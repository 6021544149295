import React from 'react'
import { Grid, Box, Typography, TextField, OutlinedInput, InputAdornment, IconButton, Button, Link } from '@mui/material';

function NoAccess() {

    return (
        <Box pt={46}  sx={{ height: '100vh', wifth: '100%', background: 'rgb(22, 29, 49)' }}>
            <Box sx={{ background: '#272F43', borderRadius: '10px', p: '40px 20px', width: '800px', m: '0 auto' }}>
                <Typography sx={{ color: '#fff', fontSize: '26px', textAlign: 'center' }}>You don't have access. Please follow the link: <a className="linkReirect" href="http://platform.dkv.global">platform.dkv.global</a></Typography>
            </Box>
        </Box>
    );
}

export default NoAccess;
