import { Box, Button, Divider, Typography, Grid, TextField, MenuItem, OutlinedInput, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';




const DialogEditUser = ({ user, handleClose, btnTitle, handleEditUser, userRole, handleUserRole, theme }) => {
    

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                color: '#D0D4B'
            },
        },
    };


    return (
        <Box sx={{ width: '566px', bgcolor: 'dialogs.background', borderRadius: '8px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '16px' }}>
            <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.primary' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 600 }}>{btnTitle}</Typography>
            <Box sx={{ cursor: 'pointer' }} onClick={handleClose} ><CloseIcon sx={{ fill: '#fff' }} /></Box>
        </Box>
        <Divider sx={{ background: '#7A838F', opacity: .2 }} />
        <Grid container
            sx={{
                width: '566px',
                '.MuiTextField-root': {
                    p: '0 13px'
                },
                '.MuiFormControl-root': {
                    p: '0 13px'
                },
                '.MuiOutlinedInput': {
                    color: '#D0D2D6'
                },
                'textarea': {
                    m: '0 11px',
                    padding: '0px',
                    p: '12px',
                    color: '#D0D2D6',
                    fontSize: '14px',
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    border: '1px solid #414655',
                    borderRadius: '2px',
                    '&:focus': {
                        outline: 'none !important',
                        border: '1px solid #7367F0',
                    }
                }
            }}>
            <Grid item xs={12}>
                <Box sx={{ width: '100% - 32px' }}>
                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '1.5rem 0 0 1rem' }}>User Name</Typography>
                <TextField variant="outlined" color="secondary"
                    value={user.name ? user.name : user.username}
                    disabled
                    sx={{
                        "& .Mui-disabled": {
                            color: "rgba(208, 210, 214, 1)",
                            '-webkit-text-fill-color': theme.mode === 'dark' ? 'rgba(208, 210, 214, 0.6) !important' : 'rgba(3, 3, 3, 0.6) !important'   // (default alpha is 0.38)
                        },
                        '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                        '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                    }} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100% - 32px' }}>
                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '1.5rem 0 0 1rem' }}>User Email</Typography>
                <TextField variant="outlined" color="secondary"
                    value={user.email}
                    disabled
                    sx={{
                        "& .Mui-disabled": {
                            color: "rgba(208, 210, 214, 1)",
                            '-webkit-text-fill-color': theme.mode === 'dark' ? 'rgba(208, 210, 214, 0.6) !important' : 'rgba(3, 3, 3, 0.6) !important'   // (default alpha is 0.38)
                        },
                        '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                        '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                    }} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{
                        fontSize: '16px',
                        letterSpacing: '0.2px',
                        lineHeight: '24px', 
                        color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        m: '1.5rem 0 0 1rem'
                    }}>User Role</Typography>
                    <Box sx={{ pl:"1rem", pr:"1rem", pt:"0.5rem", width:"100% - 32px" }} >
                        <Select
                            fullWidth
                            color='secondary'
                            value={userRole}
                            onChange={handleUserRole}
                            input={<OutlinedInput label="Leads" sx={{
                                color: 'input.text',
                                'input': {
                                    color: 'input.text'
                                },
                                'svg': { color: '#b4b7bd' },
                                '&.MuiOutlinedInput-root': {
                                    border: 'solid',
                                    borderColor: 'dialogs.border',
                                    borderWidth: '1px',
                                },
                                'fieldset': {
                                    border: 'none'
                                }
                            }} />}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                value={"ADMIN"}
                                sx={{ color: 'input.text' }}
                            >
                                Admin
                            </MenuItem>
                            <MenuItem
                                value={"USER"}
                                sx={{ color: 'input.text' }}
                            >
                                User
                            </MenuItem>
                        </Select>
                    </Box>
                </Box>
            </Grid>
        </Grid>
        <Box sx={{ width: '100%', m: '24px auto', textAlign:"center" }}>
            <Button sx={{
                fontSize: '14px',
                fontFamily: 'Montserrat',
                color: '#fff',
                padding: '8px 16px',
                textTransform: 'none',
                borderRadius: '4px',
                bgcolor: 'button.primary.background',
                '&:hover': {
                    bgcolor: 'button.primary.background'
                },
            }}
                onClick={handleEditUser}
            >
                {btnTitle}
            </Button>
        </Box>
        </Box >
    )
}

export default DialogEditUser;