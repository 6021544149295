import React from 'react'
import './App.css';
import Authorization from './components/pages/login'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import AllPeople from './components/pages/allPeople';
import MyLeads from './components/pages/myLeads';
import MyList from './components/pages/myList';
import DashboardListLeads from './components/pages/dashboardListLeads';
import ListLeads from './components/pages/listLeads';
import Home from './components/pages/homePage';
import AdminsManagment from './components/pages/admins';
import Settings from './components/pages/settings';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'
import lightTheme from './themeLight'
import darkTheme from './themeDark'
import Auth from './components/auth';
import AuthGuard from './components/auth/authd';
import NoAccess from './components/pages/error';
import Roles from './components/auth/Roles';
import Conversation from './components/pages/conversation/index';
import SampleMessage from './components/pages/sampleMessages';
import { createTheme } from '@mui/material/styles';
import { apiUser } from './api/apiUser';
import MyProfile from './components/pages/myProfile';
import CompanyInfo from './components/pages/companyInfo';
import { NotificationProvider } from './contexts/NotificationContext';

function App() {

  const [theme, setTheme] = React.useState(lightTheme);
  const [themeChanged, setThemeChanged] = React.useState(true);
  const [socketIo, setSocketIo] = React.useState(null);

  React.useEffect(() => {
    if (themeChanged === false) {
      setTheme(darkTheme)
      localStorage.setItem('mode', 'dark')
    }
    else {
      setTheme(lightTheme)
      localStorage.setItem('mode', 'light')
    }
  }, [themeChanged])

  React.useEffect(() => {
    if (localStorage.getItem('tokenID') !== null){
      const headers = {
        user_id: localStorage.getItem('userId'),
        token_id: localStorage.getItem('tokenID')
      }
      apiUser.getThemeUser({ headers })
        .then(res => {
          if (res.themeSelect === "Dark") {
            setTheme(darkTheme)
            setThemeChanged(false)
          }
          else {
            setTheme(lightTheme)
            setThemeChanged(true)
          }
        })
        .catch(err => { console.log(err) })
    }
  }, []);

  return (
    <div className='App'>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AuthGuard>
            <Roles setSocketIo={setSocketIo}>
              <NotificationProvider socketIo={socketIo}>
                <Routes>
                  {/* <Route exact path="/" element={<Authorization />} /> */}
                  {/* <Route exact path="/allpeople" element={<AllPeople themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} /> */}
                  <Route exact path="/myleads" element={<MyLeads themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/mylist" element={<MyList themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/leadsList/:id" element={<ListLeads themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/dashboardLeadsList/:id" element={<DashboardListLeads themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/" element={<Home themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/adminsmanagment" element={<AdminsManagment themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/settings" element={<Settings themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/noaccess" element={<NoAccess themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/conversation/:email/:companyName/:group" element={<Conversation themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/sample-message" element={<SampleMessage themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/profile" element={<MyProfile themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route exact path="/companyInfo/:type/:slug/:id" element={<CompanyInfo themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                  <Route path="*" element={<Home themeChanged={themeChanged} setThemeChanged={setThemeChanged} />} />
                </Routes>
              </NotificationProvider>
            </Roles>
          </AuthGuard>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
