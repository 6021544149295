import { Avatar, Box, Button, Grid, Table, TableBody, TableRow, Typography, TextField, CircularProgress } from "@mui/material";
import React from "react";
import Layout from "../../layout";
import { useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';

import facebookLink from '../../../assets/images/facebookLink.svg';
import instagramLink from '../../../assets/images/instagramLink.svg';
import emailLink from '../../../assets/images/emailLink.svg';
import linkedIdLink from '../../../assets/images/linkedIdLink.svg';
import { apiCompany } from "../../../api/apiCompany";
import { apiPeople } from "../../../api/apiPeople";
import { apiLeads } from "../../../api/apiLeads";
import { apiMessage } from "../../../api/apiMessage";


const CompanyInfo = ({ themeChanged, setThemeChanged }) => {
    const pageInfo = {
        activeMenuTitle: 'Company Info',
        header: 'Company Info',
    };
    
    const [user_id, setUser_id] = React.useState(localStorage.getItem('userId'));
    const [token_id, setToken_id] = React.useState(localStorage.getItem('tokenID'));

    const params = useParams();
    const { slug, id, type } = params;

    const [dataCompany, setDataCompany] = React.useState();
    const [data, setData] = React.useState();
    const [companyInfo, setCompanyInfo] = React.useState(false);
    const [editNote, setEditNote] = React.useState(false);
    const [editNoteText, setEditNoteText] = React.useState('');
    const [isLoad, setIsLoad] = React.useState(false);

    React.useEffect(() => {
        const headers = {
            token_id: token_id
        }
        if (slug !== 'none') {
            apiCompany.companyDetails({ slug })
                .then(function (response) {
                    setDataCompany(response)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        if (type === 'people') {
            apiPeople.getPeople({ _id: id, headers })
                .then(function (response) {
                    setData(response)
                })
                .catch(function (error) {
                    console.log(error);
                })
        } else {
            getLead()
        }
    }, [])

    const getLead = () => {
        const headers = {
            token_id: token_id
        }
        apiLeads.getLead({ _id: id, headers })
            .then(function (response) {
                setData(response)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleViewDataCompany = () => {
        setCompanyInfo(true);
    }

    const handleEditNote = () => {
        setEditNoteText(data?.notes)
        setEditNote(true);
    }

    const handleCancelClick = () => {
        setEditNoteText(data?.notes)
        setEditNote(false);
    }

    const handleSaveNotes = ({text}) => {
        const headers = {
            'user_id': user_id,
            'token_id': token_id,
            'Content-Type': 'application/json'
        }
        let editData = JSON.stringify({
            "companyName": data?.companyName,
            "linkedin": data?.linkedin,
            "name": data?.name,
            "surname": data?.surname,
            "email": data?.email,
            "leadStatus": data?.leadStatus,
            "industry": data?.industry,
            "subIndustry": data?.subIndustry,
            "slug": data?.slug,
            "country": data?.country,
            "fundingStage": data?.fundingStage,
            "notes": text ? text : editNoteText,
            _id: id,
        });
        apiLeads.updateLead({ data: editData, headers })
            .then(response => {
                // console.log(response)
                getLead()
                setEditNote(false)
            })
            .catch(error => {
                getLead()
                setEditNote(false)
            });
    }

    const autogenClick = () => {
        if(!isLoad){
            setIsLoad(true)
            const headers = {
                'user_id': user_id,
                'token_id': token_id,
            }
            apiMessage.noteChangeMessage({ company: data?.companyName, email: data?.email, headers })
                .then(res => {
                    setEditNoteText(res.text)
                    handleSaveNotes({text: res.text})
                    setIsLoad(false)
                })
                .catch(err => {
                    console.log(err)
                    setIsLoad(false)
                })
        }
    }

    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                {dataCompany && companyInfo ?
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ bgcolor: 'home.block', borderRadius: '8px' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{
                                        p: '16px', display: 'flex', flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }}>
                                        <Typography sx={{ fontSize: '20px', lineHeight: '28px', color: 'companyInfo.text.secondary', fontFamily: 'Montserrat', fontWeight: 500 }}>
                                            {dataCompany.title}
                                        </Typography>
                                        {/* <Box sx={{
                                            display: 'flex', pt: '1rem',
                                            '& > a': {
                                                mr: '0.8rem',
                                                p: '0',
                                                pt: '1rem',
                                                ':hover': {
                                                    cursor: 'pointer'
                                                }
                                            }
                                        }}>
                                            <Typography component='a'>
                                                <img src={facebookLink} alt='facebook' />
                                            </Typography>
                                            <Typography component='a'>
                                                <img src={instagramLink} alt='instagram' />
                                            </Typography>
                                            {dataCompany.fields.c_em.value &&
                                                <Typography component='a'>
                                                    <img src={emailLink} alt='email' />
                                                </Typography>}
                                            {dataCompany.fields.c_li.value &&
                                                <Typography component='a' href={dataCompany.fields.c_li.value} target='_blank'>
                                                    <img src={linkedIdLink} alt='linkedId' />
                                                </Typography>}
                                        </Box> */}
                                    </Box>
                                    <Box sx={{
                                        p: '16px',
                                        '& > .MuiAvatar-root': {
                                            width: '150px',
                                            height: '90px'
                                        },
                                        '.MuiAvatar-square': {
                                            width: '150px',
                                            height: '90px'
                                        }
                                    }}>
                                        <Avatar
                                            sx={{ bgcolor: 'button.primary.background', fontSize: '2rem' }}
                                            variant="square"
                                            alt={dataCompany.title}
                                            src={`${process.env.REACT_APP_API_URL_OLD}files/${dataCompany.fields.logo.value}`}>
                                        </Avatar>
                                    </Box>
                                </Box>
                                <Box sx={{ p: '1rem 1rem' }}>
                                    <Table>
                                        <TableBody sx={{
                                            '& > tr > p': {
                                                p: '0.5rem',
                                                color: 'companyInfo.text.secondary',
                                                lineHeight: '28px',
                                                fontFamily: 'Montserrat',
                                            },
                                            '& > tr': {
                                                borderTop: '1px solid',
                                                borderLeft: '1px solid',
                                                borderRight: '1px solid'
                                            },
                                            '& > tr > p > bold': {
                                                color: 'companyInfo.text.bold',
                                                fontWeight: '600'
                                            }
                                        }}>
                                            <TableRow>
                                                <Typography>
                                                    <bold>WEBSITE</bold> {dataCompany.fields.link.value}
                                                </Typography>
                                            </TableRow>
                                            <TableRow>
                                                <Typography>
                                                    <bold>COUNTRY</bold> {dataCompany.fields.country.value}
                                                </Typography>
                                            </TableRow >
                                            <TableRow>
                                                <Typography>
                                                    <bold>YEAR FOUNDED</bold> {dataCompany.fields.year.value}
                                                </Typography>
                                            </TableRow>
                                            <TableRow>
                                                <Typography>
                                                    <bold>IPO STATUS</bold> {dataCompany.fields.ipo_status.value}
                                                </Typography>
                                            </TableRow>
                                            <TableRow>
                                                <Typography>
                                                    <bold>ESTIMATED NUMBER OF EMPLOYEES</bold> {dataCompany.fields.staff.value}
                                                </Typography>
                                            </TableRow>
                                            <TableRow>
                                                <Typography>
                                                    <bold>TOTAL FUNDING</bold> {dataCompany.fields.fund_total.value}
                                                </Typography>
                                            </TableRow>
                                            <TableRow>
                                                <Typography>
                                                    <bold>LINKEDIN PAGE</bold> {dataCompany.fields.c_li.value}
                                                </Typography>
                                            </TableRow>
                                            <TableRow>
                                                <Typography>
                                                    <bold>TWITTER PAGE</bold> {dataCompany.fields.c_tw.value}
                                                </Typography>
                                            </TableRow>
                                            <TableRow sx={{ borderBottom: '1px solid' }}>
                                                <Typography>
                                                    <bold>SUB-INDUSTRY</bold> {data.subIndustry}
                                                </Typography>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ bgcolor: 'home.block', borderRadius: '8px' }}>
                                <Box sx={{ p: '16px' }}>
                                    <Typography sx={{
                                        fontSize: '20px', lineHeight: '28px', color: 'companyInfo.text.secondary',
                                        fontFamily: 'Montserrat', fontWeight: 500
                                    }}>
                                        About company
                                    </Typography>
                                    <Typography sx={{ color: 'companyInfo.text.description' }}>
                                        {dataCompany.fields.description.value}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    data && <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: 'home.block', borderRadius: '8px' }}>
                                <Box sx={{ p: '16px' }}>
                                    <Typography sx={{ fontSize: '20px', lineHeight: '28px', color: 'companyInfo.text.secondary', fontFamily: 'Montserrat', fontWeight: 500 }}>
                                        {data.companyName}
                                    </Typography>
                                    {dataCompany && <Button
                                        sx={{
                                            mt: '1rem',
                                            fontSize: '14px',
                                            color: '#fff',
                                            padding: '8px 16px',
                                            textTransform: 'none',
                                            borderRadius: '4px',
                                            bgcolor: 'button.primary.background',
                                            height: '40px',
                                            // fontFamily: 'Montserrat',
                                            '&:hover': {
                                                bgcolor: 'button.primary.background'
                                            }
                                        }}
                                        onClick={handleViewDataCompany}>
                                        View info about company
                                    </Button>}
                                </Box>
                                <Box sx={{
                                    p: '16px',
                                    '& > img': {
                                        width: '130px'
                                    }
                                }}>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ bgcolor: 'home.block', borderRadius: '8px' }}>
                                <Box sx={{ p: '16px', '& > p': { fontFamily: 'Montserrat' } }}>
                                    <Typography sx={{
                                        fontSize: '20px', lineHeight: '28px', color: 'companyInfo.text.secondary',
                                        fontFamily: 'Montserrat', fontWeight: 500
                                    }}>
                                        About
                                    </Typography>
                                    <Typography sx={{ color: 'companyInfo.text.description' }}>
                                        Email: {data.email}
                                    </Typography>
                                    <Typography sx={{ color: 'companyInfo.text.description' }}>
                                        Limited Partners: {data.limitedPartners}
                                    </Typography>
                                    <Typography sx={{ color: 'companyInfo.text.description' }}>
                                        Linkedin:{data.linkedin}
                                    </Typography>
                                    {type !== "lead" && <Typography sx={{ color: 'companyInfo.text.description' }}>
                                        Notes: {data.notes}
                                    </Typography>}
                                    <Typography sx={{ color: 'companyInfo.text.description' }}>
                                        Industry: {data.industry}
                                    </Typography>
                                    <Typography sx={{ color: 'companyInfo.text.description' }}>
                                        Sub-Industry: {data.subIndustry}
                                    </Typography>
                                    <Typography sx={{ color: 'companyInfo.text.description' }}>
                                        {/* {data.fields.description.value} */}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        {type === "lead" && <Grid item xs={12} md={6}>
                            <Box sx={{ bgcolor: 'home.block', borderRadius: '8px' }}>
                                <Box sx={{ p: '16px', display:'flex', justifyContent:'space-between' }}>
                                    <Typography sx={{ fontSize: '20px', lineHeight: '28px', color: 'companyInfo.text.secondary', fontFamily: 'Montserrat', fontWeight: 500, m:'auto 0' }}>
                                        Engagement Notes
                                    </Typography>
                                    <Button
                                        sx={{
                                            fontSize: '14px',
                                            color: '#fff',
                                            padding: '8px 16px',
                                            textTransform: 'none',
                                            borderRadius: '4px',
                                            bgcolor: 'button.primary.background',
                                            height: '40px',
                                            '&:hover': {
                                                bgcolor: 'button.primary.background'
                                            }
                                        }}
                                        onClick={autogenClick}
                                    >
                                        {isLoad ? 
                                        <CircularProgress size="20px" sx={{ color: '#fff', }} /> 
                                        : 
                                        "Autogenerate notes"}
                                    </Button>
                                </Box>
                                <Box sx={{
                                    p: '16px',
                                    '& > img': {
                                        width: '130px'
                                    }
                                }}>
                                    {!editNote && 
                                        <Box
                                            sx={{
                                                display:'flex',
                                                justifyContent:'space-between',
                                            }}
                                        >
                                            <Typography sx={{ color: 'companyInfo.text.description' }}>
                                                {data.notes}
                                            </Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <EditIcon onClick={() => handleEditNote()} sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                                            </Box>
                                        </Box>
                                    }
                                    {editNote && 
                                        <Box sx={{ display:'flex' }}>
                                            <TextField 
                                                fullWidth 
                                                variant="outlined" 
                                                color="secondary"
                                                value={editNoteText}
                                                onChange={(e) => setEditNoteText(e.target.value)}
                                                placeholder='Enter notes'
                                                multiline
                                                minRows={3}
                                                sx={{
                                                    pr:'1rem',
                                                    '.MuiOutlinedInput-root': { 
                                                        border: '1px solid', 
                                                        padding: '0px', 
                                                        color: '#b4b7bd', 
                                                        borderColor: 'dialogs.border' 
                                                    },
                                                    '.MuiOutlinedInput-input': { 
                                                        p: '12px', 
                                                        color: 'input.text', 
                                                        fontSize: '14px', 
                                                        fontFamily: 'Montserrat', 
                                                        fontWeight: 400, 
                                                        border: '1px solid', 
                                                        borderColor: 'dialogs.border', 
                                                        m: 0 
                                                    }
                                                }} 
                                            />
                                            <Box sx={{ display:'flex', flexDirection:'column' }}>
                                                <Button
                                                    sx={{
                                                        fontSize: '14px',
                                                        color: '#fff',
                                                        padding: '8px 16px',
                                                        textTransform: 'none',
                                                        borderRadius: '4px',
                                                        bgcolor: 'button.primary.background',
                                                        height: '40px',
                                                        '&:hover': {
                                                            bgcolor: 'button.primary.background'
                                                        }
                                                    }}
                                                    onClick={handleCancelClick}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    sx={{
                                                        mt:'1rem',
                                                        fontSize: '14px',
                                                        color: '#fff',
                                                        padding: '8px 16px',
                                                        textTransform: 'none',
                                                        borderRadius: '4px',
                                                        bgcolor: 'button.primary.background',
                                                        height: '40px',
                                                        '&:hover': {
                                                            bgcolor: 'button.primary.background'
                                                        }
                                                    }}
                                                    onClick={handleSaveNotes}
                                                >
                                                    Save
                                                </Button>
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        </Grid>}
                    </Grid>}
            </Layout>
        </Box >
    )
}


export default CompanyInfo;