import React from 'react';
import { Box, Button, Divider, Typography, Grid, TextField, FormControlLabel, RadioGroup, Radio, Popover, TextareaAutosize } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from 'axios';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DialogAddEvent = ({ btnTitle, addEvent, handleClose, values, setValues, handleChange, theme }) => {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [valueStart, setValueStart] = React.useState(new Date().setMinutes(new Date().getMinutes() + 10));
    const [valueEnd, setValueEnd] = React.useState(new Date().setMinutes(new Date().getMinutes() + 25));


    React.useEffect(() => {
        values.dateEnd = valueEnd
        values.dateStart = valueStart;
        setValues({ ...values });
    }, [valueEnd, valueStart])

    // console.log(values)
    const handleChangeTimeStart = (newValue) => {
        if (valueEnd < newValue) {
            let minutes = new Date(newValue);
            minutes = minutes.setMinutes(minutes.getMinutes() + 15);
            // console.log(minutes)
            setValueEnd(new Date(minutes));
        }
        setValueStart(newValue);
    };

    const handleChangeTimeEnd = (newValue) => {
        setValueEnd(newValue);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    return (
        <Box sx={{ width: '580px', height: '457px', bgcolor: 'dialogs.background', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '16px' }}>
                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.primary' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 600 }}>{btnTitle}</Typography>
                <Box sx={{ cursor: 'pointer' }} onClick={handleClose} ><CloseIcon sx={{ color: 'icons' }} /></Box>
            </Box>
            <Divider sx={{ bgcolor: 'dialogs.divider', opacity: .2 }} />
            <Grid container
                sx={{
                    width: '580px',
                    '.MuiTextField-root': {
                        p: '0 13px'
                    },
                    '.MuiFormControl-root': {
                        p: '0 13px'
                    },
                    '.MuiOutlinedInput': {
                        color: '#D0D2D6'
                    },
                    'textarea': {
                        m: '0 11px',
                        padding: '0px',
                        p: '12px',
                        color: '#D0D2D6',
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        border: '1px solid #414655',
                        borderRadius: '2px',
                        '&:focus': {
                            outline: 'none !important',
                            border: '1px solid #7367F0',
                        }
                    }
                }}>
                <Grid item xs={6}>
                    <Box sx={{ width: '90%' }}>
                        <Typography sx={{
                            fontSize: '16px',
                            letterSpacing: '0.2px',
                            lineHeight: '24px',
                            color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            m: '24px 33px 8px'
                        }}>Title Event</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.titleEvent}
                            onChange={handleChange('titleEvent')}
                            placeholder='Enter Title Event'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} />
                        <Typography sx={{
                            fontSize: '16px',
                            letterSpacing: '0.2px',
                            lineHeight: '24px',
                            color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            m: '24px 33px 8px'
                        }}>Description Event</Typography>

                        {/* <TextareaAutosize
                            minRows={2}
                            maxRows={7}
                            placeholder='Enter Description Event'
                            value={values.descriptionEvent}
                            onChange={handleChange('descriptionEvent')}
                            variant="outlined" color="secondary"
                            style={{
                                width: 'calc(100% - 24px)',
                                backgroundColor: '#272F43',
                                resize: 'none',
                                ':focus-visible': {
                                    outline: 'auto 2px'
                                }
                            }}
                        /> */}
                        <TextField fullWidth variant="outlined" color="secondary"
                        value={values.descriptionEvent}
                        onChange={handleChange('descriptionEvent')}
                        placeholder='Enter Text Message'
                        multiline
                        sx={{
                            '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                            '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border', m: 0 }
                        }} />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        'svg': {
                            color: theme.mode === "light" ? 'text.title' : 'text.secondary'
                        }
                    }}>
                        <Typography sx={{
                            fontSize: '16px',
                            letterSpacing: '0.2px',
                            lineHeight: '24px',
                            color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            m: '24px 33px 8px'
                        }}>Start Date Event</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                value={valueStart}
                                minDateTime={new Date().setMinutes(new Date().getMinutes() + 5)}
                                onChange={handleChangeTimeStart}
                                renderInput={(params) => <TextField
                                    sx={{
                                        '.MuiOutlinedInput-root': { border: '1px solid', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                        '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                                    }}
                                    variant="outlined" {...params} />}
                            />
                        </LocalizationProvider>
                        <Typography sx={{
                            fontSize: '16px',
                            letterSpacing: '0.2px',
                            lineHeight: '24px',
                            color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            m: '24px 33px 8px'
                        }}>End Date Event</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                value={valueEnd}
                                minDateTime={new Date(valueStart).setMinutes(new Date(valueStart).getMinutes() + 10)}
                                onChange={handleChangeTimeEnd}
                                renderInput={(params) => <TextField
                                    sx={{
                                        '.MuiOutlinedInput-root': { border: '1px solid', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                        '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                                    }}
                                    variant="outlined" {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ width: '147px', m: '25px auto', textAlign:'center' }}>
                <Button sx={{
                    fontSize: '14px',
                    color: '#fff',
                    padding: '8px 16px',
                    textTransform: 'none',
                    borderRadius: '4px',
                    bgcolor: 'button.primary.background',
                    '&:hover': {
                        bgcolor: 'button.primary.background'
                    },
                }}
                    onClick={addEvent}>Add Event</Button>
            </Box>
        </Box>
    )
}

export default DialogAddEvent;