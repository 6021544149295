import React from 'react'
import { Grid, Box, Typography, Checkbox, Rating, InputAdornment, IconButton, Button, Tooltip } from '@mui/material';
import checkbox from '../../assets/images/checkbox997.svg'
import check from '../../assets/images/check.svg'
import Ellipse1 from '../../assets/images/Ellipse1.svg'
import Ellipse2 from '../../assets/images/Ellipse2.svg'
import Ellipse3 from '../../assets/images/Ellipse3.svg'
import lightThemeEllipse1 from '../../assets/images/lightThemeEllipse1.svg'
import lightThemeEllipse2 from '../../assets/images/lightThemeEllipse2.svg'
import lightThemeEllipse3 from '../../assets/images/lightThemeEllipse3.svg'
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment'
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendIcon from '@mui/icons-material/Send';
import { Link, useLocation } from "react-router-dom";
import CircleIcon from '@mui/icons-material/Circle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { createTheme } from '@mui/material/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { NotificationContext } from "../../contexts/NotificationContext";
import HubIcon from '@mui/icons-material/Hub';
import { apiHubSpot } from '../../api/apiHubSpot';
function Table({ 
    items, 
    deleteValue, 
    conversation = true, 
    companyInfo = true, stars, 
    handleClickOpen, 
    handleFavorites, 
    page, 
    theme, 
    setOpenSnackBar, 
    setSnackBarStatus, 
    setSnackValue 
}) {

    const { notificationLeads } = React.useContext(NotificationContext);

    const PopperProps = {
        sx: {
            "& .MuiTooltip-tooltip": {
                bgcolor: 'home.grid.border',
                color: "text.primary",
                fontSize: '14px'
            }
        }
    }

    const headerTable = [
        { width: '3%', name: '#' },
        { width: '13%', name: 'Company Name' },
        // { width: '16%', name: 'Limited Partners' },
        { width: '9%', name: 'Name' },
        { width: '9%', name: 'Surname' },
        { width: '6%', name: 'Link' },
        { width: '26%', name: 'Email' },
        { width: '19%', name: 'Lead Status' },
        // { width: '12%', name: 'Notes' },
        // { width: '10%', name: 'Industry' },
    ]
    const location = useLocation();

    const getParamsSlug = (item) => {
        let slug = 'none';

        if (item.companyName) {
            slug = item.companyName.toLowerCase();
        }

        if (item.slug) {
            slug = item.slug.toLowerCase();
        }

        return slug;
    };

    // const isUnread = (unreadUserIds) => Array.isArray(unreadUserIds) && unreadUserIds.indexOf(localStorage.getItem('userId')) !== -1;
    const isUnread = (id) => notificationLeads.leads.indexOf(id) !== -1;

    if (!theme) {
        theme = createTheme({
            mode: 'dark',
        });
    }

    const handleImportToHubSpot = async (item) => {
      let properties = {
        company: item?.companyName,
        email: item?.email,
        firstname: item?.name,
        lastname: item?.surname,
        industry: item?.industry,
      };
      let data = JSON.stringify({
        properties: properties,
      });
      const headers = {
        token_id: localStorage.getItem("tokenID"),
        "Content-Type": "application/json",
      };
      apiHubSpot
        .addContact({ data, headers })
        .then(function (response) {
        //   console.log(response);
          setOpenSnackBar(true);
          setSnackBarStatus(true);
          setSnackValue("Exported in HubSpot");
        })
        .catch(function ({ e }) {
        //   console.log(e);
          setOpenSnackBar(true);
          setSnackBarStatus(false);
          setSnackValue("Contact already exist");
        });
    };

    return (
        <Box //sx={{ overflow: 'hidden', width: '100%' }}
        >
            <Grid container sx={{ bgcolor: 'table.grid.primary', width: '100%' }}>

                {headerTable.map((item) => (
                    <Grid item sx={{ width: item.width, p: '20px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }} key={item.name}>
                        <Typography sx={{ color: 'text.secondary', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0.2px', textAlign: 'center', fontFamily: 'Montserrat' }}>{item.name}</Typography>
                    </Grid>
                ))}

                {stars && <Grid item sx={{ width: '2.5%', p: '16px 12px' }}>

                </Grid>}
                <Grid item sx={{ width: '2.5%', p: '16px 12px' }}>

                </Grid>
                <Grid item sx={{ width: '2.5%', p: '16px 12px' }}>

                </Grid>
            </Grid>
            {/* <Link to={`/companyInfo/${item.slug}`} > */}
            {items?.map((item, index) => (
                <Grid key={index + 1} container sx={{ bgcolor: theme.mode === 'light' ? 'table.grid.secondary' : (index + 1) % 2 ? 'rgba(22, 29, 49, 0.4)' : 'rgba(22, 29, 49, 0.2)', zIndex: 9999, width: '100%' }}>
                    <Grid item sx={{ width: '3%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', textAlign: 'center', fontFamily: 'Montserrat' }}>{index + 1 + (page - 1) * 10}</Typography>
                    </Grid>
                    <Grid item sx={{ width: '13%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        {/* {location.pathname === '/allpeople' ?
                            <Link to={`/companyInfo/${item.slug}`} >
                                <Typography sx={{ color: 'text.link', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat', textDecoration: 'underline' }}>
                                    {item.companyName}
                                </Typography>
                            </Link>
                            : */}
                        <Typography sx={{ color: 'text.link', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat', textDecoration: 'underline', wordBreak: 'break-all' }}>
                            {item.companyName}
                        </Typography>
                        {/* } */}
                    </Grid>
                    {/* <Grid item sx={{ width: '16%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat' }}>{item.limitedPartners}</Typography>
                    </Grid> */}
                    <Grid item sx={{ width: '9%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Tooltip title={item.name}
                            PopperProps={PopperProps}
                            sx={{
                                pl: '0.5rem',
                                color: 'icons',
                                mt: 'auto',
                                mb: 'auto',
                            }} >
                            <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item sx={{ width: '9%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Tooltip title={item.surname}
                            PopperProps={PopperProps}
                            sx={{
                                pl: '0.5rem',
                                color: 'icons',
                                mt: 'auto',
                                mb: 'auto',
                            }} >
                            <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.surname}</Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item sx={{ width: '6%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border', display: 'flex', justifyContent: 'center' }} >
                        <Typography component='a' href={item.linkedin} target='_blank' sx={{ textAlign: 'center' }} >
                            <LinkedInIcon sx={{ color: 'icons', fontSize: '25px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                        </Typography>
                    </Grid>
                    <Grid item sx={{ width: '26%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat', wordBreak: 'break-all' }}>{item.email}</Typography>
                    </Grid>
                    {/* <Grid item sx={{ width: '8%', p: '6px 12px', borderRight: '1px solid #45516F' }}>
                        <Box sx={{ width: '40px', m: '0 auto' }}>
                            <Checkbox icon={<img src={checkbox} />}
                                checkedIcon={<Box sx={{
                                    width: '22px',
                                    height: '22px',
                                    border: '1px solid #7B61FF',
                                    background: '#293145',
                                    borderRadius: '4px',
                                }}><Box sx={{ m: '3px 0 0 3px' }}><img src={check} /></Box></Box>} />
                        </Box>
                    </Grid> */}
                    <Grid item sx={{ width: '19%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ mr: '5px' }}>
                                {theme.mode === 'light' ?
                                    (
                                        item.leadStatus == 'Message Received' ?
                                            <img src={lightThemeEllipse2} /> :
                                            item.leadStatus == 'Message sent' ?
                                                <img src={lightThemeEllipse3} /> :
                                                <img src={lightThemeEllipse1} />
                                    )
                                    :
                                    (
                                        item.leadStatus == 'Message Received' ?
                                            <img src={Ellipse2} /> :
                                            item.leadStatus == 'Message sent' ?
                                                <img src={Ellipse3} /> :
                                                <img src={Ellipse1} />
                                    )
                                }

                            </Box>
                            <Box>
                                <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat' }}>{window.location === '/myleads' ? item.leadStatusForUser[localStorage.getItem('userEmail')] : item.leadStatus}</Typography>
                                {(window.location === '/myleads' ? item.leadStatusForUser[localStorage.getItem('userEmail')] : item.leadStatus) !== "No action" &&
                                    <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '12px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat' }}>
                                        {moment(item.createdAt).format('DD.MM.YYYY')}
                                    </Typography>}
                            </Box>
                        </Box>
                    </Grid>
                    {/* <Grid item sx={{ width: '12%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat', wordBreak: 'break-all' }}>{item.notes}</Typography>
                    </Grid> */}
                    {/* <Grid item sx={{ width: '10%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat' }}>{item.industry}</Typography>
                    </Grid> */}

                    <Grid container sx={{ width: '15%', margin: 0, border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `none`, borderColor: 'table.grid.border', borderLeft: 'none' }} justifyContent="center">
                        <Grid item container sx={{ mt: 'auto', mb: 'auto' }} justifyContent="center">
                            {companyInfo && <Grid item sx={{ border: 'none' }}>
                                <Link to={location.pathname === '/allpeople' ? `/companyInfo/people/${getParamsSlug(item)}/${item._id}` : `/companyInfo/lead/${getParamsSlug(item)}/${item._id}`} >
                                    <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                                        <VisibilityOutlinedIcon sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                                    </Box>
                                </Link>
                            </Grid>}
                            {!stars && conversation &&
                                <Grid item sx={{ border: 'none' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                                        <Link to={`/conversation/${item.email}/${item.companyName}/false`} >
                                            <ChatBubbleOutlineIcon sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                                            {isUnread(item._id) && <CircleIcon sx={{
                                                position: 'absolute',
                                                fill: '#DB6AB5',
                                                right: '-2px',
                                                top: '0',
                                                borderRadius: '50%',
                                                fontSize: '10px'
                                            }} />}
                                        </Link>
                                    </Box>
                                </Grid>}
                            {!stars && <Grid item sx={{ border: 'none' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <HubIcon onClick={() => handleImportToHubSpot(item)} sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                                </Box>
                            </Grid>}
                            <Grid item sx={{ border: 'none' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <EditIcon onClick={() => handleClickOpen('update', item._id, item)} sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                                </Box>
                            </Grid>
                            <Grid item sx={{ border: 'none' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <DeleteOutlineIcon onClick={() => deleteValue(item._id)} sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                                </Box>
                            </Grid>
                            {stars && <Grid item sx={{ border: 'none' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Rating size="large" value={item.favorites} onChange={() => handleFavorites(item._id, item)} max={1} emptyIcon={<StarBorderIcon sx={{ color: 'icons', fontSize: '26px' }} />} />
                                </Box>
                            </Grid>}
                        </Grid>
                    </Grid>

                </Grid >
            ))
            }
        </Box >
    );
}

export default Table;
