import axios from 'axios';
import { updateToken } from '../utils/updateToken';
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.interceptors.request.use((config) => {
    config.headers.userEmail = localStorage.getItem('userEmail')
    config.headers.token_id = localStorage.getItem('tokenID');
    return config;
})


instance.interceptors.response.use((config) => {
    return config;
}, async (error) => updateToken(error, instance))

export const apiList = {
    getLists: async ({ page, headers }) => {
        return (
            await instance.get('/list/getLists', { params: { page }, headers })
        ).data;
    },
    getList: async ({ id, isDashboard = false, params = {}, headers }) => {
        return (
            await instance.get(`/list/getList/${id}`, { params: { isDashboard, ...params }, headers })
        ).data;
    },
    addList: async ({ data, headers }) => {
        return (
            await instance.post('/list/createList', data, { headers })
        ).data;
    },
    updateList: async ({ data }) => {
        return (
            await instance.post('/list/updateList', data)
        ).data;
    },
    deleteList: async ({ id }) => {
        return (
            await instance.get('/list/deleteList', { params: { id: id } })
        ).data;
    },
    exportListToXlsx: async ({ responseType }) => {
        return (
            await instance.get('/list/exportListToXlsx', { responseType })
        ).data;
    },
    exportListToCVS: async ({ id }) => {
        return (
            await instance.get('/list/exportListToCVS', { params: { id: id } })
        ).data;
    },
    exportListLeadsToCSV: async ({ id, headers }) => {
        return (
            await instance.get('/list/exportListLeadsToCSV', { params: { _id: id }, headers })
        ).data;
    },
    exportListLeadsToXLSX: async ({ id, headers }) => {
        return (
            await instance.get('/list/exportListLeadsToXLSX', { params: { _id: id }, responseType: 'arraybuffer', headers })
        ).data;
    },
    importListLeadsFromCSV: async ({ data, id, headers }) => {
        return (
            await instance.post('/list/importListLeadsFromCSV', { data, id }, { headers })
        ).data;
    },
    importListLeadsFromXLSX: async ({ data, id, headers }) => {
        return (
            await instance.post('/list/importListLeadsFromXLSX', { arrayXl: data, id }, { headers })
        ).data;
    },
}