import axios from 'axios';
import { updateToken } from '../utils/updateToken';

const defaultHeader = {
    'Content-Type': 'application/json',
    'user_id': localStorage.getItem('userId'),
    'token_id': localStorage.getItem('tokenID'),
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: defaultHeader
});

instance.interceptors.request.use((config) => {
    config.headers.userEmail = localStorage.getItem('userEmail')
    config.headers.token_id = localStorage.getItem('tokenID');
    return config;
})


instance.interceptors.response.use((config) => {
    return config;
}, async (error) => updateToken(error, instance))

export const apiZoom = {
    createMeeting: (data) => instance.post('/zoom/createMeeting', data)
}
