import { Box, CircularProgress } from '@mui/material';
import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom';
import socketIOClient from "socket.io-client";

// function Roles({ children }) {
function Roles({ setSocketIo, children }) {

    const [searchParams, setSearchParams] = useSearchParams();

    const [code, setCode] = React.useState(searchParams.get('code'));
    const { user } = useAuth0();
    const navigate = useNavigate();
    const [state, setState] = React.useState(true)
    React.useEffect(() => {
        const uri = `${process.env.REACT_APP_GOOGLE_AUTH_URI}?redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=${process.env.REACT_APP_SCOPES}&access_type=offline`;
        // if (user) {
        // console.log('user', user)
        // localStorage.setItem('userId', user.sub)
        // localStorage.setItem('email', user.email)
        // var options = {
        //     method: 'GET',
        //     url: `${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}/roles`,
        //     headers: { authorization: `Bearer ${process.env.REACT_APP_AUTH0_KEY_ROLES}` }
        // };
        // axios(options)
        //     .then(function (response) {
        //         setState(false)

        //     })
        //     .catch(function (error) {
        //         console.error(error);
        //     })
        // var data = JSON.stringify({
        //     "email": user.email,
        //     "family_name": user.family_name,
        //     "given_name": user.given_name,
        //     "locale": user.locale,
        //     "name": user.name,
        //     "nickname": user.nickname,
        //     "picture": user.picture,
        //     "sub": user.sub,
        //     "updated_at": user.updated_at,
        //     "email_verified": user.email_verified
        //   });

        // console.log(localStorage.getItem('tokenID'))
        const codegmail = code;
        if (code) {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/auth/registration`,
                headers: {
                    'Content-Type': 'application/json',
                    // 'user_id': user.sub,
                    codegmail
                },
                // data : data
            };

            axios(config)
                .then(function (response) {
                    localStorage.setItem('tokenID', response.data.dataUser.credentials.access_token);
                    localStorage.setItem('name', response.data.dataUser.name);
                    localStorage.setItem('userId', response.data.dataUser._id);
                    localStorage.setItem('userEmail', response.data.dataUser.email);
                    console.log(response.data)
                    if (response.data.access === true) {
                        if (response.data.dataUser.peopleFavorites) {
                            localStorage.setItem('favorites', JSON.stringify(response.data.dataUser.peopleFavorites))
                        }
                        navigate('/')
                        setState(false)
                    }
                })
                .catch(function (error) {
                    localStorage.removeItem('tokenID');
                    localStorage.removeItem('name');
                    console.log(error);
                    window.location = uri
                    // navigate('/noaccess')
                    setState(false)
                });
        } else if (!code && !localStorage.getItem('tokenID')) {
            // console.log('WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW')
            window.location = uri
            // } else if (!localStorage.getItem('tokenID')) {
            //     window.location = uri
        }
        if (localStorage.getItem('tokenID')) {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/auth/checkUserAuth`,
                headers: {
                    'Content-Type': 'application/json',
                    // 'user_id': user.sub,
                    token_id: localStorage.getItem('tokenID')
                },
                // data : data
            };

            axios(config)
                .then(function (response) {
                    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, { extraHeaders: { token_id: localStorage.getItem('tokenID') }});
                    if (setSocketIo) setSocketIo(socket);
                    
                    // console.log(response.data)
                    setState(false)
                })
                .catch(function (error) {
                    localStorage.removeItem('tokenID');
                    localStorage.removeItem('name');
                    window.location = uri
                });
        }
        // }
    }, [code])

    return (
        <Box>
            {state ? <Box sx={{ bgcolor: 'load.background', height: '100vh' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', pt: '20%' }}>
                    <CircularProgress size="100px" sx={{ color: 'load.circle' }} />
                </Box>
            </Box> :
                <Box>
                    {children}
                </Box>}
        </Box>
    );
}

export default Roles;
