import React from 'react'
import { Box, Snackbar, CircularProgress, Typography, Divider, Avatar, InputAdornment, OutlinedInput, Grid, Button, Dialog } from '@mui/material';
import moment from 'moment'
import CircleIcon from '@mui/icons-material/Circle';
import Layout from '../../layout';
import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import { useParams, useSearchParams } from "react-router-dom";
import DialogAddEvent from '../../dialogAddEvent';
import CreateZoomMeet from '../../createZoomMeet';
import DialogInsertTemplate from '../../dialogInsertTemplate';
import { apiMessage } from '../../../api/apiMessage';
import { apiEvents } from '../../../api/apiEvents';
import { apiZoom } from '../../../api/apiZoom';
import { createTheme } from '@mui/material/styles';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { getResponseErrorMessage } from '../../../utils/fn';
import { apiList } from '../../../api/apiList';

const darkTheme = createTheme({
    mode: 'dark',
});

const lightTheme = createTheme({
    mode: 'light',
});

function Conversation({ setThemeChanged, themeChanged }) {


    const [theme, setTheme] = React.useState(darkTheme);

    React.useEffect(() => {
        if (themeChanged === false) {
            setTheme(darkTheme)
        }
        else {
            setTheme(lightTheme)
        }
    }, [themeChanged])

    let params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const isDashboard = !!searchParams.get('isDashboard');

    const [emailSendler, setEmailSendler] = React.useState();
    const email = params.email;
    React.useEffect(() => {

        setEmailSendler(email)
    }, [])
    const pageInfo = {
        activeMenuTitle: params.group !== 'false' ? 'My Lists' : 'My Leads',
        header: params.group !== 'false' ? 'My Lists' : 'My Leads',
    };
    const [newMessageData, setNewMessageData] = React.useState("");
    const [newMessageId, setNewMessageId] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [openInsertTemplate, setOpenInsertTemplate] = React.useState(false);
    const [openCreateZoomMeet, setOpenCreateZoomMeet] = React.useState(true);
    const [isLoaderCreateZoomMeet, setIsLoaderCreateZoomMeet] = React.useState(false);
    const [isLoaderMessages, setIsLoaderMessages] = React.useState(true);
    const [isLoaderEvent, setIsLoaderEvent] = React.useState(true);
    const [messagesData, setMessagesData] = React.useState([]);
    const [pageBlock, setPageBlock] = React.useState("Emails");
    const [eventData, setEventData] = React.useState();
    const [dataMes, setDataMes] = React.useState([]);
    const [themeMessage, setThemeMessage] = React.useState();
    const [snackBar, setSnackBar] = React.useState({
        text: '',
        isOpen: false,
        isError: false
    });
    const [loaderMessaegeGenerate, setLoaderMessaegeGenerate] = React.useState(false)

    const pageBlocks = [
        {
            name: "Emails",
        },
        {
            name: "Meets",
        },
        {
            name: "Zoom",
        }
    ]

    const [values, setValues] = React.useState({
        titleEvent: '',
        descriptionEvent: '',
        dateStart: '',
        dateEnd: ''
    });

    const [zoomCreateResponse, setZoomCreateResponse] = React.useState(null);
    const [zoomCreateValues, setZoomCreateValues] = React.useState({
        email: emailSendler,
        type: 1,
        topic: '',
        duration: 60,
        password: '',
        startTime: new Date(),
    });

    const [zoomCreateError, setZoomCreateError] = React.useState({
        duration: false,
        topic: false,
        type: false,
        password: false,
        startTime: false,
    });

    const refLastMessage = React.useRef();
    const refNewMessage = React.useRef();

    React.useEffect(() => {
        if (emailSendler) {
            zoomCreateValues.email = emailSendler;
            setZoomCreateValues({ ...zoomCreateValues })
        }
    }, [emailSendler])

    React.useEffect(() => {
        if (params.group !== 'false') {
            const headers = {
                'user_id': localStorage.getItem('userId'),
                'token_id': localStorage.getItem('tokenID'),
            };
            const id = params.group;
            apiList.getList({ id, headers })
                .then((response) => {
                    const { owner, listName, leadsList } = response;
                    const newPageInfo = pageInfo;

                    if (listName) {
                        newPageInfo.header = `Leads list of ${listName}`;
                    }
                    const emailsForMessage = []
                    if (leadsList && leadsList.length) {
                        leadsList.forEach(element => {
                            emailsForMessage.push(element.email)
                        });
                        setEmailSendler(emailsForMessage)
                    }
                })
                .catch((error) => {
                    setSnackBar({
                        text: getResponseErrorMessage(error),
                        isOpen: true,
                        isError: true
                    });
                })
        }
    }, [])
    // console.log(email)


    const getMessage = () => {
        if (emailSendler && emailSendler !== 'undefined') {
            console.log(emailSendler)
            const headers = {
                'user_id': localStorage.getItem('userId'),
                'token_id': localStorage.getItem('tokenID'),
            }
            apiMessage.getMessage({ isDashboard, email: emailSendler, headers })
                .then((response) => {
                    if (!response.empty) {
                        console.log(response)
                        const newMessage = response.find(item => !item.isRead && item.recepient === "notMe");

                        setNewMessageId(newMessage?.id || null);
                        setMessagesData(response);
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setSnackBar({
                        text: getResponseErrorMessage(error),
                        isOpen: true,
                        isError: true
                    });
                })
                .finally(() => setIsLoaderMessages(false));
        }
    }

    React.useEffect(() => {
        if (messagesData.length) {
            setTimeout(() => {
                const scrollHeight = refNewMessage?.current?.offsetTop || refLastMessage.current.scrollHeight;
                const height = refLastMessage.current.clientHeight;
                const maxScrollTop = scrollHeight - height;

                refLastMessage.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            }, 500);
        }
    }, [messagesData])

    React.useEffect(() => {
        if (pageBlock === 'Emails') {
            setIsLoaderMessages(true);
            getMessage();
            getSampleMessage();
        } else if (pageBlock === 'Meets') {
            setIsLoaderEvent(true);
            getEvent();
        }
    }, [pageBlock, emailSendler])

    const handleInputChange = (e) => {
        setNewMessageData(e.target.value);
    };

    const handleSend = () => {
        sendMessage();
        setNewMessageData("");
    };

    const sendMessage = () => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
        }
        let data = {
            "themeMessage": themeMessage,
            "textMessage": newMessageData,
            "email": emailSendler,
            isDashboard,
        };
        apiMessage.sendMessage({ data, headers })
            .then(function (response) {
                getMessage();
                if (params.group !== 'false') {
                    setSnackBar({
                        text: 'Please, check the individual conversations in My Leads',
                        isOpen: true,
                        isError: false
                    })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const getEvent = () => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
        }

        apiEvents.getEvent({ isDashboard, email: emailSendler, headers })
            .then(function (response) {
                setEventData(response);
            })
            .catch(function (error) {
                setSnackBar({
                    text: getResponseErrorMessage(error),
                    isOpen: true,
                    isError: true
                });
                console.log(error)
            })
            .finally(() => setIsLoaderEvent(false));
    }

    const handleClickOpenInsertTemplate = () => {
        setOpenInsertTemplate(true);
    }

    const handleCloseInsertTemplate = () => {
        setOpenInsertTemplate(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenCreateZoomMeet = () => {
        setOpenCreateZoomMeet(true);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    }

    const handleZoomCreateChange = (prop) => (event) => {
        if (prop === 'password') {
            setZoomCreateError(prev => ({ ...prev, password: !event.target.value }));
        }

        if (prop === 'topic') {
            setZoomCreateError(prev => ({ ...prev, topic: !event.target.value }));
        }

        setZoomCreateValues({ ...zoomCreateValues, [prop]: event.target.value });
    };

    const createZoomMeet = () => {
        if (!zoomCreateValues.password) {
            setZoomCreateError(prev => ({ ...prev, password: true }));
            return;
        }

        if (!zoomCreateValues.topic) {
            setZoomCreateError(prev => ({ ...prev, topic: true }));
            return;
        }

        const isError = !!Object.entries(zoomCreateError).filter(([key, value]) => value).length;

        if (isError) {
            return;
        }

        setIsLoaderCreateZoomMeet(true);
        apiZoom.createMeeting(zoomCreateValues)
            .then(({ data }) => {
                setZoomCreateResponse(data);
                setOpenCreateZoomMeet(false);
            })
            .catch(err => {
                setSnackBar({
                    text: getResponseErrorMessage(err),
                    isOpen: true,
                    isError: true
                });
            })
            .finally(() => {
                setIsLoaderCreateZoomMeet(false);
            });
    }

    const copyZoomMeetText = () => {
        let text = '';

        if (zoomCreateResponse.topic) {
            text += `Topic: ${zoomCreateResponse.topic} \n \n`;
        }

        if (zoomCreateResponse.start_time) {
            text += `Start time: ${moment(zoomCreateResponse.start_time).format('DD.MM.YYYY HH:mm')} \n \n`;
        }

        if (zoomCreateResponse.duration) {
            text += `Duration: ${zoomCreateResponse.duration} \n \n`;
        }

        if (zoomCreateResponse.join_url) {
            text += `Start url: ${zoomCreateResponse.join_url} \n \n`;
        }

        if (zoomCreateResponse.id) {
            text += `Meeting ID: ${zoomCreateResponse.id} \n \n`;
        }

        if (zoomCreateResponse.password) {
            text += `Password: ${zoomCreateResponse.password} \n \n`;
        }

        navigator.clipboard.writeText(text);

        setSnackBar({
            text: 'Text copied',
            isOpen: true,
            isError: false
        })
    }

    const handleAddEvent = () => {
        let data = {
            "title": values.titleEvent,
            "description": values.descriptionEvent,
            "dateStart": values.dateStart,
            "dateEnd": values.dateEnd,
            "email": emailSendler,
        };
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
        }
        apiEvents.createEvent({ data, headers })
            .then(function (response) {
                getEvent();
                handleClose();
                setValues({
                    titleEvent: '',
                    descriptionEvent: '',
                    dateStart: '',
                    dateEnd: ''
                })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const getSampleMessage = () => {
        apiMessage.getSampleMessages()
            .then(function (response) {
                setDataMes(response);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleGenerateMessage = () => {
        let messageLast;
        setLoaderMessaegeGenerate(true)

        messagesData.forEach(el => {
            if (el.recepient === 'notMe') {
                messageLast = el.message
            }
        })
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
        }

        apiMessage.getMessageCreated({ text: messageLast, email: emailSendler, headers })
            .then(res => {
                console.log(res)
                // res.text.replaceAll('[Name]')
                const text = res.text;
                setNewMessageData(text);
                setLoaderMessaegeGenerate(false)
            })
            .catch(err => console.log(err))

    }

    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                <Box sx={{ bgcolor: 'conversation.conversation.background', width: '100%', height: '750px', borderRadius: '8px', display: 'flex', border: theme.mode === "light" ? `1px solid` : '', borderColor: 'conversation.conversation.border' }}>
                    <Box sx={{ bgcolor: 'conversation.conversation.background', width: { md: '10%', xs: '15%', xl: '10%' }, height: '100%-1.5rem', borderRadius: '8px', pt: '1.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
                        <Box>
                            {pageBlocks && pageBlocks.map((data) => (
                                <Box key={data.name} id={data.name} sx={{ cursor: 'pointer', height: '50px', width: '100%', textAlign: 'center', backgroundColor: data.name === pageBlock ? 'conversation.conversation.page' : 'none', display: 'grid' }}
                                    onClick={() => {
                                        setPageBlock(data.name);
                                    }}
                                >
                                    <Typography sx={{ alignSelf: 'center', color: theme.mode === "light" ? data.name === pageBlock ? 'text.link' : 'text.title' : 'text.primary', fontFamily: 'Montserrat', opacity: data.name === pageBlock ? '1' : '0.6' }}>
                                        {data.name}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        {pageBlock === "Emails" && <Box sx={{ pb: '1rem', pl: '0.4rem', pr: '0.4rem' }}>
                            <Button
                                disabled={loaderMessaegeGenerate}
                                sx={{
                                    width: '100%',
                                    fontSize: '14px',
                                    color: '#fff',
                                    padding: '8px 16px',
                                    textTransform: 'none',
                                    borderRadius: '4px',
                                    bgcolor: 'button.primary.background',
                                    // height: '40px',
                                    '&:hover': {
                                        bgcolor: 'button.primary.background'
                                    }
                                }}
                                onClick={() => handleGenerateMessage()}>
                                {loaderMessaegeGenerate ? <CircularProgress sx={{ color: '#fff' }} /> : 'Auto-generate response'}
                            </Button>
                        </Box>}
                    </Box>
                    {pageBlock === "Emails" &&
                        <Box sx={{ bgcolor: 'conversation.conversation.background', borderLeft: `1px solid`, borderColor: 'conversation.conversation.border', width: { md: '90%', xs: '87%', xl: '90%' }, height: '100%', borderRadius: '8px' }} >
                            <Box sx={{ p: '1.5rem', width: '100% - 48px', display: 'flex', justifyContent: 'space-between' }} >
                                <Box sx={{ display: 'flex' }}>
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            width: 'fit-content'
                                        }}>
                                        {params.group !== 'false' ? <GroupsOutlinedIcon sx={{ width: 48, height: 48, color: '#bdbdbd' }}></GroupsOutlinedIcon> : <Avatar alt="Avatar" src="" sx={{ width: 48, height: 48 }} />}
                                        {params.group === 'false' && <CircleIcon sx={{
                                            position: 'absolute',
                                            fill: '#28C76F',
                                            right: '0',
                                            bottom: '-4px',
                                            border: '1px #000 solid',
                                            borderRadius: '50%',
                                            fontSize: '13px'
                                        }} />}
                                    </Box>
                                    <Box sx={{ pl: '1rem', display: 'flex' }} >
                                        <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '20px', mt: 'auto', mb: 'auto' }} >{params.companyName}</Typography>
                                        {/* <Typography sx={{ color: '#FFFFFF', opacity: '0.5', fontFamily: 'Montserrat', fontSize: '14px' }} >Last visited at 13:00</Typography> */}
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Button
                                        sx={{
                                            m: 'auto 0',
                                            fontSize: '14px',
                                            fontFamily: 'Montserrat',
                                            color: '#fff',
                                            padding: '8px 16px',
                                            textTransform: 'none',
                                            borderRadius: '4px',
                                            bgcolor: 'button.primary.background',
                                            height: '40px',
                                            '&:hover': {
                                                bgcolor: 'button.primary.background'
                                            }
                                        }}
                                        onClick={handleClickOpenInsertTemplate}
                                    >
                                        Insert Template
                                    </Button>
                                </Box>
                            </Box>
                            <Divider sx={{ color: 'conversation.conversation.border', opacity: '0.4', border: `1px solid`, borderColor: 'conversation.conversation.border' }} />
                            <Box sx={{ height: { md: '74%', xl: '73%', xs: '73%' }, width: '100%', overflowY: 'auto' }} ref={refLastMessage}>
                                {isLoaderMessages && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <CircularProgress size="100px" sx={{ color: 'load.circle', }} />
                                </Box>}

                                {!isLoaderMessages && messagesData.length < 1 &&
                                    <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", textAlign: "center" }}>
                                        <Box sx={{ bgcolor: 'conversation.conversation.noMessage', mt: "auto", mb: "auto", p: "40px 24px", borderRadius: '8px' }}>
                                            <Typography sx={{
                                                fontSize: "28px",
                                                lineHeight: "40px",
                                                color: theme.mode === "light" ? 'text.link' : 'text.primary',
                                                fontFamily: 'Montserrat'
                                            }}>
                                                You don't have any messages
                                            </Typography>
                                            <Button
                                                sx={{
                                                    mt: '20px',
                                                    fontSize: '14px',
                                                    fontFamily: 'Montserrat',
                                                    color: '#fff',
                                                    padding: '8px 16px',
                                                    textTransform: 'none',
                                                    borderRadius: '4px',
                                                    bgcolor: 'button.primary.background',
                                                    height: '40px',
                                                    '&:hover': {
                                                        bgcolor: 'button.primary.background'
                                                    }
                                                }}
                                                onClick={handleClickOpenInsertTemplate}
                                            >
                                                Insert Template
                                            </Button>
                                        </Box>
                                    </Box>
                                }

                                {!isLoaderMessages && messagesData && messagesData.map((data, key) => (
                                    data.recepient === "notMe" ?
                                        <Box sx={{ p: '0.75rem 1.5rem 0.75rem 1.5rem' }} key={data.id}>
                                            {newMessageId === data.id
                                                ? <Box sx={{ p: '1rem', textAlign: 'center' }} ref={refNewMessage}>
                                                    <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '18px' }} >
                                                        New message
                                                    </Typography>
                                                </Box>
                                                : ''
                                            }
                                            <Box
                                                sx={{
                                                    display: 'inline-block',
                                                    alignItems: 'center',
                                                    verticalAlign: 'bottom',
                                                    mr: '0.7rem'
                                                }}>
                                                <Avatar alt="Avatar" src="" sx={{ width: 40, height: 40, }} />
                                            </Box>
                                            <Box sx={{ width: '10px', height: '30px', verticalAlign: 'bottom', display: 'inline-block' }} >
                                                <Box sx={{ width: '20px', height: '20px', bgcolor: 'conversation.conversation.message', transform: 'rotate(45deg)' }} />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'inline-block',
                                                    maxWidth: '60%',
                                                    p: '1rem',
                                                    bgcolor: 'conversation.conversation.message',
                                                    borderRadius: '8px',
                                                    height: 'fit-content',
                                                }}>
                                                {!Array.isArray(data.message) ?
                                                    <Typography key={data.id} component='pre' sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '14px', wordBreak: 'break-all', whiteSpace: 'break-spaces' }} >
                                                        {data.message}
                                                    </Typography>
                                                    :
                                                    data.message.map((message, index) => (
                                                        <Typography key={data.id + index} component='pre' sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '14px', wordBreak: 'break-all', whiteSpace: 'break-spaces' }} >
                                                            {message}
                                                            {!(index === data.message.length) && <br />}
                                                        </Typography>
                                                    ))
                                                }
                                                {/* <Typography component='pre' sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '14px', wordBreak: 'break-all', whiteSpace: 'break-spaces' }} >
                                                    {data.message}
                                                </Typography> */}
                                                <Box sx={{ pt: '1rem' }} >
                                                    <Typography sx={{ color: theme.mode === "light" ? 'text.title' : 'text.primary', opacity: '0.5', fontFamily: 'Montserrat', fontSize: '14px', display: 'inline-block' }} >{moment(data.date).format('DD.MM.YYYY')}</Typography>
                                                    <Typography sx={{ color: theme.mode === "light" ? 'text.title' : 'text.primary', opacity: '0.5', fontFamily: 'Montserrat', fontSize: '14px', display: 'inline-block', pl: '1rem' }} >{moment(data.date).format('HH:mm')}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        :
                                        <Box sx={{ p: '0.75rem 1.5rem 0.75rem 1.5rem', textAlign: 'end' }} key={data.id}>
                                            <Box
                                                sx={{
                                                    display: 'inline-block',
                                                    maxWidth: '60%',
                                                    p: '1rem',
                                                    pt: data.subjectFirst ? '0.2rem' : '1rem',
                                                    bgcolor: 'conversation.conversation.message',
                                                    borderRadius: '8px',
                                                    height: 'fit-content',
                                                    mr: '-10px'
                                                }}>
                                                {data.subjectFirst && <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '14px', textAlign: 'left', pb: '.5rem', wordBreak: 'break-all', whiteSpace: 'pre-line' }} >
                                                    {data.subjectFirst}
                                                </Typography>}
                                                {!Array.isArray(data.message) ?
                                                    <Typography key={data.id} sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '14px', textAlign: 'left', wordBreak: 'break-all', whiteSpace: 'pre-line' }} >
                                                        {data.message}
                                                    </Typography>
                                                    :
                                                    data.message.map((message, index) => (
                                                        <Typography key={data.id + index} sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '14px', textAlign: 'left', wordBreak: 'break-all', whiteSpace: 'pre-line' }} >
                                                            {message}
                                                            {!(index === data.message.length) && <br />}
                                                        </Typography>
                                                    ))
                                                }
                                                <Box sx={{ pt: '1rem', width: '100%', textAlign: 'center', justifyContent: 'space-between !important' }} >
                                                    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'right' }} >
                                                        <Typography sx={{ color: theme.mode === "light" ? 'text.title' : 'text.primary', opacity: '0.5', fontFamily: 'Montserrat', fontSize: '14px', display: 'inline-block' }} >
                                                            {moment(data.date).format('DD.MM.YYYY')}
                                                        </Typography>
                                                        <Typography sx={{ color: theme.mode === "light" ? 'text.title' : 'text.primary', opacity: '0.5', fontFamily: 'Montserrat', fontSize: '14px', display: 'inline-block', pl: '1rem' }} >
                                                            {moment(data.date).format('HH:mm')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: '10px', height: '30px', verticalAlign: 'bottom', display: 'inline-block', mr: '0.7rem' }} >
                                                <Box sx={{ width: '20px', height: '20px', bgcolor: 'conversation.conversation.message', transform: 'rotate(45deg)' }} />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'inline-block',
                                                    alignItems: 'center',
                                                    verticalAlign: 'bottom',
                                                    ml: '0.7rem'
                                                }}>
                                                <Avatar alt="Avatar" src="" sx={{ width: 40, height: 40, }} />
                                            </Box>
                                        </Box>
                                ))}

                            </Box>
                            <Box sx={{ height: { md: '6.5%', xl: '7.5%', xs: '10%' }, p: '1.5rem', width: '100% - 48px', bgcolor: 'conversation.conversation.background', borderRadius: '0 0 8px 8px', borderTop: `1px solid`, mt: '-1px', borderColor: 'conversation.conversation.border' }} >
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    onChange={handleInputChange}
                                    onKeyPress={(event) => {
                                        if (event.key !== undefined) {
                                            // if (event.key === 'Enter') {
                                            //     handleSend();
                                            // }
                                        }
                                    }}
                                    fullWidth
                                    multiline
                                    maxRows={1}
                                    value={newMessageData}
                                    endAdornment={<InputAdornment position="end"><Box><SendIcon sx={{ color: 'button.primary.background', cursor: 'pointer', transform: 'rotate(-45deg)', width: 30, height: 30, }} onClick={handleSend} /></Box></InputAdornment>}
                                    label='Enter Your Message'
                                    sx={{
                                        'input': {
                                            color: 'input.text'
                                        },
                                        '&.MuiOutlinedInput-root': {
                                            border: 'solid',
                                            borderColor: 'input.borderColor',
                                            borderWidth: '1px',
                                        },
                                        'fieldset': {
                                            border: 'none'
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    }
                    {pageBlock === "Meets" &&
                        <Box sx={{ bgcolor: 'conversation.conversation.background', borderLeft: `1px solid`, borderColor: 'conversation.conversation.border', width: { md: '90%', xs: '85%', xl: '90%' }, height: '100%', borderRadius: '8px' }} >
                            <Box sx={{ p: '1.5rem', width: '100% - 48px', display: 'flex' }} >
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        position: 'relative',
                                        cursor: 'pointer',
                                        width: 'fit-content'
                                    }}>
                                    <Avatar alt="Avatar" src="" sx={{ width: 48, height: 48 }} />
                                    <CircleIcon sx={{
                                        position: 'absolute',
                                        fill: '#28C76F',
                                        right: '0',
                                        bottom: '-4px',
                                        border: '1px #000 solid',
                                        borderRadius: '50%',
                                        fontSize: '13px'
                                    }} />
                                </Box>
                                <Box sx={{ pl: '1rem' }} >
                                    <Typography sx={{ color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '20px' }} >{params.companyName}</Typography>
                                    {/* <Typography sx={{ color: '#FFFFFF', opacity: '0.5', fontFamily: 'Montserrat', fontSize: '14px' }} >Last visited at 13:00</Typography> */}
                                </Box>
                            </Box>
                            <Divider sx={{ color: 'conversation.conversation.border', opacity: '0.4', border: `1px solid`, borderColor: 'conversation.conversation.border' }} />
                            <Box sx={{ height: { md: '73%', xl: '72%', xs: '70%' }, width: '100%', overflowY: 'auto' }} >
                                {isLoaderEvent && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <CircularProgress size="100px" sx={{ color: 'load.circle', }} />
                                </Box>}

                                {!isLoaderMessages && eventData && eventData.map(el => (
                                    <Box key={el.linkEvent}
                                        sx={{
                                            backgroundColor: 'conversation.conversation.message',
                                            m: '1rem 0.5rem',
                                            color: theme.mode === "light" ? 'text.link' : 'text.primary',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                        <Box sx={{ p: '.5rem 0 .5rem 2rem', }}>
                                            <Typography>{el.title}</Typography>
                                            <Typography>{el.description}</Typography>
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography>Start: {moment(el.startDate).format('DD.MM.YYYY')}</Typography>
                                                {el.time ? <Typography sx={{ pl: '1rem' }}>{moment(el.startDate).format('HH:mm')}</Typography> : ''}
                                            </Box>
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography>End: {moment(el.endDate).format('DD.MM.YYYY')}</Typography>
                                                {el.time ? <Typography sx={{ pl: '1rem' }}>{moment(el.endDate).format('HH:mm')}</Typography> : ''}
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            p: '.5rem 2rem .5rem 0',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-evenly'
                                        }}>
                                            <Button component="a"
                                                target="_blank"
                                                href={el.meetLink}
                                                sx={{
                                                    fontSize: '14px',
                                                    color: '#fff',
                                                    padding: '8px 16px',
                                                    textTransform: 'none',
                                                    borderRadius: '4px',
                                                    bgcolor: 'button.primary.background',
                                                    height: '40px',
                                                    '&:hover': {
                                                        bgcolor: 'button.primary.background'
                                                    }
                                                }}
                                                disabled={
                                                    new Date(el.endDate).valueOf() > new Date().valueOf() &&
                                                        new Date(el.startDate).valueOf() < new Date().setMinutes(new Date().getMinutes() + 10).valueOf() ?
                                                        false : true}
                                            >Join the meeting</Button>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            <Box sx={{ height: { md: '7.5%', xl: '8.5%', xs: '10%' }, p: '1.5rem', width: '100% - 48px', bgcolor: 'conversation.conversation.background', borderRadius: '0 0 8px 8px', borderTop: `1px solid`, borderColor: 'conversation.conversation.border', mt: '-1px' }} >
                                <Button
                                    sx={{
                                        fontSize: '14px',
                                        color: '#fff',
                                        padding: '8px 16px',
                                        textTransform: 'none',
                                        borderRadius: '4px',
                                        bgcolor: 'button.primary.background',
                                        height: '40px',
                                        '&:hover': {
                                            bgcolor: 'button.primary.background'
                                        }
                                    }}
                                    onClick={handleClickOpen}
                                >Add new meet</Button>
                            </Box>
                        </Box>
                    }

                    {pageBlock === "Zoom" &&
                        <Box sx={{ bgcolor: 'conversation.conversation.background', borderLeft: `1px solid`, borderColor: 'conversation.conversation.border', width: { md: '90%', xs: '85%', xl: '90%' }, height: '100%', borderRadius: '8px' }} >
                            <Box sx={{ p: '1.5rem' }} >
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item sx={{ display: 'flex' }} xs={8}>
                                            <div style={{ position: 'relative' }}>
                                                <Avatar alt="Avatar" src="" sx={{ width: 48, height: 48 }} />
                                                <CircleIcon sx={{
                                                    position: 'absolute',
                                                    fill: '#28C76F',
                                                    right: '0',
                                                    bottom: '-4px',
                                                    border: '1px #000 solid',
                                                    borderRadius: '50%',
                                                    fontSize: '13px'
                                                }} />
                                            </div>

                                            <Box sx={{ pl: '1rem', display: 'flex' }} >
                                                <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '20px', mt: 'auto', mb: 'auto' }} >{params.companyName}</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={4} textAlign="end">
                                            {!openCreateZoomMeet && <Button
                                                sx={{
                                                    fontSize: '14px',
                                                    color: '#fff',
                                                    padding: '8px 16px',
                                                    textTransform: 'none',
                                                    borderRadius: '4px',
                                                    bgcolor: 'button.primary.background',
                                                    height: '40px',
                                                    '&:hover': {
                                                        bgcolor: 'button.primary.background'
                                                    }
                                                }}
                                                onClick={handleOpenCreateZoomMeet}
                                            >Add new meet</Button>}
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Box>
                            <Divider sx={{ color: 'conversation.conversation.border', opacity: '0.4', border: `1px solid`, borderColor: 'conversation.conversation.border' }} />

                            <Box sx={{ height: { md: '73%', xl: '72%', xs: '70%' }, width: '100%', overflowY: 'auto' }} >
                                {isLoaderCreateZoomMeet
                                    ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <CircularProgress size="100px" sx={{ color: 'load.circle', }} />
                                    </Box>
                                    : openCreateZoomMeet
                                        ? <CreateZoomMeet
                                            values={zoomCreateValues}
                                            setValues={setZoomCreateValues}
                                            handleChange={handleZoomCreateChange}
                                            errors={zoomCreateError}
                                            onSubmit={createZoomMeet}
                                            theme={theme}
                                        />
                                        : <Box sx={{ padding: '1rem', }}>
                                            <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '20px', mt: 'auto', mb: '1rem', textAlign: 'center' }}>Copy and save the necessary data not to lose it.</Typography>

                                            <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '20px', mt: 'auto', mb: '1rem', wordWrap: 'break-word' }}><b>Topic:</b> {zoomCreateResponse.topic}</Typography>

                                            {zoomCreateResponse.start_time && <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '20px', mt: 'auto', mb: '1rem' }}><b>Start time:</b> {moment(zoomCreateResponse.start_time).format('DD.MM.YYYY HH:mm')}</Typography>}

                                            {zoomCreateResponse.duration && <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '20px', mt: 'auto', mb: '1rem' }}><b>Duration:</b> {zoomCreateResponse.duration}</Typography>}

                                            <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '20px', mt: 'auto', mb: '1rem', wordWrap: 'break-word' }}><b>Start url:</b> {zoomCreateResponse.join_url}</Typography>

                                            <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '20px', mt: 'auto', mb: '1rem' }}><b>Meeting ID:</b> {zoomCreateResponse.id}</Typography>

                                            <Typography sx={{ color: theme.mode === "light" ? 'text.link' : 'text.primary', fontFamily: 'Montserrat', fontSize: '20px', mt: 'auto', mb: '1rem' }}><b>Password:</b> {zoomCreateResponse.password}</Typography>

                                            <Box sx={{ textAlign: 'center' }}>
                                                <Button
                                                    sx={{
                                                        fontSize: '14px',
                                                        color: '#fff',
                                                        padding: '8px 16px',
                                                        textTransform: 'none',
                                                        borderRadius: '4px',
                                                        bgcolor: 'button.primary.background',
                                                        height: '40px',
                                                        '&:hover': {
                                                            bgcolor: 'button.primary.background'
                                                        }
                                                    }}
                                                    onClick={copyZoomMeetText}
                                                >Copy all text</Button>
                                            </Box>
                                        </Box>
                                }
                            </Box>
                        </Box>
                    }
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ '.MuiDialog-root': { background: 'rgba(13, 18, 31, 0.8)' }, '.MuiPaper-root': { background: 'none' }, background: 'rgba(13, 18, 31, 0.6)' }}
                >
                    <DialogAddEvent
                        btnTitle={"Add Event"}
                        addEvent={handleAddEvent}
                        handleClose={handleClose}
                        setValues={setValues}
                        values={values}
                        handleChange={handleChange}
                        theme={theme}
                    />
                </Dialog>
                <Dialog
                    open={openInsertTemplate}
                    onClose={handleCloseInsertTemplate}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ '.MuiDialog-root': { background: 'rgba(13, 18, 31, 0.8)' }, '.MuiPaper-root': { background: 'none' }, background: 'rgba(13, 18, 31, 0.6)' }}
                >
                    <DialogInsertTemplate
                        btnTitle={"Insert Template"}
                        handleClose={handleCloseInsertTemplate}
                        messages={dataMes}
                        setNewMessageData={setNewMessageData}
                        setThemeMessage={setThemeMessage}
                        appTheme={theme}
                    />
                </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackBar.isOpen}
                    autoHideDuration={5000}
                    onClose={() => setSnackBar({ text: '', isOpen: false, isError: false })}
                    message={snackBar.text}
                    sx={{ '.MuiPaper-root': { background: snackBar.isError ? 'red' : '#7B61FF', minWidth: '100px', fontFamily: 'Montserrat' }, right: '32px !important' }} />
            </Layout >
        </Box >
    );
}

export default Conversation;
