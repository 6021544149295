import React from 'react';
import { Box, Button, Divider, Typography, Grid, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DashboardListLeadsForm = ({
    btnTitle,
    handleClose,
    handleChange,
    values,
    addValue,
    theme,
}) => {
    return (
        <Box sx={{ width: '566px', height: 'auto', bgcolor: 'dialogs.background', borderRadius: '8px', overflowX: 'hidden' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '16px' }}>
                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.primary' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 600 }}>{btnTitle}</Typography>

                <Box sx={{ cursor: 'pointer' }} onClick={handleClose} ><CloseIcon sx={{ color: 'icons' }} /></Box>
            </Box>

            <Divider sx={{ bgcolor: 'dialogs.divider', opacity: .2 }} />

            <Grid container spacing={3} sx={{ p: '0 16px' }}>
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Company</Typography>
                    <TextField fullWidth variant="outlined" color="secondary"
                        value={values.company}
                        onChange={handleChange('company')}
                        placeholder='Enter Company'
                        sx={{
                            '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                            '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                        }} />

                    <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Linkedin</Typography>
                    <TextField fullWidth variant="outlined" color="secondary"
                        value={values.linkedin}
                        onChange={handleChange('linkedin')}
                        placeholder='Enter Linkedin'
                        sx={{
                            '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                            '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                        }} />

                    <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Email</Typography>
                    <TextField fullWidth variant="outlined" color="secondary"
                        value={values.email}
                        onChange={handleChange('email')}
                        placeholder='Enter Email'
                        sx={{
                            '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                            '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                        }} />
                </Grid>

                <Grid item xs={6}>
                    <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Partner</Typography>
                    <TextField fullWidth variant="outlined" color="secondary"
                        value={values.name}
                        onChange={handleChange('name')}
                        placeholder='Enter Partner'
                        sx={{
                            '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                            '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                        }} />

                    <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Role</Typography>
                    <TextField fullWidth variant="outlined" color="secondary"
                        value={values.role}
                        onChange={handleChange('role')}
                        placeholder='Enter Role'
                        sx={{
                            '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                            '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                        }} />

                    <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Notes</Typography>
                    <TextField fullWidth variant="outlined" color="secondary"
                        value={values.notes}
                        onChange={handleChange('notes')}
                        placeholder='Enter Notes'
                        sx={{
                            '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                            '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                        }} />
                </Grid>
            </Grid>

            <Box sx={{ width: '147px', m: '25px auto', textAlign: 'center' }}>
                <Button sx={{
                    fontSize: '14px',
                    color: '#fff',
                    padding: '8px 16px',
                    textTransform: 'none',
                    borderRadius: '4px',
                    bgcolor: 'button.primary.background',
                    '&:hover': {
                        bgcolor: 'button.primary.background'
                    },
                }}
                    onClick={addValue}>{btnTitle}</Button>
            </Box>
        </Box >
    )
}

export default DashboardListLeadsForm;