import axios from 'axios';
import { updateToken } from '../utils/updateToken';
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.interceptors.request.use((config) => {
    config.headers.userEmail = localStorage.getItem('userEmail')
    config.headers.token_id = localStorage.getItem('tokenID');
    return config;
})


instance.interceptors.response.use((config) => {
    return config;
}, async (error) => updateToken(error, instance))

export const apiPeople = {
    getAllPeople: async ({ page, headers, searchData, relevantCompany }) => {
        return (
            await instance.get('/people/getAllPeople', { params: { page, seachQuery: searchData, relevantCompany }, headers })
        ).data;
    },
    getPeople: async ({ headers, _id }) => {
        return (
            await instance.get('/people/getPeople', { params: { _id }, headers })
        ).data;
    },
    getPeopleFromSlug: async ({ headers, slug }) => {
        return (
            await instance.get('/people/getPeopleFromSlug', { params: { slug }, headers })
        ).data;
    },
    deletePeople: async ({ _id, headers }) => {
        return (
            await instance.get('/people/deletePeople', { params: { _id }, headers })
        ).data;
    },
    addPeople: async ({ headers, data }) => {
        return (
            await instance.post('/people/addPeople', data, { headers })
        ).data;
    },
    updatePeople: async ({ headers, data }) => {
        return (
            await instance.post('/people/updatePeople', data, { headers })
        ).data;
    },
    peopleInLead: async ({ headers, data }) => {
        return (
            await instance.post('/people/peopleInLead', data, { headers })
        ).data;
    },

    countPeople: async ({ headers }) => {
        return (
            await instance.get('/people/countPeople', { headers })
        ).data;
    },
}