import React from 'react';
import { Box, Button, Pagination } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { createTheme } from '@mui/material/styles';

const PaginationCrm = ({ prevPage, count, page, handleChange, nextPage }) => {

    return (
        <Box sx={{ margin: '3rem 0 0' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                    disabled={page==1? true: false} sx={{
                        bgcolor: 'button.pagination.background',
                        border: `1px solid`,
                        borderColor: 'pagination.grid.border',
                        borderRadius: '4px',
                        width: '32px',
                        height: '32px',
                        mr: '5px',
                        p: '0px',
                        minWidth: '32px'
                    }}>
                    <ArrowBackIosIcon onClick={prevPage} sx={{ color: 'pagination.arrow', fontSize: '14px', ml: '4px' }} />
                </Button>
                <Pagination hideNextButton={true} hidePrevButton={true} count={count} page={page} onChange={handleChange}
                    sx={{
                        fontSize: '14px',
                        '.MuiPaginationItem-root':
                        {
                            fontWeight: 600,
                            bgcolor: 'button.pagination.background',
                            color: 'button.pagination.buttonText',
                            margin: '0 5px',
                            border: `1px solid`,
                            borderColor: 'pagination.grid.border',
                            borderRadius: '4px',
                            '&:hover': { color: 'button.primary.background' }
                        },
                        '.Mui-selected':
                        {
                            bgcolor: `button.primary.background`, color: '#fff',
                            '&:hover': {
                                bgcolor: 'button.primary.background', color: '#fff !important',
                            }
                        }
                    }} />
                <Button
                disabled={page == count? true: false} sx={{
                    bgcolor: 'button.pagination.background',
                    border: `1px solid`,
                    borderColor: 'pagination.grid.border',
                    borderRadius: '4px',
                    width: '32px',
                    height: '32px',
                    ml: '5px',
                    minWidth: '32px'
                }}>
                    <ArrowForwardIosIcon onClick={nextPage} sx={{ color: 'pagination.arrow',  fontSize: '14px' }} />
                </Button>
            </Box>
        </Box>
    )
}

export default PaginationCrm;