import React from 'react'
import { Box, Button, Dialog, Menu, MenuItem, Snackbar, styled } from '@mui/material';
import Layout from '../../layout';
import PaginationCrm from '../../pagination';
import TableList from '../../tableList';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogAddFields from '../../dialogAddFields';
import { apiLeads } from '../../../api/apiLeads';
import { apiList } from '../../../api/apiList';
import { generateObjects, readFile } from '@ramonak/react-excel';
import { createTheme } from '@mui/material/styles';
import axios from 'axios';
import { getResponseErrorMessage } from '../../../utils/fn';

function _interopDefault(ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }
var XLSX = _interopDefault(require('xlsx'));

const darkTheme = createTheme({
    mode: 'dark',
});

const lightTheme = createTheme({
    mode: 'light',
});

function MyList({ setThemeChanged, themeChanged }) {
    const pageInfo = {
        activeMenuTitle: 'My Lists',
        header: 'My Lists',
    };

    const [theme, setTheme] = React.useState(darkTheme);

    React.useEffect(() => {
        let cleanupFunction = false;
        if (themeChanged === false) {
            if (!cleanupFunction) setTheme(darkTheme)
        }
        else {
            if (!cleanupFunction) setTheme(lightTheme)
        }
        return () => cleanupFunction = true;
    }, [themeChanged])

    const FileDownload = require('js-file-download');
    const fileReader = new FileReader();
    const [vertMenu, setVertMenu] = React.useState(null);
    const openVertMenu = Boolean(vertMenu);

    const handleClickVertMenu = (event) => {
        setVertMenu(event.currentTarget);
    };

    const handleCloseVertMenu = () => {
        setVertMenu(null);
    };

    const handleChangeImportXL = () => {
        // let config = {
        //     method: 'get',
        //     url: `${process.env.REACT_APP_API_URL}/list/exportListToXlsx`,
        //     responseType: 'arraybuffer'
        // };
        // axios(config)
        //     .then(function (response) {
        //         console.log(response)
        //         FileDownload(response.data, 'list.xlsx')
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     })
    };

    const handleClickImportCSV = () => {
        // let config = {
        //     method: 'get',
        //     url: `${process.env.REACT_APP_API_URL}/list/exportListToCVS`,
        // };
        // axios(config)
        //     .then(function (response) {
        //         FileDownload(response.data, 'list.csv')
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     })
    };

    const handleClickExportXL = () => {
        const responseType = 'arraybuffer'
        apiList.exportListToXlsx({ responseType })
            .then(function (response) {
                // console.log(response)
                FileDownload(response, 'list.xlsx')
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleClickExportCSV = () => {
        apiList.exportListToCVS()
            .then(function (response) {
                FileDownload(response, 'list.csv')
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    // const items = [
    //     {
    //         latName: 'latName',
    //         entries: 200,
    //         owner: 'owner',
    //         lastActivity: 'lastActivity',
    //     },
    //     {
    //         latName: 'latName',
    //         entries: 200,
    //         owner: 'owner',
    //         lastActivity: 'lastActivity',
    //     },
    //     {
    //         latName: 'latName',
    //         entries: 200,
    //         owner: 'owner',
    //         lastActivity: 'lastActivity',
    //     },
    //     {
    //         latName: 'latName',
    //         entries: 200,
    //         owner: 'owner',
    //         lastActivity: 'lastActivity',
    //     },
    //     {
    //         latName: 'latName',
    //         entries: 200,
    //         owner: 'owner',
    //         lastActivity: 'lastActivity',
    //     },
    // ]
    const [data, setData] = React.useState();
    const [dataLeads, setDataLeads] = React.useState()
    const [valueLeadList, setValueLeadList] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [snackValue, setSnackValue] = React.useState('');
    const [propsDialog, setPropsDialog] = React.useState('');
    const [idPeople, setIdPeople] = React.useState('');
    const [snackBarStatus, setSnackBarStatus] = React.useState(false);
    const [paginationSettings, setPaginationSettings] = React.useState('');
    const [values, setValues] = React.useState({
        "listName": '',
    });
    const [leadsList, setLeadsList] = React.useState()
    const [page, setPage] = React.useState(1);
    const [valueSearch, setValueSearch] = React.useState('');
    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    const setSuccessSnackBar = (message) => {
        setOpenSnackBar(true);
        setSnackBarStatus(true);
        setSnackValue(message);
    };

    const setErrorSnackBar = (message) => {
        setOpenSnackBar(true);
        setSnackBarStatus(false);
        setSnackValue(message);
    };

    const getLeads = () => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
        }
        apiLeads.getLeadsUser({ headers })
            .then(function (response) {
                setDataLeads(response);
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false)
    }

    React.useEffect(() => {
        getLeads()
    }, []);

    const prevPage = () => {
        if (page !== 1)
            setPage(page - 1);
    }

    const nextPage = () => {
        setPage(page + 1);
    }

    const handleChange = (e, value) => {
        setPage(value)
    }

    const handleChangeSearch = (e) => {
        setValueSearch(e.target.value)
    }


    const handleChangeValues = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    }

    const handleClose = () => {
        setOpen(false);
        setValues({ "listName": '' });
        setValueLeadList([]);
    };

    React.useEffect(() => {
        getLists(page)
    }, [])

    const Input = styled('input')({
        display: 'none',
    });

    const getLists = (page) => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            token_id: localStorage.getItem('tokenID')
        }
        apiList.getLists({ headers, page })
            .then((response) => {
                let someData = response.docs;
                ///////////////////////////////////////////////
                let config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}/dashboard/getLists?owner=${localStorage.getItem('userEmail')}`,
                    headers: {
                        'Content-Type': 'application/json',
                        token_id: localStorage.getItem('tokenID')
                    },
                };
    
                axios(config)
                    .then(function (response) {
                        response.data.forEach(element => {
                            someData.push(element);
                        });
                        setData(someData)
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                ///////////////////////////////////////////////
                // setData(response.docs)
                setPaginationSettings({
                    totalPages: response.totalPages,
                    hasNextPage: response.hasNextPage,
                    hasPrevPage: response.hasPrevPage,
                    totalDocs: response.totalDocs
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const addValue = () => {
        let data;
        if (propsDialog === 'Create list') {
            data = JSON.stringify({
                "listName": values.listName,
                "leadsList": valueLeadList
            })
            const headers = {
                'user_id': localStorage.getItem('userId'),
                token_id: localStorage.getItem('tokenID'),
                'Content-Type': 'application/json'
            }
            apiList.addList({ data, headers })
                .then(function (response) {
                    handleClose();
                    getLists(page);
                    setSuccessSnackBar(response.message);
                })
                .catch(function (error) {
                    setErrorSnackBar(getResponseErrorMessage(error));
                })
        } else {
            data = {
                "_id": idPeople,
                "listName": values.listName,
                "leadsList": valueLeadList
            }
            apiList.updateList({ data })
                .then(function (response) {
                    setValueLeadList([]);
                    setValues({ listName: '' })
                    handleClose();
                    getLists(page);
                    setSuccessSnackBar(response.message);
                })
                .catch(function (error) {
                    setErrorSnackBar(getResponseErrorMessage(error));
                })
        }
    }


    const handleClickOpen = (target, id, item) => {
        if (id) setIdPeople(id)
        if (target === 'add') {
            setPropsDialog('Create list')
        } else {
            let selectValue = []
            item.leadsList.forEach(element => {
                selectValue.push(element._id);
            });
            setValueLeadList(selectValue)
            setValues(item);
            setPropsDialog('Update list')
        }
        setOpen(true);
    };

    const handleImport = (e) => {
        const id = idPeople;
        const file = e.target.files[0];
        const headers = {
            'user_id': localStorage.getItem('userId'),
            token_id: localStorage.getItem('tokenID'),
            'Content-Type': 'application/json'
        }
        if (e.target.accept === '.csv') {
            fileReader.onload = function (event) {
                const csvOutput = event.target.result;
                apiList.importListLeadsFromCSV({ data: csvOutput, id, headers })
                    .then(res => {
                        setOpenSnackBar(true)
                        if (res.err) {
                            setSnackBarStatus(false);
                            setSnackValue(res.message);
                        } else {
                            setSnackBarStatus(true);
                            setSnackValue(res.message);
                        }
                        handleClose();
                        getLists(page);
                    })
                    .catch(err => console.log(err))
            };
            fileReader.readAsText(file);
        } else {
            readFile(file)
                .then((readedData) => {
                    var result = readedData.SheetNames.map(function (name) {
                        var _ref;

                        var ws = readedData.Sheets[name];
                        var dataParse = XLSX.utils.sheet_to_json(ws, {
                            header: 1,
                            defval: ''
                        });
                        return _ref = {}, _ref[name] = dataParse, _ref;
                    });
                    const data = generateObjects(result[0]);
                    apiList.importListLeadsFromXLSX({ data, id, headers })
                        .then(function (response) {
                            setOpenSnackBar(true)
                            if (response.err) {
                                setSnackBarStatus(false);
                                setSnackValue(response.message);
                            } else {
                                setSnackBarStatus(true);
                                setSnackValue(response.message);
                            }
                            handleClose();
                            getLists(page);
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                })
                .catch((error) => console.error(error));
        }
    }

    const handleExport = (target) => {
        const id = idPeople
        if (target === 'CSV') {
            apiList.exportListLeadsToCSV({ id })
                .then(function (response) {
                    FileDownload(response, 'listLeads.csv')
                })
                .catch(function (error) {
                    console.log(error);
                })
        } else {
            apiList.exportListLeadsToXLSX({ id })
                .then(function (response) {
                    FileDownload(response, 'listLeads.xlsx')
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }


    const handleValueLeadList = (event) => {
        const {
            target: { value },
        } = event;
        setValueLeadList(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handleDeleteValue = (id, item) => {
        if(!item.dashboard){
            apiList.deleteList({ id })
                .then(function (response) {
                    getLists(page)
                    setSuccessSnackBar(response.message);
                })
                .catch(function (error) {
                    setErrorSnackBar(getResponseErrorMessage(error));
                })}
        else {
            let config = {
                method: 'delete',
                url: `${process.env.REACT_APP_API_URL}/dashboard/deleteList?id=${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    token_id: localStorage.getItem('tokenID')
                },
            };

            axios(config)
                .then(function (response) {
                    getLists(page)
                    setSuccessSnackBar(response.data.message);
                })
                .catch(function (error) {
                    setErrorSnackBar(getResponseErrorMessage(error));
                })
        }
    }


    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                <Input
                    hidden
                    accept=".xlsx"
                    id="imporListtXLSX"
                    type="file"
                    onChange={handleChangeImportXL}
                />
                <Box sx={{
                    display: 'flex', justifyContent: 'right', alignItems: 'center', pb: '2rem',
                }}>
                    <Button
                        sx={{
                            fontSize: '14px',
                            color: '#fff',
                            padding: '8px 16px',
                            textTransform: 'none',
                            borderRadius: '4px',
                            bgcolor: 'button.primary.background',
                            height: '40px',
                            '&:hover': {
                                bgcolor: 'button.primary.background',
                            }
                        }}
                        onClick={() => handleClickOpen('add')}
                    >
                        <AddIcon sx={{ pr: '2px' }} />
                        Create New List
                    </Button>
                    <MoreVertIcon
                        onClick={handleClickVertMenu}
                        sx={{ color: 'menu.points', fontSize: 30, cursor: 'pointer', pl: '1rem' }}
                        aria-controls={openVertMenu ? 'more-vert-icon-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openVertMenu ? 'true' : undefined}
                    />
                    <Menu
                        anchorEl={vertMenu}
                        id="more-vert-icon-menu"
                        open={openVertMenu}
                        onClose={handleCloseVertMenu}
                        onClick={handleCloseVertMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                border: theme.mode !== 'light' ? `solid` : 'none',
                                borderWidth: '1px',
                                borderColor: '#45516F',
                                bgcolor: 'menu.background',
                                color: 'menu.text',
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    borderTop: theme.mode !== 'light' ? `solid` : 'none',
                                    borderLeft: theme.mode !== 'light' ? `solid` : 'none',
                                    borderWidth: '1px',
                                    borderColor: '#45516F',
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'menu.background',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {/* <MenuItem sx={{ label: { cursor: 'pointer' } }}>
                            <label htmlFor="imporListtXLSX" >
                                Import to Excel
                            </label>
                        </MenuItem>
                        <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)' }} onClick={handleClickImportCSV}>
                            Import to CSV
                        </MenuItem> */}
                        <MenuItem sx={{
                            // borderTop: "solid", borderWidth: '1px', 
                            borderColor: 'rgba(0, 0, 0, 0.25)'
                        }} onClick={handleClickExportXL}>
                            Export to Excel
                        </MenuItem>
                        <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)', mb: '-4px' }} onClick={handleClickExportCSV}>
                            Export to CSV
                        </MenuItem>
                    </Menu>
                </Box>
                <Box sx={{ bgcolor: 'myLists.block' }}>
                    <TableList items={data} handleClickOpen={handleClickOpen} deleteValue={handleDeleteValue} theme={theme} setSuccessSnackBar={setSuccessSnackBar} setErrorSnackBar={setErrorSnackBar}/>
                </Box>
                {paginationSettings && <PaginationCrm
                    page={page}
                    count={Math.ceil(paginationSettings.totalDocs / 10)}
                    prevPage={prevPage}
                    handleChange={handleChange}
                    nextPage={nextPage} />}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ '.MuiDialog-root': { background: 'rgba(13, 18, 31, 0.8)' }, '.MuiPaper-root': { background: 'none' }, background: 'rgba(13, 18, 31, 0.6)' }}
                >
                    <DialogAddFields
                        handleExport={handleExport}
                        handleImport={handleImport}
                        leadsList={leadsList}
                        btnTitle={propsDialog}
                        handleClose={handleClose}
                        values={values}
                        handleChange={handleChangeValues}
                        valueLeadStatus={valueLeadList}
                        handleValueLeadStatus={handleValueLeadList}
                        addValue={addValue}
                        dataLeads={dataLeads}
                        theme={theme} />
                </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={openSnackBar}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackBar}
                    message={snackValue}
                    sx={{ '.MuiPaper-root': { background: snackBarStatus ? '#7B61FF' : 'red', minWidth: '100px', fontFamily: 'Montserrat' }, right: '32px !important' }} />

            </Layout >
        </Box >
    );
}

export default MyList;
