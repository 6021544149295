import { createContext, useState, useEffect } from "react";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children, socketIo }) => {
    const [socketNotification, setSocketNotification] = useState({
        notification: false,
        notificationLeads: {
            leads: [],
            dashboardLeads: []
        }
    });

    const setData = (data) =>
        setSocketNotification((prev) => ({ ...prev, ...data }));


    useEffect(() => {
        if (socketIo) {
            // connect status
            // socketIo.on("connectStatus", data => {
            //     console.log('socket status', data)
            // });

            socketIo.on("notification", data => {
                if (data) {
                    setData(data);
                }
            });

        }
    }, [socketIo])

    return <NotificationContext.Provider value={socketNotification}>
        {children}
    </NotificationContext.Provider>
};