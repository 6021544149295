import axios from 'axios';
import { updateToken } from '../utils/updateToken';
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.interceptors.request.use((config) => {
    config.headers.userEmail = localStorage.getItem('userEmail')
    config.headers.token_id = localStorage.getItem('tokenID');
    return config;
})


instance.interceptors.response.use((config) => {
    return config;
}, async (error) => updateToken(error, instance))

export const apiUser = {
    getUsers: async ({ page }) => {
        return (
            await instance.get('/user/users', { params: { page } })
        ).data;
    },
    getUser: async ({ headers }) => {
        return (
            await instance.get('/user/user', { headers })
        ).data;
    },
    changeThemeUser: async ({ headers, theme }) => {
        return (
            await instance.get('/user/changeThemeUser', { params: { theme }, headers })
        ).data;
    },
    getThemeUser: async ({ headers }) => {
        return (
            await instance.get('/user/getThemeUser', { headers })
        ).data;
    },
    changeUser: async ({ headers, industry, subIndustry }) => {
        return (
            await instance.post('/user/changeUser', { industry, subIndustry }, { headers })
        ).data;
    },
    editUserRole: async ({ body }) => {
        return (
            await instance.post('/user/changeUserRole', { role: body.role, _id: body._id })
        ).data;
    },
    setCheckAccess: async ({ check, headers }) => {
        return (
            await instance.get('/user/setCheckAccess', { params: { check }, headers })
        ).data;
    },
    getCheckAccess: async ({ headers }) => {
        return (
            await instance.get('/user/getCheckAccess', { headers })
        ).data;
    },

    updateAccess: async ({ headers }) => {
        return (
            await instance.get('/auth/updateToken', { headers })
        ).data;
    }
}