import axios from 'axios';
import { updateToken } from '../utils/updateToken';
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.interceptors.request.use((config) => {
    config.headers.userEmail = localStorage.getItem('userEmail')
    config.headers.token_id = localStorage.getItem('tokenID');
    return config;
})


instance.interceptors.response.use((config) => {
    return config;
}, async (error) => updateToken(error, instance))

export const apiLeads = {
    addLeads: async ({ data, headers }) => {
        return (
            await instance.post('/lead/addLead', data, { headers })
        ).data;
    },
    updateLead: async ({ data, headers }) => {
        return (
            await instance.post('/lead/updateLead', data, { headers })
        ).data;
    },
    getAllLeads: async ({ page, headers }) => {
        return (
            await instance.get('/lead/getAllLeads', { params: { page }, headers })
        ).data;
    },
    getLead: async ({ _id, headers }) => {
        return (
            await instance.get('/lead/getLead', { params: { _id }, headers })
        ).data;
    },
    getLeadsUser: async ({ page, headers }) => {
        return (
            await instance.get('/lead/getLeadsUser', { params: { page }, headers })
        ).data;
    },
    deleteLead: async ({ _id, isDashboard = false, headers }) => {
        return (
            await instance.get('/lead/deleteLead', { params: { isDashboard, _id }, headers })
        ).data;
    },
    countLeads: async ({ headers }) => {
        return (
            await instance.get('/lead/countLeads', { headers })
        ).data;
    },
    importLeadsFromCSV: async ({ data, headers }) => {
        return (
            await instance.post('/lead/importCSV', { data }, { headers })
        ).data;
    },
    importLeadsFromXLSX: async ({ data, headers }) => {
        return (
            await instance.post('/lead/importXLSX', { data }, { headers })
        ).data;
    },

    getLeadsStatuses: async ({ headers }) => {
        return (
            await instance.get('/lead/getLeadsStatuses', { headers })
        ).data;
    },
    getUnreadLeadNotification: async ({ headers }) => {
        return (
            await instance.get('/message/getUnreadLeadNotification', { headers })
        ).data;
    },
}