import React from 'react'
import { Box, Dialog, Snackbar, Button, Menu, MenuItem } from '@mui/material';
import Layout from '../../layout';
import Table from '../../table';
import PaginationCrm from '../../pagination';
import MyLeadsDetails from '../myLeadsDetails';
import DialogAddFields from '../../dialogAddFields';
import axios from 'axios'
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactExcel, readFile, generateObjects } from '@ramonak/react-excel';
import styled from '@emotion/styled';
import { createTheme } from '@mui/material/styles';
import { apiLeads } from '../../../api/apiLeads';
import { getResponseErrorMessage } from '../../../utils/fn';
import socketIOClient from "socket.io-client";
const ENDPOINT = `${process.env.REACT_APP_SOCKET_URL}`;

// const user_id = localStorage.getItem('userId');
// const token_id = localStorage.getItem('tokenID');

function _interopDefault(ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }
var XLSX = _interopDefault(require('xlsx'));

const darkTheme = createTheme({
    mode: 'dark',
});

const lightTheme = createTheme({
    mode: 'light',
});

function MyLeads({ setThemeChanged, themeChanged }) {
    const pageInfo = {
        activeMenuTitle: 'My Leads',
        header: 'My Leads',
    };

    const [theme, setTheme] = React.useState(darkTheme);

    React.useEffect(() => {
        if (themeChanged === false) {
            setTheme(darkTheme)
        }
        else {
            setTheme(lightTheme)
        }
    }, [themeChanged])

    const FileDownload = require('js-file-download');
    const fileReader = new FileReader();

    const [user_id, setUser_id] = React.useState(localStorage.getItem('userId'));
    const [token_id, setToken_id] = React.useState(localStorage.getItem('tokenID'));

    const [page, setPage] = React.useState(1);
    const [valueSearch, setValueSearch] = React.useState('');
    const [data, setData] = React.useState()
    const [valueLeadStatus, setValueLeadStatus] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [snackValue, setSnackValue] = React.useState('');
    const [propsDialog, setPropsDialog] = React.useState('');
    const [idPeople, setIdPeople] = React.useState('');
    const [snackBarStatus, setSnackBarStatus] = React.useState(false);
    const [paginationSettings, setPaginationSettings] = React.useState('');
    const [values, setValues] = React.useState({
        companyName: '',
        // limitedPartners: '',
        name: '',
        surname: '',
        linkedin: '',
        email: '',
        leadStatus: '',
        notes: '',
        industry: '',
        subIndustry: '',
        slug: '',
        country: '',
        fundingStage: '',
    });

    const [vertMenu, setVertMenu] = React.useState(null);
    const openVertMenu = Boolean(vertMenu);

    const inputXLSXRef = React.useRef()

    const Input = styled('input')({
        display: 'none',
    });

    const handleClickVertMenu = (event) => {
        setVertMenu(event.currentTarget);
    };
    const handleCloseVertMenu = () => {
        setVertMenu(null);
    };
    const handleValueLeadStatus = (value) => {
        setValueLeadStatus(value)
    }
    const handleValueCountry = (value) => {
        setValues({...values, country: value})
    }
    const handleValueFunding = (value) => {
        setValues({...values, fundingStage: value})
    }
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    }
    const getLeads = (page, token_id) => {
        const headers = {
            'user_id': user_id,
            'token_id': token_id,
        }
        apiLeads.getLeadsUser({ page, headers })
            .then(function (response) {
                if(response?.totalDocs === 0){
                    const headers = {
                        'user_id': user_id,
                        'token_id': token_id,
                        'Content-Type': 'application/json'
                    }
                    let data = JSON.stringify({
                        "companyName": "Test",
                        "linkedin": "test",
                        // "limitedPartners": values.limitedPartners,
                        "name": "Tester",
                        "surname": "Test",
                        "email": "test2fortest2testers@gmail.com",
                        "leadStatus": "No action",
                        "notes": "asd",
                        "industry": "Test",
                        "subIndustry": "test",
                        // "slug": values.slug,
                        "country": "International",
                        "fundingStage": "None",
                    });
                    apiLeads.addLeads({ data, headers })
                        .then(function (response) {
                            getLeads(page)
                            setOpenSnackBar(true)
                            if (response.err) {
                                setSnackBarStatus(false);
                                setSnackValue("Error add lead");
                            } else {
                                setSnackBarStatus(true);
                                setSnackValue(response.message);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            // setSnackValue(error)
                        })
                }
                setData(response.docs)
                // console.log(response.docs)
                setPaginationSettings({
                    totalPages: response.totalPages,
                    hasNextPage: response.hasNextPage,
                    hasPrevPage: response.hasPrevPage,
                    totalDocs: response.totalDocs
                })
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    React.useEffect(() => {
        getLeads(page, token_id)
        const socket = socketIOClient(ENDPOINT);
        socket.emit('checkLeads', {user_id: user_id, token_id: token_id, page});
        socket.on(`${user_id}ListCheck`, data => {
            // console.log(data)
            setData(data.docs)
            // setNotification(data);
        });
    }, []);
    const addValue = () => {
        let data;
        const headers = {
            'user_id': user_id,
            'token_id': token_id,
            'Content-Type': 'application/json'
        }
        if (propsDialog === 'Add Leads') {
            data = JSON.stringify({
                "companyName": values.companyName,
                "linkedin": values.linkedin,
                // "limitedPartners": values.limitedPartners,
                "name": values.name,
                "surname": values.surname,
                "email": values.email,
                "leadStatus": valueLeadStatus,
                "notes": values.notes,
                "industry": values.industry,
                "subIndustry": values.subIndustry,
                "slug": values.slug,
                "country": values.country,
                "fundingStage": values.fundingStage,
            });
            apiLeads.addLeads({ data, headers })
                .then(function (response) {
                    getLeads(page)
                    handleClose()
                    setOpenSnackBar(true)
                    if (response.err) {
                        setSnackBarStatus(false);
                        setSnackValue(response.message);
                    } else {
                        setSnackBarStatus(true);
                        setSnackValue(response.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    // setSnackValue(error)
                })
        } else {
            data = JSON.stringify({
                "companyName": values.companyName,
                "linkedin": values.linkedin,
                // "limitedPartners": values.limitedPartners,
                "name": values.name,
                "surname": values.surname,
                "email": values.email,
                "leadStatus": valueLeadStatus,
                "notes": values.notes,
                "industry": values.industry,
                "subIndustry": values.subIndustry,
                "slug": values.slug,
                "country": values.country,
                "fundingStage": values.fundingStage,
                _id: idPeople,
            });
            apiLeads.updateLead({ data, headers })
                .then(response => {
                    getLeads(page);
                    handleClose();
                    setOpenSnackBar(true);
                    setSnackBarStatus(true);
                    setSnackValue(response.message);
                })
                .catch(error => {
                    setOpenSnackBar(true);
                    setSnackBarStatus(false);
                    setSnackValue(getResponseErrorMessage(error));
                });
        }
    }
    const deleteValue = (id) => {
        const headers = {
            'user_id': user_id,
            'token_id': token_id,
        }
        apiLeads.deleteLead({ headers, _id: id })
            .then(function (response) {
                getLeads(page)
                handleClose()
                setOpenSnackBar(true)
                setSnackBarStatus(true);
                if (response.message) {
                    setSnackValue('Lead delete successfully!')
                } else setSnackValue('Lead delete successfully!')

            })
            .catch(function (error) {
                console.log(error);
                // setSnackValue(error)
            })
    }
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false)
    }
    const handleClickOpen = (target, id, item) => {
        if (id) setIdPeople(id)
        if (target === 'add') {
            setPropsDialog('Add Leads')
        } else {
            setValueLeadStatus(item.leadStatus)
            setValues(item)
            setPropsDialog('Update Lead')
        }
        setOpen(true);
    };

    const handleClickExportCSV = () => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/lead/loadCSV`,
            headers: {
                'user_id': user_id,
                'token_id': token_id,
                'Content-Type': 'application/json'
            }
        };
        axios(config)
            .then(function (response) {
                FileDownload(response.data, 'leads.csv')
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleClickExportXL = () => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/lead/loadXLSX`,
            headers: {
                'user_id': user_id,
                'token_id': token_id,
                'Content-Type': 'application/json'
            },
            responseType: 'arraybuffer'
        };
        axios(config)
            .then(function (response) {
                // console.log(response.data)
                FileDownload(response.data, 'leads.xlsx')
            })
            .catch(function (error) {
                console.log(error);
            })
    };



    const handleClose = () => {
        setOpen(false);
    };
    const prevPage = () => {
        getLeads(page - 1)
        setPage(page - 1);
    }

    const nextPage = () => {
        getLeads(page + 1)
        setPage(page + 1);
    }

    const handleChangePagination = (e, value) => {
        getLeads(value)
        setPage(value)
    }

    const handleChangeSearch = (e) => {
        setValueSearch(e.target.value)
    }

    const [dataForPostXL, setDataForPostXL] = React.useState();

    const handleChangeImportCSV = (e) => {
        const headers = {
            'user_id': user_id,
            'token_id': token_id,
        }
        const file = e.target.files[0];
        fileReader.onload = function (event) {
            const csvOutput = event.target.result;
            apiLeads.importLeadsFromCSV({ data: csvOutput, headers })
                .then(res => {
                    setOpenSnackBar(true)
                    if (res.err) {
                        setSnackBarStatus(false);
                        setSnackValue(res.message);
                    } else {
                        setSnackBarStatus(true);
                        setSnackValue(res.message);
                    }
                    handleClose();
                    getLeads(page);
                })
                .catch(err => console.log(err))
        };
        fileReader.readAsText(file);
    };

    const handleChangeImportXL = (e) => {
        const file = e.target.files[0];
        readFile(file)
            .then((readedData) => {
                var result = readedData.SheetNames.map(function (name) {
                    var _ref;

                    var ws = readedData.Sheets[name];
                    var dataParse = XLSX.utils.sheet_to_json(ws, {
                        header: 1,
                        defval: ''
                    });
                    return _ref = {}, _ref[name] = dataParse, _ref;
                });
                setDataForPostXL(result[0])
            })
            .catch((error) => console.error(error));
    };

    React.useEffect(() => {
        if (dataForPostXL) {
            const headers = {
                'user_id': user_id,
                'token_id': token_id,
                'Content-Type': 'application/json'
            }
            const data = generateObjects(dataForPostXL);
            apiLeads.importLeadsFromXLSX({ data: { arrayXl: data }, headers })
                .then(function (response) {
                    // console.log(response)
                    // setOpenSnackBar(true)
                    // if (response.data.message) {
                    //     setSnackValue(response.data.message)
                    // } else setSnackValue('User delete successfully!')
                    getLeads(page);
                })
                .catch(function (error) {
                    console.log(error);
                })

        }
    }, [dataForPostXL])

    // console.log(dataForPostXL)

    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                <Box sx={{
                    display: 'flex', justifyContent: 'right', alignItems: 'center', pb: '2rem',
                    '.react-excel': { display: 'none' }
                }}>
                    <Input
                        hidden
                        accept=".xlsx"
                        id="importXLSX"
                        type="file"
                        onChange={handleChangeImportXL}
                        onClick={(e) => {
                            setDataForPostXL();
                            // console.log(e.target.value)
                        }} />
                    <Input
                        hidden
                        accept=".csv"
                        id="importCSV"
                        type="file"
                        onChange={handleChangeImportCSV}
                    />
                    <Button
                        sx={{
                            fontSize: '14px',
                            color: '#fff',
                            padding: '8px 16px',
                            textTransform: 'none',
                            borderRadius: '4px',
                            bgcolor: 'button.primary.background',
                            height: '40px',
                            '&:hover': {
                                bgcolor: 'button.primary.background',
                            }
                        }}
                        onClick={() => handleClickOpen('add')}>
                        <AddIcon sx={{ pr: '2px' }} />
                        Add Leads
                    </Button>
                    <MoreVertIcon
                        onClick={handleClickVertMenu}
                        sx={{ color: 'menu.points', fontSize: 30, cursor: 'pointer', pl: '1rem' }}
                        aria-controls={openVertMenu ? 'more-vert-icon-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openVertMenu ? 'true' : undefined} />
                    <Menu
                        anchorEl={vertMenu}
                        id="more-vert-icon-menu"
                        open={openVertMenu}
                        onClose={handleCloseVertMenu}
                        onClick={handleCloseVertMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                border: theme.mode !== 'light' ? `solid` : 'none',
                                borderWidth: '1px',
                                borderColor: '#45516F',
                                bgcolor: 'menu.background',
                                color: 'menu.text',
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    borderTop: theme.mode !== 'light' ? `solid` : 'none',
                                    borderLeft: theme.mode !== 'light' ? `solid` : 'none',
                                    borderWidth: '1px',
                                    borderColor: '#45516F',
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'menu.background',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem sx={{ label: { cursor: 'pointer' } }} >
                            <label htmlFor="importXLSX" >
                                Import from Excel
                            </label>
                        </MenuItem>
                        <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)' }} >
                            <label htmlFor="importCSV" >
                                Import from CSV
                            </label>
                        </MenuItem>
                        <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)' }} onClick={handleClickExportXL}>
                            Export to Excel
                        </MenuItem>
                        <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)', mb: '-4px' }} onClick={handleClickExportCSV}>
                            Export to CSV
                        </MenuItem>
                    </Menu>
                </Box>
                <Box sx={{ bgcolor: 'myLeads.block' }}>
                    <Table 
                        items={data} 
                        deleteValue={deleteValue} 
                        stars={false} 
                        handleClickOpen={handleClickOpen} 
                        page={page} 
                        theme={theme} 
                        setOpenSnackBar={setOpenSnackBar}
                        setSnackBarStatus={setSnackBarStatus}
                        setSnackValue={setSnackValue}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>

                    {paginationSettings && <PaginationCrm
                        page={page}
                        count={Math.ceil(paginationSettings.totalDocs / 10)}
                        prevPage={prevPage}
                        handleChange={handleChangePagination}
                        nextPage={nextPage} />}
                </Box>
                {/* <MyLeadsDetails /> */}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ '.MuiDialog-root': { background: 'rgba(13, 18, 31, 0.8)' }, '.MuiPaper-root': { background: 'none' }, background: 'rgba(13, 18, 31, 0.6)' }}
                >
                    <DialogAddFields
                        btnTitle={propsDialog}
                        handleClose={handleClose}
                        values={values}
                        handleChange={handleChange}
                        valueLeadStatus={valueLeadStatus}
                        handleValueLeadStatus={handleValueLeadStatus}
                        handleValueCountry={handleValueCountry}
                        handleValueFunding={handleValueFunding}
                        addValue={addValue}
                        theme={theme} />
                </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={openSnackBar}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackBar}
                    message={snackValue}
                    sx={{ '.MuiPaper-root': { background: snackBarStatus ? '#7B61FF' : 'red', minWidth: '100px', fontFamily: 'Montserrat' }, right: '32px !important' }} />
            </Layout >
        </Box >
    );
}

export default MyLeads;
