import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import React from "react";
import { useNavigate } from 'react-router-dom';
const Auth = () => {
    const { loginWithRedirect } = useAuth0();
    const { isLoading, isAuthenticated, user } = useAuth0();
    React.useEffect(() => {
        // if (!isAuthenticated && !isLoading) {
        //     loginWithRedirect();
        // }
    })

    return (
        <Box>

        </Box>
    )
}

export default Auth;