import React from 'react'
import { Grid, Box, Typography, TextField, OutlinedInput, InputAdornment, IconButton, Button, Link, Divider, FormControlLabel, Switch, Stack, Input } from '@mui/material';
import Layout from '../../layout';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme } from '@mui/material/styles';
import { apiUser } from '../../../api/apiUser';

const darkTheme = createTheme({
    mode: 'dark'
});

const lightTheme = createTheme({
    mode: 'light'
});

const AntSwitch = styled(Switch)(() => ({
    width: 48,
    height: 26,
    padding: 0,
    display: 'flex',
    borderRadius: 60,
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#177ddc',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 23,
        height: 23,
        borderRadius: 60,
        //   transition: theme.transitions.create(['width'], {
        //     duration: 200,
        //   }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            'rgba(255,255,255,.35)',
        boxSizing: 'border-box',
    },
}));

const tableData = [
    { name: 'Email', value: '' },
    { name: 'First Name', value: '', slugName: 'firstName' },
    { name: 'Last Name', value: '', slugName: 'lastName' },
    { name: 'Industry', value: '', slugName: 'industry' },
    { name: 'Sub-industry', value: '', slugName: 'subIndustry' },
    { name: 'Date Joined', value: '' }
]

function Settings({ setThemeChanged, themeChanged }) {
    const pageInfo = {
        activeMenuTitle: 'Settings',
        header: 'Settings',
    };

    const [theme, setTheme] = React.useState(darkTheme);
    // const [dataUser, setDataUser] = React.useState(tableData);
    // const [editMode, setEditMode] = React.useState(false);
    // const [inputValue, setInputValue] = React.useState({
    //     industry: '',
    //     subIndustry: ''
    // });

    React.useEffect(() => {
        let cleanupFunction = false;
        if (themeChanged === false) {
            if (!cleanupFunction) setTheme(darkTheme)
        }
        else {
            if (!cleanupFunction) setTheme(lightTheme)
        }
        return () => cleanupFunction = true;
    }, [themeChanged])

    const { user } = useAuth0();
    const [values, setValues] = React.useState({
        oldPassword: '',
        mewPassword: '',
        confirmPassword: '',
        username: '',
        showPassword: false,
        showPasswordConfirm: false,
    });
    const [errors, setErrors] = React.useState({
        username: false,
        oldPassword: false,
        mewPassword: false,
        confirmPassword: false,
        emptyUsername: false,
        emptyPassword: false,
        equalPassword: false,
    });
    const [rolesData, setRolesData] = React.useState([])
    const [checkedSwitch, setCheckedSwitch] = React.useState(false)
    const handleCheckedSwitch = () => {
        console.log('=================================');
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
        }
        const check = !checkedSwitch
        apiUser.setCheckAccess({ check, headers })
            .then(function (response) {
                setCheckedSwitch(!checkedSwitch)
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    React.useEffect(() => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
            'Cookie': 'adonis-session=4bd6d6044e4bf674b71a55e2dfe5fa05Db0XBzVCJ90oDzS0Ag0MuLiiNngv5hGD303Q4lEjWlWHgysFYZuECg9qIBcwYPgEYGwaUE%2BJ%2FTO6O0AL3abpAXUToOoDhAn%2FzczeJGIK0GER4VRCBWiXDkRjF5K6gdvm; adonis-session-values=96565522224df7ef8d7cf36b45c6313998dy2KgXssw1ggocA60GvQaKG1%2BISeGAjwJGChCwwfg0lBJLaEStEc7mMZF4Mc01W4JsGlr%2F7ovM6ztnRmamL5U3gGYdlGNnm0n9F8xC79MjRTEHG91UY4zVyKlSf0%2F4pxMTHSnoz7nDtcFYvuBjYBhhcwi9i2ZfUe%2B5OI5t1LA%3D'
        }
        apiUser.getCheckAccess({ headers })
            .then(function (response) {
                setCheckedSwitch(response.check)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const onSubmitUsername = () => {
        if (values.username === '') {
            setErrors({
                ...errors,
                emptyUsername: true,
            });
        } else {
            setErrors({
                ...errors,
                emptyUsername: false,
            });
        }
    };
    const onSubmitPassword = () => {
        console.log(values.oldPassword)
        if (values.oldPassword == '' || values.mewPassword == '' || values.confirmPassword == '') {
            setErrors({
                ...errors,
                emptyPassword: true,
            });
        }
        else {
            setErrors({
                ...errors,
                emptyPassword: false,
            });
            if (values.mewPassword !== values.confirmPassword) {
                setErrors({
                    ...errors,
                    equalPassword: true,
                });
            } else {
                setErrors({
                    ...errors,
                    equalPassword: false,
                });
            }
        }
    };
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = (ref) => {
        if (ref === "mewPassword") {
            setValues({
                ...values,
                showPassword: !values.showPassword,
            });
        } else if (ref === "confirmPassword") {
            setValues({
                ...values,
                showPasswordConfirm: !values.showPasswordConfirm,
            });
        }
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    React.useEffect(() => {
        // if (user) {
            const headers = {
                'user_id': localStorage.getItem('userId'),
                'token_id': localStorage.getItem('tokenID'),
            }
            apiUser.getUser({ headers })
                .then((res) => {
                    console.log(res)
                    setRolesData(res.roles[0]);
                    // dataUser[0].value = res.email;
                    // dataUser[1].value = res.given_name
                    // dataUser[2].value = res.family_name
                    // dataUser[3].value = res.industry
                    // dataUser[4].value = res.subIndustry
                    // dataUser[5].value = res.updated_at
                    // inputValue.industry = res.industry;
                    // inputValue.subIndustry = res.subIndustry;
                    // setInputValue({ ...inputValue })
                    // setDataUser([...dataUser])
                })
                .catch((error) => {
                    console.log(error)
                });
        // };
    }, []);

    // const handleSave = () => {
    //     const headers = {
    //         'user_id': localStorage.getItem('userId')
    //     }
    //     api.changeUser({ headers, industry: inputValue.industry, subIndustry: inputValue.subIndustry })
    //         .then(res => {
    //             console.log(res.doc.industry);
    //             inputValue.industry = res.doc.industry;
    //             inputValue.subIndustry = res.doc.subIndustry;
    //             setInputValue({ ...inputValue });
    //             setEditMode(!editMode);
    //         })
    //         .catch(err => {
    //             console.log(err)
    //         })
    // }

    // const handleEdit = () => {
    //     setEditMode(!editMode);
    //     if (editMode) {
    //         setInputValue({
    //             industry: dataUser[3].value,
    //             subIndustry: dataUser[4].value
    //         })
    //     }
    // }

    // const handleChangeInput = (e) => {
    //     // console.log(e.target.value)
    //     inputValue[e.target.name] = e.target.value;
    //     setInputValue({ ...inputValue })
    // }

    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                <Grid container>
                    <Grid item xs={4}>
                        {rolesData === 'ADMIN' && <Box>
                            <Typography sx={{ color: theme.mode === 'light' ? 'text.primary' : 'text.secondary', fontSize: '16px', fontFamily: 'Montserrat' }}>Checking user access to the system</Typography>
                            <Stack mt={2} direction="row" spacing={1} alignItems="center">
                                <Typography sx={{ color: theme.mode === 'light' ? 'text.primary' : 'text.secondary', fontSize: '16px', fontFamily: 'Montserrat' }}>Off</Typography>
                                <AntSwitch checked={checkedSwitch} onChange={handleCheckedSwitch} inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography sx={{ color: theme.mode === 'light' ? 'text.primary' : 'text.secondary', fontSize: '16px', fontFamily: 'Montserrat' }}>On</Typography>
                            </Stack>
                        </Box>}
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <Box sx={{ bgcolor: 'settings.editMenu.background', p: '1rem 2rem', borderRadius: '8px' }}>
                            {dataUser && dataUser.map((item, key) => (
                                <Box key={item.name} sx={{
                                    display: 'flex', borderTop: 'solid 1px', p: '0 1rem ',
                                    borderBottom: key === dataUser.length - 1 ? 'solid 1px' : '',
                                    '&:last-child': { borderBottom: 'solid 1px' }
                                }}>
                                    <Typography sx={{ color: theme.mode === 'light' ? 'text.primary' : 'text.secondary', p: '1rem', minWidth: '40%' }}>{item.name}</Typography>
                                    {item.name === 'Email' || item.name === 'Date Joined' ?
                                        <Typography sx={{
                                            p: '1rem',
                                            color: theme.mode === 'light' ? 'text.primary' : 'text.secondary',
                                            fontStyle: item.name === 'Date Joined' ? 'oblique' : 'normal'
                                        }}>
                                            {item.name === 'Date Joined' ? moment(item.value).format('MMM. DD, YYYY, h:mma') : item.value}
                                        </Typography> :
                                        <Box sx={{ minWidth: '52%', pt: '0.3rem' }}>
                                            <OutlinedInput
                                                fullWidth
                                                disabled={editMode && (item.name === 'Industry' || item.name === 'Sub-industry') ? false : true}
                                                value={editMode && (item.name === 'Industry' || item.name === 'Sub-industry') ? inputValue[item.slugName] : item.value}
                                                onChange={handleChangeInput}
                                                name={item.slugName}
                                                sx={{
                                                    'input': {
                                                        color: 'input.color',
                                                        p: '0'
                                                    },
                                                    "& .Mui-disabled": {
                                                        color: "rgba(208, 210, 214, 1)",
                                                        '-webkit-text-fill-color': theme.mode === 'dark' ? 'rgba(208, 210, 214, 0.6) !important' : 'rgba(3, 3, 3, 0.6) !important'   // (default alpha is 0.38)
                                                    },
                                                    '&.MuiOutlinedInput-root': {
                                                        p: '8px 0 8px 1rem',
                                                        height: '45px',
                                                        border: 'solid',
                                                        borderColor: 'input.borderColor',
                                                        borderWidth: '1px',
                                                    },
                                                    'fieldset': {
                                                        border: 'none'
                                                    }
                                                }}
                                            />
                                        </Box>}
                                </Box>
                            ))}
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: '1rem' }}>
                                {editMode ?
                                    <Box>
                                        <Button
                                            onClick={handleEdit}
                                            sx={{
                                                color: 'button.settings.background',
                                                border: 'solid 1px',
                                                borderColor: 'button.settings.borderColor',
                                                ':hover': {
                                                    bgcolor: 'button.settings.hoverBackground'
                                                }
                                            }}>
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleSave}
                                            sx={{
                                                ml: '1rem',
                                                color: 'button.settings.background',
                                                border: 'solid 1px',
                                                borderColor: 'button.settings.borderColor',
                                                ':hover': {
                                                    bgcolor: 'button.settings.hoverBackground'
                                                }
                                            }}>
                                            Save
                                        </Button>
                                    </Box>
                                    : <Button
                                        onClick={handleEdit}
                                        sx={{
                                            color: 'button.settings.background',
                                            border: 'solid 1px',
                                            borderColor: 'button.settings.borderColor',
                                            ':hover': {
                                                bgcolor: 'button.settings.hoverBackground'
                                            }
                                        }}>
                                        Edit
                                    </Button>}
                            </Box>
                        </Box>
                    </Grid> */}
                </Grid>
            </Layout>
        </Box >
    );
}

export default Settings;
