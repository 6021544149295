import { useState, useEffect } from 'react';
import { Box, Button, Select, MenuItem, OutlinedInput, Typography, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const CreateZoomMeet = ({
    onSubmit,
    values,
    errors,
    setValues,
    handleChange,
    theme
}) => {
    const types = [
        { value: 1, label: 'An instant meeting' },
        { value: 2, label: 'A scheduled meeting' },
    ];

    const [startTime, setStartTime] = useState(new Date().setMinutes(new Date().getMinutes() + 10));

    const handleChangeTimeStart = (newValue) => {
        setStartTime(newValue);
    };

    useEffect(() => {
        setValues({ ...values, startTime });
    }, [startTime])

    return (
        <Box sx={{ maxWidth: '580px', margin: '0 auto' }}>
            <Box>
                <Typography sx={{
                    fontSize: '16px',
                    letterSpacing: '0.2px',
                    lineHeight: '24px',
                    color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    m: '24px 33px 8px'
                }}>Topic</Typography>
                <TextField fullWidth variant="outlined" color="secondary"
                    value={values.topic}
                    error={errors.topic}
                    helperText={ errors.topic && "Topic is required." }
                    onChange={handleChange('topic')}
                    placeholder='Enter topic'
                    sx={{
                        '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                        '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                    }} />

                <Typography sx={{
                    fontSize: '16px',
                    letterSpacing: '0.2px',
                    lineHeight: '24px',
                    color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    m: '24px 33px 8px'
                }}>Password</Typography>
                <TextField fullWidth variant="outlined" color="secondary"
                    error={errors.password}
                    helperText={ errors.password && "Password is required." }
                    value={values.password}
                    onChange={handleChange('password')}
                    placeholder='Enter password'
                    sx={{
                        '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                        '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                    }} />

                <Typography sx={{
                    fontSize: '16px',
                    letterSpacing: '0.2px',
                    lineHeight: '24px',
                    color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    m: '24px 33px 8px'
                }}>Type</Typography>

                <Select
                    fullWidth
                    value={values.type}
                    onChange={handleChange('type')}
                    input={<OutlinedInput label="Leads" sx={{
                        color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                        'input': {
                            color: theme.mode === "light" ? 'text.title' : 'text.secondary'
                        },
                        'svg': { color: '#b4b7bd' },
                        '&.MuiOutlinedInput-root': {
                            border: 'solid',
                            borderColor: 'dialogs.border',
                            borderWidth: '1px',
                        },
                        'fieldset': {
                            border: 'none'
                        }
                    }} />}
                >
                    {types && types.map((item) => (
                        <MenuItem
                            key={item.value}
                            value={item.value}
                            sx={{ color: 'input.text' }}
                        >
                            {item.label}
                        </MenuItem>))}
                </Select>
            </Box>

            {values.type > 1 && <Box>
                <Typography sx={{
                    fontSize: '16px',
                    letterSpacing: '0.2px',
                    lineHeight: '24px',
                    color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    m: '24px 33px 8px'
                }}>Duration</Typography>
                <TextField fullWidth variant="outlined" color="secondary"
                    value={values.duration}
                    type="number"
                    onChange={handleChange('duration')}
                    placeholder='Enter duration'
                    sx={{
                        '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                        '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                    }} />
            </Box>}

            {values.type > 1 && <Box sx={{
                'svg': {
                    color: theme.mode === "light" ? 'text.title' : 'text.secondary'
                },
                '.MuiFormControl-root': { width: '100%' },
            }}>
                <Typography sx={{
                    fontSize: '16px',
                    letterSpacing: '0.2px',
                    lineHeight: '24px',
                    color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    m: '24px 33px 8px'
                }}>Start Date Event</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        value={values.startTime}
                        minDateTime={new Date().setMinutes(new Date().getMinutes() + 5)}
                        onChange={handleChangeTimeStart}
                        renderInput={(params) => <TextField
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }}
                            variant="outlined" {...params} />}
                    />
                </LocalizationProvider>
            </Box>}

            <Box sx={{ width: '147px', m: '25px auto', textAlign: 'center' }}>
                <Button sx={{
                    fontSize: '14px',
                    color: '#fff',
                    padding: '8px 16px',
                    textTransform: 'none',
                    borderRadius: '4px',
                    bgcolor: 'button.primary.background',
                    '&:hover': {
                        bgcolor: 'button.primary.background'
                    },
                }}
                    onClick={onSubmit}>Add Meet</Button>
            </Box>
        </Box>
    )
}

export default CreateZoomMeet;