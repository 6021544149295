import React from 'react'
import { Grid, Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Link, useLocation } from "react-router-dom";
import CircleIcon from '@mui/icons-material/Circle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { createTheme } from '@mui/material/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { NotificationContext } from "../../contexts/NotificationContext";

const tableWidth = {
    id: '3%',
    company: '15%',
    partner: '15%',
    role: '15%',
    link: '6%',
    email: '22%',
    notes: '13%',
    actions: '11%'
};

const headerTable = [
    { width: 'id', name: '#' },
    { width: 'company', name: 'Company' },
    { width: 'partner', name: 'Partner' },
    { width: 'role', name: 'Role' },
    { width: 'link', name: 'Link' },
    { width: 'email', name: 'Email' },
    { width: 'notes', name: 'Notes' },
    { width: 'actions', name: '' },
];

const DashboardListLeadsTable = ({
    items,
    handleDelete,
    handleClickOpen,
    page,
    theme
}) => {

    const { notificationLeads } = React.useContext(NotificationContext);

    const location = useLocation();

    const getParamsSlug = (item) => {
        let slug = 'none';

        if (item.company) {
            slug = item.company.toLowerCase();
        }

        if (item.company_slug) {
            slug = item.company_slug.toLowerCase();
        }

        return slug;
    };

    const isCompanyInfo = (item) => false; //item.company_slug || item.company;
    const isConversation = (item) => item.email && item.company_slug;
    // const isUnread = (unreadUserIds) => Array.isArray(unreadUserIds) && unreadUserIds.indexOf(localStorage.getItem('userId')) !== -1;
    const isUnread = (id) => notificationLeads.dashboardLeads.indexOf(id) !== -1;

    if (!theme) {
        theme = createTheme({
            mode: 'dark',
        });
    }

    return (
        <Box>
            <Grid container sx={{ bgcolor: 'table.grid.primary', width: '100%' }}>

                {headerTable.map((item) => (
                    <Grid item sx={{ width: tableWidth[item.width], p: '20px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }} key={item.name}>
                        <Typography sx={{ color: 'text.secondary', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0.2px', textAlign: 'center', fontFamily: 'Montserrat' }}>{item.name}</Typography>
                    </Grid>
                ))}
            </Grid>

            {items?.map((item, index) => (
                <Grid key={index + 1} container sx={{ bgcolor: theme.mode === 'light' ? 'table.grid.secondary' : (index + 1) % 2 ? 'rgba(22, 29, 49, 0.4)' : 'rgba(22, 29, 49, 0.2)', zIndex: 9999, width: '100%' }}>
                    <Grid item sx={{ width: tableWidth.id, p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', textAlign: 'center', fontFamily: 'Montserrat' }}>{index + 1 + (page - 1) * 10}</Typography>
                    </Grid>

                    <Grid item sx={{ width: tableWidth.company, p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: 'text.link', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat', textDecoration: 'underline', wordBreak: 'break-all' }}>
                            {item.company}
                        </Typography>
                    </Grid>

                    <Grid item sx={{ width: tableWidth.partner, p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat' }}>{item.name}</Typography>
                    </Grid>

                    <Grid item sx={{ width: tableWidth.role, p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat' }}>{item.role}</Typography>
                    </Grid>

                    <Grid item sx={{ width: tableWidth.link, p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border', display: 'flex', justifyContent: 'center' }} >
                        <Typography component='a' href={item.linkedin} target='_blank' sx={{ textAlign: 'center' }} >
                            <LinkedInIcon sx={{ color: 'icons', fontSize: '25px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                        </Typography>
                    </Grid>

                    <Grid item sx={{ width: tableWidth.email, p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat', wordBreak: 'break-all' }}>{item.email}</Typography>
                    </Grid>

                    <Grid item sx={{ width: tableWidth.notes, p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'table.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat', wordBreak: 'break-all' }}>{item.notes}</Typography>
                    </Grid>

                    <Grid container sx={{ width: tableWidth.actions, p: '16px 6px', margin: 0 }} justifyContent="center">
                        {isCompanyInfo(item) && <Grid item sx={{ border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `none`, borderColor: 'table.grid.border', borderLeft: 'none' }}>
                            <Link to={`/companyInfo/leadDashboard/${getParamsSlug(item)}/${item._id}`} >
                                <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                                    <VisibilityOutlinedIcon sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                                </Box>
                            </Link>
                        </Grid>}

                        {isConversation(item) && <Grid item sx={{ border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `none`, borderColor: 'table.grid.border', borderLeft: 'none' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                                <Link to={`/conversation/${item.email}/${item.company_slug}/false?isDashboard=1`} >
                                    <ChatBubbleOutlineIcon sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                                    {isUnread(item._id) && <CircleIcon sx={{
                                        position: 'absolute',
                                        fill: '#DB6AB5',
                                        right: '-2px',
                                        top: '0',
                                        borderRadius: '50%',
                                        fontSize: '10px'
                                    }} />}
                                </Link>
                            </Box>
                        </Grid>}

                        <Grid item sx={{ border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `none`, borderColor: 'table.grid.border', borderLeft: 'none' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <EditIcon onClick={() => handleClickOpen('update', item._id, item)} sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                            </Box>
                        </Grid>

                        <Grid item sx={{ border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `none`, borderColor: 'table.grid.border', borderLeft: 'none' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <DeleteOutlineIcon onClick={() => handleDelete(item._id)} sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                            </Box>
                        </Grid>
                    </Grid>

                </Grid >
            ))
            }
        </Box >
    );
}

export default DashboardListLeadsTable;
