import axios from "axios";

export const updateToken = async (error, instance) => {
    const originalRequest = error.config;
    // console.log(originalRequest)
    if (error.response.data.message === "Access denied") {
        const uri = `${process.env.REACT_APP_GOOGLE_AUTH_URI}?redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=${process.env.REACT_APP_SCOPES}&access_type=offline`;
        localStorage.removeItem('tokenID');
        localStorage.removeItem('name');
        window.location = uri;
    }
    if (error.response.status == 403 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/auth/updateToken?token_id=${localStorage.getItem('tokenID')}&userEmail=${localStorage.getItem('userEmail')}`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(res => {
                    console.log(response)
                    originalRequest.headers.token_id = response.data.token;
                    console.log(response.data.token)
                    localStorage.setItem('tokenID', response.data.token)
                    // console.log(originalRequest)
                    return instance.request(originalRequest);
                })
                .catch(err => {
                    console.log(error.status)
                    const uri = `${process.env.REACT_APP_GOOGLE_AUTH_URI}?redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=${process.env.REACT_APP_SCOPES}&access_type=offline`;

                    window.location = uri;
                    return
                })
            // console.log(response)
            // originalRequest.headers.token_id = response.data.token;
            // console.log(response.data.token)
            // localStorage.setItem('tokenID', response.data.token)
            // console.log(originalRequest)
            // return instance.request(originalRequest);
        } catch (error) {
            console.log('error')
        }
    }
}