import React from 'react'
import { Grid, Box, Typography, Tab, Tabs, InputAdornment, IconButton, Button, Link } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import inIcon from '../../../assets/images/inIcon.svg'
import settings from '../../../assets/images/settings.svg'
import search from '../../../assets/images/search.svg'
import { styled } from '@mui/system';

const StepsTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#D0D2D6',
    padding: '16px 16px 14px',
    opacity: 0.6,
    '&.Mui-selected': {
        fontFamily: 'Montserrat',
        letterSpacing: '0.4000000059604645px',
        color: '#D0D2D6',
        opacity: 1,
        borderBottomColor: '#fff'
    },
}));
const StyledTabs = styled((props) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
    '& .MuiTabs-indicator': {
        backgroundColor: '#7B61FF',
    }
});
const details = {
    title: 'Sharon French',
    subTitle: 'THE TIFIN GROUP',
    logo: <img src={inIcon} alt="in" />,
    mail: 'french@tifin.group',
    contactorName: 'John Smith',
    notes: 'Some notes Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna'
}
const detailsItems = [
    {
        title: 'Re: Our meeting',
        date: '07.02.2022',
        description: 'Dear Sharon, Kindly accept the...'
    },
    {
        title: 'Re: Our meeting',
        date: '07.02.2022',
        description: 'Dear Sharon, Kindly accept the...'
    },
    {
        title: 'Re: Our meeting',
        date: '07.02.2022',
        description: 'Dear Sharon, Kindly accept the...'
    },
    {
        title: 'Re: Our meeting',
        date: '07.02.2022',
        description: 'Dear Sharon, Kindly accept the...'
    },
    {
        title: 'Re: Our meeting',
        date: '07.02.2022',
        description: 'Dear Sharon, Kindly accept the...'
    },
]
function MyLeadsDetails() {
    const [activeStep, setActiveStep] = React.useState(0)
    const handleChange = (event, newValue) => {
        setActiveStep(newValue);
    };
    return (
        <Grid container spacing={5}>
            <Grid item xs={3}>
                <Box sx={{ background: '#293145', borderRadius: '4px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', p: '16px 25px' }}>
                        <ArrowBackIosIcon sx={{ fill: '#fff', fontSize: '18px', p: '0 10px' }} />
                        <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: '#D0D2D6', fontFamily: 'Montserrat' }}>
                            Back to My Leads
                        </Typography>
                    </Box>
                    <Box sx={{ p: '16px', background: 'rgba(22, 29, 49, 0.2)' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '20px', lineHeight: '28px', color: '#D0D2D6', fontFamily: 'Montserrat' }}>
                                {details.title}
                            </Typography>
                            <Box>
                                {details.logo}
                            </Box>
                        </Box>
                        <Box mt={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '12px', lineHeight: '20px', color: '#D0D2D6', fontFamily: 'Montserrat' }}>
                                {details.subTitle}
                            </Typography>
                            <Typography sx={{ fontSize: '12px', lineHeight: '20px', color: '#D0D2D6', fontFamily: 'Montserrat', textAlign: 'right' }}>
                                {details.mail}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ p: '16px', background: 'rgba(22, 29, 49, 0.4)' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: '#D0D2D6', fontFamily: 'Montserrat', fontWeight: 600 }}>
                                Contactor name:
                            </Typography>
                            <Typography sx={{ fontSize: '12px', lineHeight: '20px', color: '#D0D2D6', fontFamily: 'Montserrat', textAlign: 'right' }}>
                                {details.contactorName}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ p: '16px', background: 'rgba(22, 29, 49, 0.2)' }}>
                        <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: '#D0D2D6', fontFamily: 'Montserrat', fontWeight: 600 }}>
                            Notes:
                        </Typography>
                        <Typography mt={1} sx={{ fontSize: '12px', lineHeight: '20px', color: '#D0D2D6', fontFamily: 'Montserrat' }}>
                            {details.notes}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={9}>
                <Box sx={{ background: '#293145', borderRadius: '4px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex' }}>
                            <StyledTabs value={activeStep} onChange={handleChange} sx={{ width: { xs: '100%' } }}>
                                <StepsTab
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        textTransform: 'capitalize !important',
                                    }}
                                    label="Emails"
                                />
                                <StepsTab
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        textTransform: 'capitalize !important',
                                    }}
                                    label="Meetings"
                                />
                                <StepsTab
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        textTransform: 'capitalize !important',
                                    }}
                                    label="Tasks"
                                />
                            </StyledTabs>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', p: '12px 16px' }}>
                            <Box sx={{ cursor: 'pointer', m: '3px 20px 0 0' }}>
                                <img src={search} alt="search" />
                            </Box>
                            <Box sx={{ cursor: 'pointer', m: '3px 20px 0 0' }}>
                                <img src={settings} alt="settings" />
                            </Box>
                            <Button sx={{
                                fontSize: '14px',
                                lineHeight: '24px',
                                color: '#fff',
                                fontFamily: 'Montserrat',
                                fontWeight: 500,
                                letterSpacing: '0.2px',
                                background: '#7B61FF',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                '&:hover': {
                                    background: '#7B61FF'
                                }
                            }}>Create an Email</Button>
                        </Box>
                    </Box>
                    <Box>
                        {detailsItems.map((item, index) => (
                            <Grid container hey={index + 1} sx={{ background: (index + 1) % 2 ? 'rgba(22, 29, 49, 0.4)' : 'rgba(22, 29, 49, 0.2)' }}>
                                <Grid item sx={{ width: '35%', borderRight: '1px solid #45516F', p: '16px' }}>
                                    <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: '#D0D2D6', fontFamily: 'Montserrat' }}>
                                        {item.title}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ width: '20%', borderRight: '1px solid #45516F', p: '16px' }}>
                                    <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: '#D0D2D6', fontFamily: 'Montserrat' }}>
                                        {item.date}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ width: '45%', p: '16px' }}>
                                    <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: '#D0D2D6', fontFamily: 'Montserrat' }}>
                                        {item.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                </Box>
            </Grid>
        </Grid >
    );
}

export default MyLeadsDetails;
