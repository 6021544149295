import { createTheme } from '@mui/material/styles';
const themeDark = createTheme({
  palette: {
    background: {
      paper: '#283046',
    },

    input: {
      borderColor: '#414655',
      disabledColor: 'rgba(208, 210, 214, 0.6)',
      color: 'rgba(208, 210, 214, 1)',
      text: 'rgba(208, 210, 214, 1)',
    },

    button: {
      primary: {
        background: "#7B61FF !important",
      },
      secondary: {
        buttonText: "#7B61FF",
        border: "#7B61FF",
      },
      pagination: {
        buttonText: "#b4b7bd",
        background: "none",
      },
      settings: {
        background: "#7B61FF",
        borderColor: "#7B61FF",
        hoverBackground: "#7b61ff80"
      }
    },

    icons: "#D0D2D6",

    menu: {
      points: "#FFFFFF",
      background: "#272F43",
      text: "#FFFF",
      is: '#7B61FF',
    },

    load: {
      background: 'rgb(22, 29, 49)',
      circle: '#7B61FF',
    },

    text: {
      primary: "#FFFFFF",
      secondary: "#D0D2D6",
      link: "#7B61FF",
      title: "#D0D2D6",
      arrow: "#D0D2D6",
    },

    layout: {
      icon: '#FFFFFF',
      background: {
        main: "rgb(22, 29, 49)",
        block: "#293145",
        divider: "#7A838F",
        navbarItems: {
          active: "#7B61FF",
          default: "#293145",
          typography: "#fff",
        },
      },
      typography: {
        main: "#fff",
        name: "#d0d2d6",
      },
    },

    home: {
      graph: {
        primary: "#FFFFFF",
        secondary: "#FFFFFF",
      },
      graphLineOne: {
        1: "#C4C4C4",
        2: "#77B529",
        3: "#EA920F",
      },
      graphLineTwo: {
        1: "#7B61FF",
        2: "#00CFE8",
        3: "#2A99FF",
        4: "#28C7AA",
      },
      grid: {
        primary: "rgba(22, 29, 49, 0.6)",
        secondary: "#FFFFFF",
        border: "#45516F",
      },
      block: "#293145",
      divider: "#7A838F",
    },

    allPeople: {
      arrow: "#FFFFFF",
      grid: {
        primary: "rgba(22, 29, 49, 0.6)",
        secondary: "#FFFFFF",
        border: "#45516F",
      },
      search: {
        icon: '#D0D2D6'
      },
      checkbox: {
        label: "#D0D2D6",
        color: "#7B61FF !important",
      },
      block: "#293145",
    },

    pagination: {
      arrow: "#FFFFFF",
      grid: {
        border: "#45516F",
      },
    },

    table: {
      grid: {
        primary: "rgba(22, 29, 49, 0.6)",
        secondary: "#FFFFFF",
        border: "#45516F",
      },
      block: "#293145",
    },

    myLeads: {
      mode: "dark",
      arrow: "#FFFFFF",
      grid: {
        primary: "rgba(22, 29, 49, 0.6)",
        secondary: "#FFFFFF",
        border: "#45516F",
      },
      block: "#293145",
    },

    myLists: {
      mode: "dark",
      arrow: "#FFFFFF",
      grid: {
        primary: "rgba(22, 29, 49, 0.6)",
        secondary: "#FFFFFF",
        border: "#45516F",
      },
      block: "#293145",
    },

    sampleMessage: {
      mode: "dark",
      sample: {
        background: "#293145",
        border: "#45516F",
      },
      arrow: "#FFFFFF",
      grid: {
        border: "#45516F",
      },
    },

    admins: {
      mode: "dark",
      arrow: "#FFFFFF",
      grid: {
        primary: "rgba(22, 29, 49, 0.6)",
        secondary: "#FFFFFF",
        border: "#45516F",
      },
      block: "#293145",
    },

    tableList: {
      mode: "dark",
      grid: {
        primary: "rgba(22, 29, 49, 0.6)",
        secondary: "#FFFFFF",
        border: "#45516F",
      },
      block: "#293145",
    },

    conversation: {
      mode: 'dark',
      conversation: {
        background: '#293145',
        page: '#7B61FF',
        border: 'rgba(69, 81, 111, 0.4)',
        noMessage: '#242B3F',
        message: 'rgba(22, 29, 49, 1)',
      },
    },

    dialogs: {
      background: '#272F43',
      divider: '#7A838F',
      border: '#414655',
    },

    settings: {
      editMenu: {
        background: "#293145",
        title: "#D0D2D6",
        border: "#45516F",
      },
    },

    companyInfo: {
      text: {
        secondary: '#D0D2D6',
        description: '#6B7280',
        bold: '#7367F0'
      }
    }
  },
});
export default themeDark