import axios from 'axios';
import { updateToken } from '../utils/updateToken';
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});


instance.interceptors.request.use((config) => {
    config.headers.userEmail = localStorage.getItem('userEmail');
    config.headers.token_id = localStorage.getItem('tokenID')
    // config.headers.token_id = localStorage.getItem('tokenID');
    return config;
})


instance.interceptors.response.use((config) => {
    return config;
}, async (error) => updateToken(error, instance))

export const apiMessage = {
    getMessage: async ({ isDashboard, email, headers }) => {
        return (
            await instance.get('/message/getMessages', { params: { email, isDashboard }, headers })
        ).data;
    },
    sendMessage: async ({ data, headers }) => {
        return (
            await instance.post('/message/sendMessage', data, { headers })
        ).data;
    },
    getDataMessage: async ({ headers }) => {
        return (
            await instance.get('/message/getDataMessage', { headers })
        ).data;
    },
    // updateMessage: async (params) => {
    //     return (
    //         await instance.post('/message/login', params)
    //     ).data;
    // },
    getCountMessages: async ({ headers }) => {
        return (
            await instance.get('/message/getCountsMessages', { headers })
        ).data;
    },
    getSampleMessages: async () => {
        return (
            await instance.get('/message/getSampleMessages')
        ).data;
    },

    getChosenSampleMessage: async (params) => {
        return (
            await instance.get('/auth/login', params)
        ).data;
    },
    getMessageCreated: async ({ text, email, headers }) => {
        console.log(text)
        return (
            await instance.get('/message/getMessageCreated', { params: { text, email }, headers })
        ).data;
    },
    noteChangeMessage: async ({ company, email, headers }) => {
        return (
            await instance.get('/message/noteChangeMessage', { params: { email, company }, headers })
        ).data;
    },
}