import React from 'react'
import { Grid, Box, Typography, TextField, OutlinedInput, InputAdornment, IconButton, Button, Link } from '@mui/material';
import dka_with_text_white_logo from '../../../assets/images/dka_with_text_white_logo.png'
import passOpen from '../../../assets/images/passOpen.svg'
import passClose from '../../../assets/images/passClose.svg'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
function Authorization() {
    const [values, setValues] = React.useState({
        login: '',
        password: '',
        showPassword: false,
    });
    const navigate = useNavigate()
    React.useEffect(() => {
        if (localStorage.getItem('authToken')) {
        navigate('/home')
        }
    }, []);


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleSubmit = () => {
        const data = JSON.stringify({
            "email": values.login,
            "password": values.password,
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/auth/login`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                
                localStorage.setItem('authToken', response.data.token)
                if(response.data.userData.peopleFavorites){
                    localStorage.setItem('favorites', JSON.stringify(response.data.userData.peopleFavorites) )
                }
                navigate('/home')
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    return (
        <Box className='bgMain' sx={{ height: '100vh', position: 'relative' }}>
            <Grid container>
                <Grid item container direction="column"
                    justifyContent="space-between" alignItems='center' xs={8} sx={{ padding: '30px', height: '100vh', display: { xs: 'none', md: 'flex' }, background: '#213046' }}>
                    <Grid xs={4}>

                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{ width: '80%', margin: '0 auto' }}>
                            <Typography mt={5} sx={{ fontSize: '2.25rem', fontWeight: 'bold', lineHeight: 2, color: '#d0d2d6' }}>Knowledge is Power
                                Deep Knowledge is Transcendent Power</Typography>
                        </Box>
                    </Grid>
                    <Grid xs={4}>

                    </Grid>
                </Grid>
                <Grid item container direction="column"
                    justifyContent="space-between" xs={12} md={4} sx={{ background: '#283046', height: '100vh' }}>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={6} sx={{ padding: { xs: '0 1rem', md: '0 4rem' } }}>
                        <Typography mt={5} mb={1} sx={{ fontSize: '1.714rem', fontWeight: 500, color: '#d0d2d6' }}>Welcome!</Typography>
                        <Typography mb={2} sx={{ fontSize: '1rem', color: '#b4b7bd' }}>Please sign-in to your account and start the adventure</Typography>
                        <Typography sx={{ fontSize: '0.857rem', color: '#d0d2d6' }}>Login</Typography>
                        <TextField id="outlined-basic" color="secondary" variant="outlined"
                            value={values.login}
                            onChange={handleChange('login')}
                            sx={{
                                '.MuiOutlinedInput-root': {
                                    border: '1px solid #404656',
                                    color: '#6e6b7b',
                                    background: '#e8f0fe',
                                    borderRadius: '0.357rem',

                                },
                                '.MuiOutlinedInput-input': {
                                    padding: '0.438rem 1rem',
                                    fontSize: '1rem',
                                    fontWeight: 400,
                                    lineHeight: 1.45,
                                },
                                width: '100%'
                            }} />
                        <Grid mt={2} container justifyContent="space-between">
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: '0.857rem', color: '#d0d2d6' }}>Password</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography sx={{ fontSize: '0.857rem', color: '#039BFD' }}>Forgot Password?</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <OutlinedInput
                            sx={{
                                '.MuiOutlinedInput-root': {
                                    border: '1px solid #3b4253',
                                    color: '#6e6b7b',
                                    background: '#e8f0fe',
                                    borderRadius: '0.357rem',

                                },
                                '.MuiOutlinedInput-input': {
                                    padding: '0.438rem 1rem',
                                    fontSize: '1rem',
                                    fontWeight: 400,
                                    lineHeight: 1.45,
                                    background: '#e8f0fe',
                                    borderRadius: '0.357rem 0 0 0.357rem',
                                },
                                width: '100%'
                            }}
                            color="secondary"
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="center">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ?
                                            <Box sx={{ padding: '5px' }}>
                                                <img width='16px' src={passClose} />
                                            </Box>
                                            : <Box sx={{ padding: '5px' }}>
                                                <img width='16px' src={passOpen} />
                                            </Box>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Button sx={{
                            background: '#02388B',
                            fontWeight: 500,
                            textTransform: 'none',
                            width: '100%',
                            padding: '0.438rem 1rem',
                            color: '#fff',
                            fontSize: '1rem',
                            mt: '1rem',
                            '&:hover': {
                                boxShadow: '0 8px 25px -8px #039bfd',
                                background: '#02388B',
                            }
                        }} onClick={handleSubmit}>Sign In</Button>
                        <Typography mb={2} sx={{ fontSize: '14px', color: '#b4b7bd', textAlign: 'center', width: '90%', m: '1rem auto 0' }}>New on our platform?&nbsp;
                            <Link href="#" sx={{ color: '#039BFD', textDecoration: 'none' }}>Create an account</Link></Typography>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ position: 'absolute', top: '30px', left: '30px' }}>
                <img width="150px" src={dka_with_text_white_logo} />
            </Box>
        </Box>
    );
}

export default Authorization;
