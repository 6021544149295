import { Box, Button, Divider, Typography, Grid, TextField, FormControlLabel, RadioGroup, Radio, Popover, TextareaAutosize } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';




const DialogAddSampleText = ({ values, handleClose, btnTitle, handleChange, handleCreateMessage, handleUpdateMessage, idMessage, theme }) => {


    return (
        <Box sx={{ width: '566px', height: '550px', bgcolor: 'dialogs.background', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '16px' }}>
                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.primary' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 600 }}>{btnTitle}</Typography>
                <Box sx={{ cursor: 'pointer' }} onClick={handleClose} ><CloseIcon sx={{ color: 'icons' }} /></Box>
            </Box>
            <Divider sx={{ bgcolor: 'dialogs.divider', opacity: .2 }} />
            <Box sx={{
                width: '500px',
                '.MuiTextField-root': {
                    p: '0 33px'
                },
                'textarea': {
                    m: '0 31px',
                    padding: '0px',
                    p: '12px',
                    color: '#D0D2D6',
                    fontSize: '14px',
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    border: '1px solid #414655',
                    borderRadius: '2px',
                    '&:focus': {
                        outline: 'none !important',
                        border: '1px solid #7367F0',
                    }
                }
            }}>
                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 33px 8px' }}>Template Name</Typography>
                <TextField fullWidth variant="outlined" color="secondary"
                    value={values.templateName}
                    onChange={handleChange('templateName')}
                    placeholder='Enter Template Name'
                    sx={{
                        '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                        '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                    }} />
                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 33px 8px' }}>Message Subjects</Typography>
                <TextField fullWidth variant="outlined" color="secondary"
                    value={values.themeMessage}
                    onChange={handleChange('themeMessage')}
                    placeholder='Enter Message Subjects'
                    sx={{
                        '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                        '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                    }} />
                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 33px 8px' }}>Text Message</Typography>
                <TextField fullWidth variant="outlined" color="secondary"
                value={values.textMessage}
                onChange={handleChange('textMessage')}
                placeholder='Enter Text Message'
                multiline
                sx={{
                    '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                    '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border', m: 0 }
                }} />
            </Box>
            <Box sx={{ m: '25px auto', display: 'flex', justifyContent: 'center' }}>
                <Button sx={{
                    fontSize: '14px',
                    color: '#fff',
                    padding: '8px 16px',
                    textTransform: 'none',
                    borderRadius: '4px',
                    bgcolor: 'button.primary.background',
                    '&:hover': {
                        bgcolor: 'button.primary.background'
                    },
                }}
                    onClick={btnTitle === 'Add Message Template' ? handleCreateMessage : () => handleUpdateMessage(idMessage)}>{btnTitle}</Button>
            </Box>
        </Box >
    )
}

export default DialogAddSampleText;