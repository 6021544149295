import React from 'react'
import { Link } from 'react-router-dom';
import { Grid, Box, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import HubIcon from '@mui/icons-material/Hub';
import { apiHubSpot } from '../../api/apiHubSpot';

function TableList({ items, handleClickOpen, deleteValue, theme, setSuccessSnackBar, setErrorSnackBar }) {

    const headerTable = [
        { width: '3%', name: '#', borderRight: '1px solid #45516F' },
        { width: '24%', name: 'List Name', borderRight: '1px solid #45516F' },
        { width: '7%', name: 'Entries', borderRight: '1px solid #45516F' },
        { width: '28%', name: 'Owner', borderRight: '1px solid #45516F' },
        { width: '26%', name: 'Last Activity', borderRight: '1px solid #45516F' },
        { width: '12%', name: '', borderRight: '' },
    ]

    if (!theme) {
        theme = createTheme({
            mode: 'dark',
        });
    }
    const isUnread = false;

    const handleImportToHubSpot = async (item) => {
      let contacts = [];
      await item?.leadsList?.forEach((lead) => {
        contacts.push({
          properties: {
            company: lead?.companyName,
            email: lead?.email,
            firstname: lead?.name,
            lastname: lead?.surname,
            industry: lead?.industry,
          },
        });
      });
      let data = JSON.stringify({
        contacts: contacts,
      });
      const headers = {
        token_id: localStorage.getItem("tokenID"),
        'Content-Type': 'application/json'
      };
      apiHubSpot
        .addBatchContacts({ data, headers })
        .then(function (response) {
        //   console.log(response);
          if(response?.status === "true"){
            setSuccessSnackBar(response?.message);
          } else {
            setErrorSnackBar(response?.message);
          }
        })
        .catch(function ({e}) {
        //   console.log(e);
          setErrorSnackBar("Error");
        });
    };
    return (
        <Box>
            <Grid container sx={{ bgcolor: 'tableList.grid.primary' }}>

                {headerTable.map((item) => (
                    <Grid item sx={{ width: item.width, p: '20px 12px', borderRight: item.borderRight, borderColor: theme.mode === 'light' ? 'tableList.grid.border' : '' }} key={item.name}>
                        <Typography sx={{ color: 'text.title', fontSize: '16px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0.2px', textAlign: 'center', fontFamily: 'Montserrat' }}>{item.name}</Typography>
                    </Grid>
                ))}

            </Grid>
            {items && items.map((item, index) => (
                <Grid key={index + 1} container sx={{ bgcolor: theme.mode === 'light' ? 'tableList.grid.secondary' : (index + 1) % 2 ? 'rgba(22, 29, 49, 0.4)' : 'rgba(22, 29, 49, 0.2)', zIndex: 9999 }}>
                    <Grid item sx={{ width: '3%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'tableList.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', textAlign: 'center', fontFamily: 'Montserrat' }}>{index + 1}</Typography>
                    </Grid>
                    <Grid item sx={{ width: '24%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'tableList.grid.border' }}>
                        <Link to={item.dashboard ? `/dashboardLeadsList/${item._id}` : `/leadsList/${item._id}`}>
                            <Typography sx={{ color: 'text.link', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat', textDecoration: 'underline', wordBreak: 'break-all' }}>
                                {item.listName}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item sx={{ width: '7%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'tableList.grid.border' }}>
                        <Typography sx={{ textAlign: 'center', color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat' }}>{item.leadsList?.length}</Typography>
                    </Grid>
                    <Grid item sx={{ width: '28%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'tableList.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat' }}>{item.owner.email ? item.owner.email : item.owner}</Typography>
                    </Grid>
                    <Grid item sx={{ width: '26%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `1px solid`, borderColor: 'tableList.grid.border' }}>
                        <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontSize: '14px', fontWeight: 400, lineHeight: '20px', fontFamily: 'Montserrat' }}>{item.lastActivity}</Typography>
                    </Grid>
                    <Grid item sx={{ width: '3%', p: '16px 12px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: `none`, borderColor: 'table.grid.border', borderLeft: 'none' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                            <Link to={`/conversation/${item.email}/${item.listName}/${item._id}`} >
                                <ChatBubbleOutlineIcon sx={{ color: 'icons', fontSize: '26px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                                {isUnread && <CircleIcon sx={{
                                    position: 'absolute',
                                    fill: '#DB6AB5',
                                    right: '-2px',
                                    top: '0',
                                    borderRadius: '50%',
                                    fontSize: '10px'
                                }} />}
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item sx={{ width: '3%', p: '12px 8px 6px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: 'none', borderLeft: 'none', borderColor: 'tableList.grid.border' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <HubIcon onClick={() => handleImportToHubSpot(item)} sx={{ color: 'icons', fontSize: '30px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                        </Box>
                    </Grid>
                    <Grid item sx={{ width: '3%', p: '12px 8px 6px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: 'none', borderLeft: 'none', borderColor: 'tableList.grid.border' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            {!item.dashboard && <EditIcon onClick={() => handleClickOpen('update', item._id, item)} sx={{ color: 'icons', fontSize: '30px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />}
                        </Box>
                    </Grid>
                    <Grid item sx={{ width: '3%', p: '12px 8px 6px', border: theme.mode === 'light' ? `1px solid` : 'none', borderRight: 'none', borderLeft: 'none', borderColor: 'tableList.grid.border' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <DeleteOutlineIcon onClick={() => deleteValue(item._id, item)} sx={{ color: 'icons', fontSize: '30px', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer' }} />
                        </Box>
                    </Grid>
                </Grid>
            ))}
        </Box>
    );
}

export default TableList;