import React from 'react'
import { Grid, Box, Typography, Snackbar, Button, Menu, MenuItem, Dialog, getTableCellUtilityClass } from '@mui/material';
import Layout from '../../layout';
import PaginationCrm from '../../pagination';
import { apiUser } from '../../../api/apiUser';
import { useAuth0 } from '@auth0/auth0-react';
import DialogEditUser from '../../dialogEditUser/index';
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    mode: 'dark',
});

const lightTheme = createTheme({
    mode: 'light',
});

function AdminsManagment({ setThemeChanged, themeChanged }) {
    const pageInfo = {
        activeMenuTitle: 'User Management',
        header: 'Admins Managment',
    };

    const [theme, setTheme] = React.useState(darkTheme);
    const [snackBarStatus, setSnackBarStatus] = React.useState(false);
    const [snackValue, setSnackValue] = React.useState('');
    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    React.useEffect(() => {
        let cleanupFunction = false;
        if (themeChanged === false) {
            if (!cleanupFunction) setTheme(darkTheme)
        }
        else {
            if (!cleanupFunction) setTheme(lightTheme)
        }
        return () => cleanupFunction = true;
    }, [themeChanged])

    const [vertMenu, setVertMenu] = React.useState(null);
    const [editUser, setEditUser] = React.useState({
        _id: "",
        username: "",
        name: "",
        email: "",
        roles: []
    });
    const openVertMenu = Boolean(vertMenu);
    const [open, setOpen] = React.useState(false);
    const [userRole, setUserRole] = React.useState('')

    const handleEditUser = () => {
        let body = {
            role: [userRole],
            _id: editUser._id
        }
        apiUser.editUserRole({ body })
            .then(res => {
                getUsers(page);
                setOpenSnackBar(true)
                setSnackBarStatus(true);
                setSnackValue(res.message);
            })
            .catch(err => { console.log(err) })
        setOpen(false);
    };

    const handleUserRole = (event) => {
        setUserRole(event.target.value)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickVertMenu = (event, item) => {
        setVertMenu(event.currentTarget);
        setEditUser(item);
    };

    const handleCloseVertMenu = () => {
        setVertMenu(null);
    };

    const handleEdit = () => {
        if (rolesData === "ADMIN") {
            setUserRole(editUser.roles[0])
            setOpen(true);
        }
    };

    const items = [
        {
            name: 'name',
            surname: 'surname',
            email: 'Linkedin',

        },
        {
            name: 'name',
            surname: 'surname',
            email: 'Linkedin',
        },
        {
            name: 'name',
            surname: 'surname',
            email: 'Linkedin',
        },
        {
            name: 'name',
            surname: 'surname',
            email: 'Linkedin',
        },
        {
            name: 'name',
            surname: 'surname',
            email: 'Linkedin',
        },
    ]
    const { user } = useAuth0();
    const [rolesData, setRolesData] = React.useState([])
    const [page, setPage] = React.useState(1);
    const [users, setUsers] = React.useState();
    const [paginationSettings, setPaginationSettings] = React.useState({
        totalPages: 1
    });

    const getUsers = (page) => {

        apiUser.getUsers({ page })
            .then(res => {
                setUsers(res.docs);
                setPaginationSettings({
                    totalPages: res.totalPages
                })
            })
            .catch(err => { console.log(err) })
    }

    React.useEffect(() => {
        getUsers(page);
    }, [])

    React.useEffect(() => {
        // if (user) {
        const headers = {
            // user_id: user.sub,
            token_id: localStorage.getItem('tokenID')
        }
        apiUser.getUser({ headers })
            .then(function (response) {
                setRolesData(response.roles[0]);
            })
            .catch(function (error) {
                console.log(error)
            });
        // };
    }, []);

    const prevPage = () => {
        if (page !== 1) {
            getUsers(page - 1)
            setPage(page - 1)
        };
    }

    const nextPage = () => {
        getUsers(page + 1)
        setPage(page + 1);
    }

    const handleChangePagination = (e, value) => {
        getUsers(value)
        setPage(value)
    }

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false)
    }

    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', pb: '2rem', }}>
                    <Button sx={{
                        fontSize: '14px',
                        color: 'button.secondary.buttonText',
                        border: `1px solid`,
                        borderColor: 'button.secondary.border',
                        padding: '8px 16px',
                        textTransform: 'none',
                        borderRadius: '4px',
                        mr: '20px'
                    }}>Refresh</Button>
                    <Button sx={{
                        fontSize: '14px',
                        color: '#fff',
                        padding: '8px 16px',
                        textTransform: 'none',
                        borderRadius: '4px',
                        bgcolor: 'button.primary.background',
                        '&:hover': {
                            bgcolor: 'button.primary.background',
                        }
                    }}>Add new admin</Button>
                </Box>
                <Box sx={{ bgcolor: 'admins.block', borderRadius: '4px' }}>
                    <Grid container sx={{ bgcolor: 'admins.grid.primary' }}>
                        <Grid sx={{ width: '32%', borderRight: theme.mode === 'light' ? `1px solid` : '1px solid #45516F', borderColor: 'admins.grid.border' }}>
                            <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: 'text.title', fontFamily: 'Montserrat', fontWeight: 600, p: '16px 16px', letterSpacing: '0.2px' }}>
                                Name
                            </Typography>
                        </Grid>
                        <Grid sx={{ width: '32%', borderRight: theme.mode === 'light' ? `1px solid` : '1px solid #45516F', borderColor: 'admins.grid.border' }}>
                            <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: 'text.title', fontFamily: 'Montserrat', fontWeight: 600, p: '16px 16px', letterSpacing: '0.2px' }}>
                                Surname
                            </Typography>
                        </Grid>
                        <Grid sx={{ width: '32%', borderRight: theme.mode === 'light' ? `1px solid` : '1px solid #45516F', borderColor: 'admins.grid.border' }}>
                            <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: 'text.title', fontFamily: 'Montserrat', fontWeight: 600, p: '16px 16px', letterSpacing: '0.2px' }}>
                                Email
                            </Typography>
                        </Grid>
                    </Grid>
                    {users && users.map((item, index) => (
                        <Grid key={index + 1} container sx={{ bgcolor: theme.mode === 'light' ? 'admins.grid.secondary' : (index + 1) % 2 ? 'rgba(22, 29, 49, 0.4)' : 'rgba(22, 29, 49, 0.2)' }}>
                            <Grid sx={{ width: '32%', borderRight: theme.mode === 'light' ? `1px solid` : '1px solid #45516F', borderColor: 'admins.grid.border' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, p: '16px 16px', letterSpacing: '0.2px' }}>
                                    {item.name ? item.name.indexOf(' ') === -1 ? item.name : item.name.substring(0, item.name.indexOf(' ')) : item.username}
                                </Typography>
                            </Grid>
                            <Grid sx={{ width: '32%', borderRight: theme.mode === 'light' ? `1px solid` : '1px solid #45516F', borderColor: 'admins.grid.border' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, p: '16px 16px', letterSpacing: '0.2px' }}>
                                    {item.name ? item.name.indexOf(' ') === -1 ? '' : item.name.substring(item.name.indexOf(' '), item.name.length) : ''}
                                </Typography>
                            </Grid>
                            <Grid sx={{ width: '32%', borderRight: theme.mode === 'light' ? `1px solid` : '1px solid #45516F', borderColor: 'admins.grid.border' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: '20px', color: theme.mode === 'light' ? 'text.link' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, p: '16px 16px', letterSpacing: '0.2px' }}>
                                    {item.email}
                                </Typography>
                            </Grid>
                            <Grid sx={{ width: '4%' }}>
                                <Typography
                                    value={1}
                                    onClick={(e) => handleClickVertMenu(e, item)}
                                    sx={{ fontSize: '26px', lineHeight: '24px', color: 'icons', fontFamily: 'Montserrat', fontWeight: 600, p: '16px 16px', letterSpacing: '0.2px', textAlign: 'center', mt: '-5px', cursor: "pointer" }}
                                    aria-controls={openVertMenu ? 'more-vert-icon-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openVertMenu ? 'true' : undefined}
                                >
                                    ...
                                </Typography>
                                <Menu
                                    anchorEl={vertMenu}
                                    id="more-vert-icon-menu"
                                    open={openVertMenu}
                                    onClose={handleCloseVertMenu}
                                    onClick={handleCloseVertMenu}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            border: theme.mode !== 'light' ? `solid` : 'none',
                                            borderWidth: '1px',
                                            borderColor: '#45516F',
                                            bgcolor: 'menu.background',
                                            color: 'menu.text',
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                borderTop: 'solid',
                                                borderLeft: 'solid',
                                                borderWidth: '1px',
                                                borderColor: '#45516F',
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'menu.background',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem sx={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)' }} onClick={handleEdit}>
                                        Edit
                                    </MenuItem>
                                    {/* <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)', mb: '-4px' }} onClick={"#"}>
                                        Export to CSV
                                    </MenuItem> */}
                                </Menu>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
                <PaginationCrm
                    page={page}
                    count={paginationSettings.totalPages}
                    prevPage={prevPage}
                    handleChange={handleChangePagination}
                    nextPage={nextPage} />
            </Layout >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ '.MuiDialog-root': { background: 'rgba(13, 18, 31, 0.8)' }, '.MuiPaper-root': { background: 'none' }, background: 'rgba(13, 18, 31, 0.6)' }}
            >
                <DialogEditUser
                    user={editUser}
                    btnTitle={"Edit User"}
                    handleClose={handleClose}
                    userRole={userRole}
                    handleUserRole={handleUserRole}
                    handleEditUser={handleEditUser}
                    theme={theme}
                />
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnackBar}
                autoHideDuration={5000}
                onClose={handleCloseSnackBar}
                message={snackValue}
                sx={{ '.MuiPaper-root': { background: snackBarStatus ? '#7B61FF' : 'red', minWidth: '100px', fontFamily: 'Montserrat' }, right: '32px !important' }} />
        </Box >
    );
}

export default AdminsManagment;