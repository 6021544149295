import React from 'react'
import { Box, Dialog, Snackbar, Button, InputAdornment, OutlinedInput, Checkbox, FormControlLabel } from '@mui/material';
import Layout from '../../layout';
import Table from '../../table';
import PaginationCrm from '../../pagination';
import DialogAddFields from '../../dialogAddFields';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import checkCircle from '../../../assets/images/checkCircle.svg'
import { apiPeople } from '../../../api/apiPeople';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    mode: 'dark',
});

const lightTheme = createTheme({
    mode: 'light',
});

function AllPeople({ setThemeChanged, themeChanged }) {
    const pageInfo = {
        activeMenuTitle: 'All Contacts',
        header: 'All Contacts',
    };

    const [theme, setTheme] = React.useState(darkTheme);
    const [data, setData] = React.useState()

    const [values, setValues] = React.useState({
        companyName: '',
        // limitedPartners: '',
        name: '',
        surname: '',
        linkedin: '',
        email: '',
        leadStatus: '',
        notes: '',
        industry: '',
        subIndustry: '',
        slug: '',
    });
    const [errors, setErrors] = React.useState({
        email: '',
        companyName: '',
        leadStatus: '',
    });
    const [page, setPage] = React.useState(1);
    const [valueLeadStatus, setValueLeadStatus] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [snackValue, setSnackValue] = React.useState('');
    const [idPeople, setIdPeople] = React.useState('');
    const [paginationSettings, setPaginationSettings] = React.useState('');
    const [propsDialog, setPropsDialog] = React.useState('');
    const [snackBarStatus, setSnackBarStatus] = React.useState(false);
    const [valueSearch, setValueSearch] = React.useState('');
    const [relevantCompany, setRelevantCompany] = React.useState(false);

    const handleValueLeadStatus = (value) => {
        setValueLeadStatus(value)
    }

    const [vertMenu, setVertMenu] = React.useState(null);
    const openVertMenu = Boolean(vertMenu);

    const getPeople = (page) => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            token_id: localStorage.getItem('tokenID')
        }
        apiPeople.getAllPeople({ page, headers, searchData: valueSearch, relevantCompany })
            .then(function (response) {
                setPaginationSettings({
                    totalPages: response.totalPages,
                    hasNextPage: response.hasNextPage,
                    hasPrevPage: response.hasPrevPage,
                    totalDocs: response.totalDocs
                })
                setData(response.docs);
                if (response.err) {
                    setOpenSnackBar(true);
                    setSnackBarStatus(false)
                    setSnackValue(response.message)
                } else {
                    response.docs.forEach(element => {
                        element.favorites = 0
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    React.useEffect(() => {
        let cleanupFunction = false;
        if (themeChanged === false) {
            if (!cleanupFunction) setTheme(darkTheme)
        }
        else {
            if (!cleanupFunction) setTheme(lightTheme)
        }
        return () => cleanupFunction = true;
    }, [themeChanged])

    React.useEffect(() => {
        getPeople(page);
    }, [valueSearch, relevantCompany]);

    const handleFavorites = (id, data) => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            token_id: localStorage.getItem('tokenID')
        }
        apiPeople.peopleInLead({ headers, data })
            .then(function (response) {
                getPeople(page);
                setOpenSnackBar(true);
                if (response.err) {
                    setSnackBarStatus(false)
                    setSnackValue(response.message)
                } else {
                    setSnackBarStatus(true)
                    setSnackValue(response.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleInputChange = (e) => {
        setValueSearch(e.target.value);
    };

    const handleChangeCheckbox = (e) => {
        setRelevantCompany(!relevantCompany)
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    }
    const prevPage = () => {
        getPeople(page - 1)
        setPage(page - 1);
    }

    const nextPage = () => {
        getPeople(page + 1)
        setPage(page + 1);
    }

    const handleChangePagination = (e, value) => {
        getPeople(value)
        setPage(value)
    }

    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false)
    }
    const handleClickOpen = (target, id, item) => {
        if (id) setIdPeople(id)
        if (target === 'add') {
            setPropsDialog('Add Contacts')
        } else {
            setValueLeadStatus(item.leadStatus)
            setValues(item)
            setPropsDialog('Update Contacts')
        }
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // const handleClickVertMenu = (event) => {
    //     setVertMenu(event.currentTarget);
    // };
    // const handleCloseVertMenu = () => {
    //     setVertMenu(null);
    // };
    const addValue = () => {
        let isError = false;
        if(values.email === '' || !values.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            if(values.email === ''){
                setErrors(errors => ({ ...errors, email: 'Email is required' }));
            } else {
                if(!values.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                    setErrors(errors => ({ ...errors, email: 'Email not valid' }));
                }
            }
            isError = true;
        } else {
            setErrors(errors => ({ ...errors, email: '' }));
        }
        if(!valueLeadStatus){
            setErrors(errors => ({ ...errors, leadStatus: 'Lead Status is required' }));
            isError = true;
        } else {
            setErrors(errors => ({ ...errors, leadStatus: '' }));
        }
        if(values.companyName === ''){
            setErrors(errors => ({ ...errors, companyName: 'Company Name is required' }));
            isError = true;
        } else {
            setErrors(errors => ({ ...errors, companyName: '' }));
        }
        if (!isError) {
            const headers = {
                'user_id': localStorage.getItem('userId'),
                token_id: localStorage.getItem('tokenID'),
                'Content-Type': 'application/json'
            }
            const data = JSON.stringify({
                "companyName": values.companyName,
                "linkedin": values.linkedin,
                // "limitedPartners": values.limitedPartners,
                "name": values.name,
                "surname": values.surname,
                "email": values.email,
                "leadStatus": valueLeadStatus,
                "notes": values.notes,
                "industry": values.industry,
                "subIndustry": values.subIndustry,
                "slug": values.slug,
                _id: idPeople,
            });
            if (propsDialog === 'Add Contacts') {
                apiPeople.addPeople({ data, headers })
                    .then(function (response) {
                        getPeople(page)
                        handleClose()
                        setOpenSnackBar(true)
                        if (response.err) {
                            setSnackBarStatus(false);
                            setSnackValue(response.message)
                        } else {
                            setSnackBarStatus(true);
                            setSnackValue(response.message)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            } else {
                apiPeople.updatePeople({ data, headers })
                    .then(function (response) {
                        getPeople(page)
                        handleClose()
                        setOpenSnackBar(true)
                        if (response.err) {
                            setSnackBarStatus(false);
                            setSnackValue(response.message)
                        } else {
                            setSnackBarStatus(true);
                            setSnackValue(response.message)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
        }
    }

    const deleteValue = (id) => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            token_id: localStorage.getItem('tokenID')
        }
        apiPeople.deletePeople({ headers, _id: id })
            .then(function (response) {
                getPeople(page)
                handleClose()
                setOpenSnackBar(true)
                if (response.err) {
                    setSnackValue(response.message.msg)
                } else setSnackValue(response.message)

            })
            .catch(function (error) {
                console.log(error);
            })
    }
    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: '2rem' }}>
                    <Box sx={{ width: '65%', display: 'flex' }}>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            onChange={handleInputChange}
                            fullWidth
                            value={valueSearch}
                            placeholder='Type in company or person'
                            endAdornment={
                                <InputAdornment position="end">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <SearchIcon sx={{
                                            color: 'allPeople.search.icon',
                                            width: 30,
                                            height: 30,
                                        }} />
                                    </Box>
                                </InputAdornment>
                            }
                            label='Type in company or person'
                            sx={{
                                'input': {
                                    color: 'input.color',
                                    p: '0',
                                    fontFamily: 'Montserrat'
                                },
                                '&.MuiOutlinedInput-root': {
                                    p: '8px 0 8px 1rem',
                                    height: '45px',
                                    border: 'solid',
                                    borderColor: 'input.borderColor',
                                    borderWidth: '1px',
                                },
                                'fieldset': {
                                    border: 'none'
                                }
                            }}
                        />

                        <FormControlLabel
                            onChange={(e) => {
                                handleChangeCheckbox(e)
                            }}
                            sx={{
                                minWidth: '300px',
                                justifyContent: 'flex-end',
                                ml: '0 !important',
                                '.MuiFormControlLabel-label': {
                                    color: 'allPeople.checkbox.label',
                                    fontSize: '14px',
                                    fontFamily: "'Montserrat', Helvetica, Arial, serif !important;",
                                },
                                '.MuiCheckbox-root': {
                                    p: '0 8px 0 8px',
                                    color: 'allPeople.checkbox.label'
                                },
                                '.MuiCheckbox-root:hover': {
                                    backgroundColor: 'rgba(25, 118, 210, 0)'
                                },
                                svg: {
                                    fontSize: '2rem',
                                    color: 'allPeople.checkbox.color'
                                },
                                '.Mui-checked': {
                                    color: 'button.primary.background'
                                }
                            }}
                            value="top"
                            control={<Checkbox value='Show only my relevant companies' checked={relevantCompany} />}
                            label='Show only my relevant companies'
                            labelPlacement="end"
                        />
                    </Box>
                    <Button
                        sx={{
                            fontSize: '14px',
                            color: '#fff',
                            padding: '8px 16px',
                            textTransform: 'none',
                            borderRadius: '4px',
                            bgcolor: 'button.primary.background',
                            height: '40px',
                            '&:hover': {
                                bgcolor: 'button.primary.background'
                            }
                        }}
                        onClick={() => handleClickOpen('add')}>
                        <AddIcon sx={{ pr: '2px' }} />
                        Add Contacts
                    </Button>
                    {/* <Box sx={{ pl: '1rem', width: "30px", height: "32px"}}>
                        <img src={checkCircle} width='26' alt='svg' align='middle' />
                    </Box> */}
                    {/* <Box sx={{ pl: '1rem' }}>
                        <MoreVertIcon
                            onClick={handleClickVertMenu}
                            sx={{ color: '#FFFFFF', fontSize: 30, cursor: 'pointer' }}
                            aria-controls={openVertMenu ? 'more-vert-icon-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openVertMenu ? 'true' : undefined} />
                    </Box>
                    <Menu
                        anchorEl={vertMenu}
                        id="more-vert-icon-menu"
                        open={openVertMenu}
                        onClose={handleCloseVertMenu}
                        onClick={handleCloseVertMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                border: 'solid',
                                borderWidth: '1px',
                                borderColor: '#45516F',
                                color: '#FFFF',
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    borderTop: 'solid',
                                    borderLeft: 'solid',
                                    borderWidth: '1px',
                                    borderColor: '#45516F',
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem sx={{ mt: '-4px' }}>
                            Save to list
                        </MenuItem>
                        <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)' }} >
                            Appoint lead owner
                        </MenuItem>
                        <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)' }} >
                            Schedule an event
                        </MenuItem>
                        <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)' }} >
                            Add to exclusion list
                        </MenuItem>
                        <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)' }} >
                            Add note
                        </MenuItem>
                        <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)', mb: '-4px' }} >
                            Log Out
                        </MenuItem>
                    </Menu> */}
                </Box>
                <Box sx={{ bgcolor: 'allPeople.block' }}>
                    <Table items={data} deleteValue={deleteValue} stars={true} handleClickOpen={handleClickOpen} handleFavorites={handleFavorites} page={page} theme={theme} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    {paginationSettings && <PaginationCrm
                        page={page}
                        count={Math.ceil(paginationSettings.totalDocs / 10)}
                        prevPage={prevPage}
                        handleChange={handleChangePagination}
                        nextPage={nextPage}
                        diabledPrevPage={paginationSettings.hasPrevPage}
                        diabledNextPage={paginationSettings.hasNextPage} />}
                </Box>
            </Layout>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ '.MuiDialog-root': { background: 'rgba(13, 18, 31, 0.8)' }, '.MuiPaper-root': { background: 'none' }, background: 'rgba(13, 18, 31, 0.6)' }}
            >
                <DialogAddFields
                    btnTitle={propsDialog}
                    handleClose={handleClose}
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    valueLeadStatus={valueLeadStatus}
                    handleValueLeadStatus={handleValueLeadStatus}
                    addValue={addValue}
                    theme={theme}
                />
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnackBar}
                autoHideDuration={5000}
                onClose={handleCloseSnackBar}
                message={snackValue}
                sx={{ '.MuiPaper-root': { background: snackBarStatus ? '#7B61FF' : 'red', minWidth: '100px', fontFamily: 'Montserrat' }, right: '32px !important' }} />
        </Box>
    );
}

export default AllPeople;
