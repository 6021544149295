import axios from 'axios';
import { updateToken } from '../utils/updateToken';
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.interceptors.request.use((config) => {
    config.headers.userEmail = localStorage.getItem('userEmail')
    config.headers.token_id = localStorage.getItem('tokenID');
    return config;
})


instance.interceptors.response.use((config) => {
    return config;
}, async (error) => updateToken(error, instance))

export const apiHubSpot = {
    addBatchContacts: async ({ data, headers }) => {
        return (
            await instance.post('/hubspot/createBatchContacts', data, { headers })
        ).data;
    },
    addContact: async ({ data, headers }) => {
        return (
            await instance.post('/hubspot/createContact', data, { headers })
        ).data;
    },
}