import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Box, Dialog, Button, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Layout from '../../layout';
import { DashboardListLeadsTable, DashboardListLeadsForm } from '../../dashboardListLeads';
import DialogDeleteField from '../../dialogDeleteField';
import { createTheme } from '@mui/material/styles';
import { apiList } from '../../../api/apiList';
import { apiLeads } from '../../../api/apiLeads';
import { getResponseErrorMessage } from '../../../utils/fn';

const darkTheme = createTheme({
    mode: 'dark',
});

const lightTheme = createTheme({
    mode: 'light',
});

function DashboardListLeads({ setThemeChanged, themeChanged }) {
    const { id } = useParams();

    const isDashboard = true;

    const [pageInfo, setPageInfo] = useState({
        activeMenuTitle: 'My Lists',
        header: 'Dashboard Leads list',
    });
    const [theme, setTheme] = useState(darkTheme);
    const [data, setData] = useState(null);
    const [valueLeadStatus, setValueLeadStatus] = useState('');
    const [open, setOpen] = useState(false);
    const [snackValue, setSnackValue] = useState('');
    const [propsDialog, setPropsDialog] = useState('');
    const [idPeople, setIdPeople] = useState('');
    const [leadDelete, setLeadDelete] = useState(null);
    const [snackBarStatus, setSnackBarStatus] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [values, setValues] = useState({
        company: '',
        linkedin: '',
        name : '',
        role: '',
        email: ''
    });

    const handleValueLeadStatus = (value) => {
        setValueLeadStatus(value);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const setSuccessSnackBar = (message) => {
        setOpenSnackBar(true);
        setSnackBarStatus(true);
        setSnackValue(message);
    };

    const setErrorSnackBar = (message) => {
        setOpenSnackBar(true);
        setSnackBarStatus(false);
        setSnackValue(message);
    };

    const getList = () => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
        };

        apiList.getList({ id, isDashboard, headers })
            .then(function (response) {
                const { listName, leadsList } = response;
                const newPageInfo = pageInfo;

                if (listName) {
                    newPageInfo.header = `Dashboard Leads list of ${listName}`;
                    setPageInfo(newPageInfo);
                }

                if (leadsList && leadsList.length) {
                    setData(leadsList);
                }
            })
            .catch(function (error) {
                setErrorSnackBar(getResponseErrorMessage(error));
            })
    };

    const addValue = () => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
            'Content-Type': 'application/json'
        };

        const data = { ...values, isDashboard };

        if (propsDialog === 'Add Lead') {
            // This is not used, if it is be sure to update the query so that entries are added to DashboardLead
            // apiLeads.addLeads({ data, headers })
            //     .then(function (response) {
            //         getList();
            //         handleClose();
            //         setOpenSnackBar(true);
            //         console.log(response);

            //         if (response.err) {
            //             setSnackBarStatus(false);
            //             setSnackValue(response.message);
            //         } else {
            //             setSnackBarStatus(true);
            //             setSnackValue(response.message);
            //         }
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //         // setSnackValue(error)
            //     })
        } else {
            data._id = idPeople;
            apiLeads.updateLead({ data, headers })
                .then(response => {
                    getList();
                    handleClose();
                    setSuccessSnackBar(response.message);
                })
                .catch(error => {
                    setErrorSnackBar(getResponseErrorMessage(error));
                });
        }
    };

    const openDeleteModal = (id) => {
        const lead = data.find(item => item._id === id);

        setLeadDelete(lead);
    };

    const closeDeleteModal = () => setLeadDelete(null);

    const deleteValue = () => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
        }
        
        apiLeads.deleteLead({ headers, isDashboard, _id: leadDelete._id })
            .then(function (response) {
                getList();
                setSuccessSnackBar('Lead delete successfully!');
            })
            .catch(function (error) {
                setErrorSnackBar(getResponseErrorMessage(error));
            })
            .finally(() => closeDeleteModal());
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false)
    };

    const handleClickOpen = (target, id, item) => {
        if (id) setIdPeople(id)
        if (target === 'add') {
            setPropsDialog('Add Lead')
        } else {
            setValueLeadStatus(item.leadStatus)
            setValues(item)
            setPropsDialog('Update Lead')
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getList();
    }, [id]);

    useEffect(() => {
        if (themeChanged === false) {
            setTheme(darkTheme);
        }
        else {
            setTheme(lightTheme);
        }
    }, [themeChanged])

    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                {/* <Box sx={{
                    display: 'flex', justifyContent: 'right', alignItems: 'center', pb: '2rem',
                    '.react-excel': { display: 'none' }
                }}>
                    <Button
                        sx={{
                            fontSize: '14px',
                            color: '#fff',
                            padding: '8px 16px',
                            textTransform: 'none',
                            borderRadius: '4px',
                            bgcolor: 'button.primary.background',
                            height: '40px',
                            '&:hover': {
                                bgcolor: 'button.primary.background',
                            }
                        }}
                        onClick={() => handleClickOpen('add')}>
                        <AddIcon sx={{ pr: '2px' }} />
                        Add Lead
                    </Button>
                </Box> */}
                <Box sx={{ bgcolor: 'myLeads.block' }}>
                    <DashboardListLeadsTable items={data} handleDelete={openDeleteModal} handleClickOpen={handleClickOpen} page={1} theme={theme} />
                </Box>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ '.MuiDialog-root': { background: 'rgba(13, 18, 31, 0.8)' }, '.MuiPaper-root': { background: 'none' }, background: 'rgba(13, 18, 31, 0.6)' }}
                >
                    <DashboardListLeadsForm
                        btnTitle={propsDialog}
                        handleClose={handleClose}
                        values={values}
                        handleChange={handleChange}
                        addValue={addValue}
                        theme={theme} />
                </Dialog>

                {leadDelete && <Dialog
                    open={!!leadDelete}
                    onClose={closeDeleteModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ '.MuiDialog-root': { background: 'rgba(13, 18, 31, 0.8)' }, '.MuiPaper-root': { background: 'none' }, background: 'rgba(13, 18, 31, 0.6)' }}
                >
                    <DialogDeleteField
                        title={`Delete Lead ${leadDelete.name || ''}`}
                        text={`Are you sure you want to delete lead ${leadDelete.name || ''}?`}
                        onCloseModal={closeDeleteModal}
                        onSubmit={deleteValue}
                    />
                </Dialog>}

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={openSnackBar}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackBar}
                    message={snackValue}
                    sx={{ '.MuiPaper-root': { background: snackBarStatus ? '#7B61FF' : 'red', minWidth: '100px', fontFamily: 'Montserrat' }, right: '32px !important' }} />
            </Layout >
        </Box >
    );
}

export default DashboardListLeads;
