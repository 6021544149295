import React from 'react'
import { Grid, Box, Typography, LinearProgress, OutlinedInput, InputAdornment, IconButton, Button, Link, Divider, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Layout from '../../layout';
import CategoryRadialBar from '../../radialChart';
import trendingup from '../../../assets/images/trendingup.svg'
import { apiLeads } from '../../../api/apiLeads';
import { apiMessage } from '../../../api/apiMessage';
import { apiEvents } from '../../../api/apiEvents';
import { apiPeople } from '../../../api/apiPeople';
import axios from 'axios';
import { createTheme } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';

const darkTheme = createTheme({
    mode: 'dark',
});

const lightTheme = createTheme({
    mode: 'light',
});

const homeItems = {
    money: [
        {
            title: 'Leads',
            price: '34 000 $'
        },
        {
            title: 'Messages',
            price: '34 000 $'
        },
        {
            title: 'Payment',
            price: '34 000 $'
        },
        {
            title: 'Due Balance',
            price: '34 000 $'
        },
    ],
    progress: [
        {
            title: 'Lead Statuses',
            items: [
                {
                    title: 'Draft',
                    value: 3
                },
                {
                    title: 'Pending',
                    value: 5
                },
                {
                    title: 'Not Paid',
                    value: 12
                },
                {
                    title: 'Overdue',
                    value: 6
                },
                {
                    title: 'Partially Paid',
                    value: 8
                },
                {
                    title: 'Paid',
                    value: 55
                },
            ]
        },
        {
            title: 'Messages',
            items: [
                {
                    title: 'Answered',
                    value: 3
                },
                {
                    title: 'In active communicaiton',
                    value: 5
                },
                {
                    title: 'Unanswered',
                    value: 12
                },
                {
                    title: 'Overdue',
                    value: 6
                },
                {
                    title: 'Partially Paid',
                    value: 8
                },
                {
                    title: 'Paid',
                    value: 55
                },
            ]
        },
        // {
        //     title: 'Order Preview',
        //     items: [
        //         {
        //             title: 'Draft',
        //             value: 3
        //         },
        //         {
        //             title: 'Pending',
        //             value: 5
        //         },
        //         {
        //             title: 'Not Paid',
        //             value: 12
        //         },
        //         {
        //             title: 'Overdue',
        //             value: 6
        //         },
        //         {
        //             title: 'Partially Paid',
        //             value: 8
        //         },
        //         {
        //             title: 'Paid',
        //             value: 55
        //         },
        //     ]
        // },
    ],
    recentLeads: [
        {
            nameClient: 'Andrii Tiron',
            phone: '+ (380) 99 45 65 234',
            status: false
        },
        {
            nameClient: 'Victoria Bula',
            phone: '+ (380) 99 87 64 321',
            status: false
        },
        {
            nameClient: 'John Smith',
            phone: '+ (380) 99 45 65 234',
            status: false
        },
        {
            nameClient: 'Jared Leto',
            phone: '+ (380) 99 87 64 321',
            status: false
        },
    ],
    recentProducts: [
        {
            productName: 'name',
            price: '$12',
            status: true
        },
        {
            productName: 'name',
            price: '$120',
            status: true
        },
        {
            productName: 'name',
            price: '$123',
            status: true
        },
        {
            productName: 'name',
            price: '$9543',
            status: true
        },
    ]
}
function Home({ setThemeChanged, themeChanged }) {
    const pageInfo = {
        activeMenuTitle: 'Homepage',
        header: 'Investor Relationship Management',
    };

    const [theme, setTheme] = React.useState(darkTheme);
    const { user } = useAuth0()

    const PopperProps = {
        sx: {
            "& .MuiTooltip-tooltip": {
                bgcolor: 'home.grid.border',
                color: "text.primary",
                fontSize: '12px'
            }
        }
    }

    React.useEffect(() => {
        let cleanupFunction = false;
        if (themeChanged === false) {
            if (!cleanupFunction) setTheme(darkTheme)
        }
        else {
            if (!cleanupFunction) setTheme(lightTheme)
        }
        return () => cleanupFunction = true;
    }, [themeChanged])

    const [leadsData, setLeadsData] = React.useState([]);

    const [money, setMoney] = React.useState([
        {
            title: 'Leads',
            price: '0',
            last: 0
        },
        {
            title: 'Messages',
            price: '0',
            last: 0
        },
        {
            title: 'Meets',
            price: '0',
            last: 0
        },
        {
            title: 'All People',
            price: '0',
            last: 0
        },
    ])

    const [statuses, setStatuses] = React.useState([
        {
            title: 'Lead Statuses',
            items: []
        },
        {
            title: 'Messages',
            items: []
        },
    ])

    React.useEffect(() => {
        let cleanupFunction = false;
        const headers = {
            user_id: localStorage.getItem('userId'),
            token_id: localStorage.getItem('tokenID')
        }
        apiLeads.getLeadsStatuses({ headers })
            .then((res) => {
                statuses[0].items = res
                if (!cleanupFunction) setStatuses([...statuses]);
            })
            .catch((err) => {
                console.log(err);
            });
        apiMessage.getDataMessage({ headers })
            .then((res) => {
                // console.log(res)
                statuses[1].items = res
                if (!cleanupFunction) setStatuses([...statuses]);
            })
            .catch((err) => {
                console.log(err);
            });

        apiLeads.countLeads({ headers })
            .then((res) => {
                money[0].price = res.allLeads;
                money[0].last = res.lastLeads;
                if (!cleanupFunction) setMoney([...money]);
            })
            .catch((err) => {
                console.log(err);
            });

        apiPeople.countPeople({ headers })
            .then((res) => {
                money[3].price = res.allPeople;
                money[3].last = res.lastPeople;
                if (!cleanupFunction) setMoney([...money]);
            })
            .catch((err) => {
                console.log(err);
            });

        apiLeads.getLeadsUser({ headers })
            .then(res => {
                if (!cleanupFunction) setLeadsData(res)
            })
            .catch(err => { console.log(err) })

        apiEvents.getCountsEvent({ headers })
            .then((res) => {
                const countEvents = res.counts;
                money[2].price = countEvents;
                money[2].last = res.last;
                if (!cleanupFunction) setMoney([...money]);
            })
            .catch((err) => {
                console.log(err);
            });

        apiMessage.getCountMessages({ headers })
            .then(res => {
                const countMessages = res.counts;
                money[1].price = countMessages;
                money[1].last = res.last;
                if (!cleanupFunction) setMoney([...money]);
            })
            .catch((err) => {
                console.log(err);
            });
        // функция очистки useEffect
        return () => cleanupFunction = true;
    }, [])

    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                <Grid container spacing={4}>
                    {money && money.map((item, index) => (
                        <Grid key={index + 1} item xs={6} md={3}>
                            <Box sx={{ bgcolor: 'home.block', borderRadius: '8px' }}>
                                <Box sx={{ p: '16px', display: 'flex', justifyContent: 'center' }}>
                                    <Typography sx={{ fontSize: '20px', lineHeight: '28px', color: 'text.primary', fontFamily: 'Montserrat', fontWeight: 500, textAlign: 'center' }}>
                                        {item.title}
                                    </Typography>
                                    <Tooltip title={item.title === 'Leads' ? 'My leads count' : item.title === 'Messages' ? 'My messages count' : item.title === 'Meets' ? 'My Meets' : 'Limited Partners available'}
                                        PopperProps={PopperProps}
                                        sx={{
                                            pl: '0.5rem',
                                            color: 'icons',
                                            mt: 'auto',
                                            mb: 'auto',
                                        }} >
                                        <HelpOutlineIcon />
                                    </Tooltip>
                                </Box>
                                <Divider sx={{ bgcolor: 'home.divider', opacity: 0.2 }} />
                                <Box sx={{ p: '24px' }}>
                                    <Typography sx={{
                                        fontSize: '36px',
                                        lineHeight: '40px',
                                        color: theme.mode === 'light' ? 'text.primary' : item.title === 'Leads' ? 'rgba(123, 97, 255, 1)' : item.title === 'Messages' ? '#DB6AB5' : item.title === 'Meets' ? '#FF9F43' : '#28C7AA',
                                        fontFamily: 'Montserrat',
                                        fontWeight: 300,
                                        textAlign: 'center'
                                    }}>
                                        {item.price}
                                    </Typography>
                                    <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: theme.mode === 'light' ? 'text.secondary' : 'text.primary', fontFamily: 'Montserrat', fontWeight: 400, textAlign: 'center', opacity: 0.6, mt: '4px' }}>
                                        {item.title === 'Due Balance' ? 'Not Paid' : `+ ${item.last} in the last 30 days`}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', mt: '32px' }}>
                    <Grid item container p={4} xs={12} md={5.85} sx={{ width: 'calc(50% - 80px)', bgcolor: 'home.block', borderRadius: '8px' }}>
                        <Grid item container spacing={6} sx={{ mt: '-50px' }}>
                            {statuses && statuses.map((item, index) => (
                                <Grid key={index + 1} item xs={12} md={6} sx={{ display: { xs: index === 1 && 'none', md: 'block' } }}>
                                    <Box sx={{ display: 'flex' }} >
                                        {console.log(index)}
                                        <Typography sx={{ fontSize: '20px', lineHeight: '28px', color: 'text.primary', fontFamily: 'Montserrat', fontWeight: 500 }}>
                                            {item.title}
                                        </Typography>
                                        <Tooltip title="Lorem ipsum"
                                            PopperProps={PopperProps}
                                            sx={{
                                                pl: '0.5rem',
                                                color: 'icons',
                                                mt: 'auto',
                                                mb: 'auto',
                                            }} >
                                            <HelpOutlineIcon />
                                        </Tooltip>
                                    </Box>
                                    {item.items && item.items.map((i, index) => (
                                        <Box key={index + 1} mt={4}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography sx={{ fontSize: '14px', lineHeight: '16px', color: 'home.graph.primary', fontFamily: 'Montserrat', fontWeight: 500, opacity: 0.6 }}>
                                                    {i.title}
                                                </Typography>
                                                <Typography sx={{ fontSize: '14px', lineHeight: '16px', color: 'home.graph.secondary', fontFamily: 'Montserrat', fontWeight: 500, opacity: 0.6 }}>
                                                    {i.value + '%'}
                                                </Typography>
                                            </Box>
                                            <LinearProgress sx={{
                                                mt: '7px',
                                                background: 'rgba(22, 29, 49, 0.2)',
                                                '.MuiLinearProgress-bar': {
                                                    // background: index === 0 ? '#7B61FF' : index === 1 ? '#FF9F43' : index === 2 ? '#DB6AB5' :
                                                    //     index === 3 ? '#28C7AA' : index === 4 ? '#2A99FF' : '#00CFE8',
                                                    bgcolor: item.title === "Lead Statuses" ? (index === 0 ? 'home.graphLineOne.1' : index === 1 ? 'home.graphLineOne.2'
                                                        : index === 2 ? 'home.graphLineOne.3' : '#00CFE8')
                                                        : index === 0 ? 'home.graphLineTwo.1' : index === 1 ? 'home.graphLineTwo.2' : index === 2 ? 'home.graphLineTwo.3' :
                                                            index === 3 ? 'home.graphLineTwo.4' : '#00CFE8',
                                                    borderRadius: '100px'
                                                },
                                                height: '8px',
                                                borderRadius: '100px'
                                            }} variant="determinate" value={i.value} />
                                        </Box>
                                    ))}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} md={5.85} sx={{ width: 'calc(50% - 20px)', pt: { xs: "1.5rem", md: "0" }, display: { md: 'none' } }}>
                        <Grid item container p={4} xs={12} md={5.85} sx={{ width: 'calc(50% - 80px)', bgcolor: 'home.block', borderRadius: '8px' }}>
                            <Grid item container spacing={6} sx={{ mt: '-50px' }}>
                                {statuses && statuses.map((item, index) => (
                                    <Grid key={index + 1} item xs={12} md={6} sx={{ display: { xs: index === 0 && 'none' } }}>
                                        <Box sx={{ display: 'flex' }} >
                                            {console.log(index)}
                                            <Typography sx={{ fontSize: '20px', lineHeight: '28px', color: 'text.primary', fontFamily: 'Montserrat', fontWeight: 500 }}>
                                                {item.title}
                                            </Typography>
                                            <Tooltip title="Lorem ipsum"
                                                PopperProps={PopperProps}
                                                sx={{
                                                    pl: '0.5rem',
                                                    color: 'icons',
                                                    mt: 'auto',
                                                    mb: 'auto',
                                                }} >
                                                <HelpOutlineIcon />
                                            </Tooltip>
                                        </Box>
                                        {item.items && item.items.map((i, index) => (
                                            <Box key={index + 1} mt={4}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography sx={{ fontSize: '14px', lineHeight: '16px', color: 'home.graph.primary', fontFamily: 'Montserrat', fontWeight: 500, opacity: 0.6 }}>
                                                        {i.title}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: '14px', lineHeight: '16px', color: 'home.graph.secondary', fontFamily: 'Montserrat', fontWeight: 500, opacity: 0.6 }}>
                                                        {i.value + '%'}
                                                    </Typography>
                                                </Box>
                                                <LinearProgress sx={{
                                                    mt: '7px',
                                                    background: 'rgba(22, 29, 49, 0.2)',
                                                    '.MuiLinearProgress-bar': {
                                                        // background: index === 0 ? '#7B61FF' : index === 1 ? '#FF9F43' : index === 2 ? '#DB6AB5' :
                                                        //     index === 3 ? '#28C7AA' : index === 4 ? '#2A99FF' : '#00CFE8',
                                                        bgcolor: item.title === "Lead Statuses" ? (index === 0 ? 'home.graphLineOne.1' : index === 1 ? 'home.graphLineOne.2'
                                                            : index === 2 ? 'home.graphLineOne.3' : '#00CFE8')
                                                            : index === 0 ? 'home.graphLineTwo.1' : index === 1 ? 'home.graphLineTwo.2' : index === 2 ? 'home.graphLineTwo.3' :
                                                                index === 3 ? 'home.graphLineTwo.4' : '#00CFE8',
                                                        borderRadius: '100px'
                                                    },
                                                    height: '8px',
                                                    borderRadius: '100px'
                                                }} variant="determinate" value={i.value} />
                                            </Box>
                                        ))}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={5.85} sx={{ width: 'calc(50% - 20px)', pt: { xs: "1.5rem", md: "0" } }}>
                        <Grid item container xs={12} sx={{ bgcolor: 'home.block', borderRadius: '8px', }}>
                            <Grid item xs={12} sx={{ display: 'flex' }} >
                                <Typography sx={{ fontSize: '20px', lineHeight: '28px', color: 'text.primary', fontFamily: 'Montserrat', fontWeight: 500, p: '24px 29px' }}>
                                    Recent Leads
                                </Typography>
                                <Tooltip title="Lorem ipsum"
                                    PopperProps={PopperProps}
                                    sx={{
                                        color: 'icons',
                                        mt: 'auto',
                                        mb: 'auto',
                                    }} >
                                    <HelpOutlineIcon />
                                </Tooltip>
                            </Grid>
                            <Grid item container xs={12} sx={{ overflowX: "clip", overflowY: "auto" }}>
                                <Grid item container xs={12} sx={{ bgcolor: 'home.grid.primary', height: "fit-content" }}>
                                    <Grid sx={{ width: '25%', borderRight: `1px solid`, borderColor: 'home.grid.border' }}>
                                        <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: 'text.secondary', fontFamily: 'Montserrat', fontWeight: 600, p: '16px 29px', letterSpacing: '0.2px' }}>
                                            Client
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ width: '40%', borderRight: `1px solid`, borderColor: 'home.grid.border' }}>
                                        <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: 'text.secondary', fontFamily: 'Montserrat', fontWeight: 600, p: '16px 16px', letterSpacing: '0.2px' }}>
                                            Email
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ width: '25%', borderRight: `1px solid`, borderColor: 'home.grid.border' }}>
                                        <Typography sx={{ fontSize: '16px', lineHeight: '24px', color: 'text.secondary', fontFamily: 'Montserrat', fontWeight: 600, p: '16px 16px', letterSpacing: '0.2px' }}>
                                            Status
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sx={{ height: "350px", flexDirection: 'column' }}>
                                    {leadsData && leadsData.map((item, index) => (
                                        <Grid key={index + 1} item container xs={12} sx={{ bgcolor: theme.mode === 'light' ? 'home.grid.secondary' : (index + 1) % 2 ? 'rgba(22, 29, 49, 0.2)' : 'rgba(22, 29, 49, 0.4)', maxHeight: '70px', borderTop: theme.mode === 'light' ? '1px solid' : 'none', borderColor: 'home.grid.border' }}>
                                            <Grid sx={{ width: '25%', borderRight: `1px solid`, borderColor: 'home.grid.border', display: "flex" }}>
                                                <Typography sx={{ fontSize: '14px', wordBreak: 'break-all', lineHeight: '20px', color: 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, p: '16px 29px', letterSpacing: '0.2px', mt: "auto", mb: "auto" }}>
                                                    {item.companyName}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ width: '40%', borderRight: `1px solid`, borderColor: 'home.grid.border', display: "flex" }}>
                                                <Typography sx={{ fontSize: '14px', wordBreak: 'break-all', lineHeight: '20px', color: 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, p: '16px 16px', letterSpacing: '0.2px', mt: "auto", mb: "auto" }}>
                                                    {item.email}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ width: '25%', borderRight: `1px solid`, borderColor: 'home.grid.border', p: '12px 16px', display: "flex", justifyContent: 'center' }}>
                                                {item.companyName && <Button sx={{
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                    color: theme.mode === 'light' ? '#92400E' : 'white',
                                                    // item.leadStatusForUser ?
                                                    // item.leadStatusForUser[user.email] ?
                                                    //     item.leadStatusForUser[user.email] === "Message Received" ? '#77B529' : item.leadStatusForUser[user.email] === "Message sent" ? "#EA920F" : "#C4C4C4" :
                                                    //     item.leadStatus === "Message Received" ? '#77B529' : item.leadStatus === "Message sent" ? "#EA920F" : "#C4C4C4" :
                                                    // item.leadStatus === "Message Received" ? '#77B529' : item.leadStatus === "Message sent" ? "#EA920F" : "#C4C4C4",
                                                    fontFamily: 'Montserrat',
                                                    textTransform: 'none',
                                                    fontWeight: 500,
                                                    padding: '4px 12px',
                                                    background: theme.mode === 'light' ? '#FEF3C7' :
                                                        item.leadStatusForUser ?
                                                            item.leadStatusForUser[localStorage.getItem('userEmail')] ?
                                                                item.leadStatusForUser[localStorage.getItem('userEmail')] === "Message Received" ? 'rgba(119, 181, 41, 0.12)' : item.leadStatusForUser[localStorage.getItem('userEmail')] === "Message sent" ? "rgba(234, 146, 15, 0.12)" : "rgba(196, 196, 196, 0.12)" :
                                                                item.leadStatus === "Message Received" ? 'rgba(119, 181, 41, 0.12)' : item.leadStatus === "Message sent" ? "rgba(234, 146, 15, 0.12)" : "rgba(196, 196, 196, 0.12)" :
                                                            item.leadStatus === "Message Received" ? 'rgba(119, 181, 41, 0.12)' : item.leadStatus === "Message sent" ? "rgba(234, 146, 15, 0.12)" : "rgba(196, 196, 196, 0.12)",
                                                    mt: "auto", mb: "auto"
                                                }}>{item.leadStatusForUser ? item.leadStatusForUser[localStorage.getItem('userEmail')] ? item.leadStatusForUser[localStorage.getItem('userEmail')] : item.leadStatus : item.leadStatus}</Button>
                                                    // :
                                                    //     <Button sx={{
                                                    //         fontSize: '12px',
                                                    //         lineHeight: '20px',
                                                    //         color: '#28C7AA',
                                                    //         fontFamily: 'Montserrat',
                                                    //         fontWeight: 500,
                                                    //         padding: '4px 12px',
                                                    //         background: 'rgba(40, 199, 170, 0.12)',
                                                    //         mt: "auto", mb: "auto"
                                                    //     }}>AVAILABLE</Button>
                                                }
                                            </Grid>
                                            <Grid sx={{ width: '10%', display: "flex", justifyContent: "center" }}>
                                                <Typography sx={{ fontSize: '26px', lineHeight: '24px', color: 'icons', fontFamily: 'Montserrat', fontWeight: 600, p: '0px 16px 16px 16px', letterSpacing: '0.2px', textAlign: 'center', mt: "auto", mb: "auto" }}>
                                                    ...
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Layout >
        </Box >
    );
}

export default Home;
