import React from 'react';
import { Box, Button, Divider, Typography, Grid, TextField, FormControlLabel, RadioGroup, Radio, Popover, FormGroup, Checkbox, MenuItem, Select, InputLabel, OutlinedInput, Menu, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from 'axios'
import Table from '../table';
import { useLocation } from 'react-router-dom';
import countriesList from '../../assets/json/countries.json'

const DialogAddFields = ({ btnTitle, handleClose, handleChange, values, errors, valueLeadStatus, handleValueLeadStatus, handleValueCountry, handleValueFunding, addValue, dataLeads, leadsList, handleExport, handleImport, theme, menu = true }) => {

    if (!errors) {
        errors = {
            email: '',
            companyName: '',
            leadStatus: '',
        }
    }

    const fundingStagesList = [
        '',
        'Pre-seed funding',
        'Seed funding',
        'Series A',
        'Series B',
        'Series C',
        'Series D',
        'IPO',
    ]

    const [vertMenu, setVertMenu] = React.useState(null);
    const [formErrors, setFormErrors] = React.useState({
        fundingStage: ''
    });
    const openVertMenu = Boolean(vertMenu);
    const location = useLocation();

    const headerTable = [
        { width: '5%', name: '#' },
        { width: '25%', name: 'Company Name' },
        { width: '10%', name: 'Link' },
        { width: '55%', name: 'Email' }
    ]

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                color: 'input.text',
                bgcolor: 'menu.background',
            },
        },
    };
    const Input = styled('input')({
        display: 'none',
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElCountry, setAnchorElCountry] = React.useState(null);
    const [anchorElFunding, setAnchorElFunding] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickCountry = (event) => {
        setAnchorElCountry(event.currentTarget);
    };

    const handleClickFunding = (event) => {
        setAnchorElFunding(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setAnchorElCountry(null);
        setAnchorElFunding(null);
    };

    const handleClickVertMenu = (event) => {
        setVertMenu(event.currentTarget);
    };

    const handleCloseVertMenu = () => {
        setVertMenu(null);
    };

    const handleAdd = () => {
        setFormErrors({
            fundingStage: ''
        })
        if (handleValueCountry && handleValueFunding) {
            let isError = false
            // if (!values.fundingStage) {
            //     isError = true
            //     setFormErrors({ ...formErrors, fundingStage: 'Required' })
            // }
            if (!isError) {
                addValue()
            }
        }
        else {
            addValue()
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const openCountry = Boolean(anchorElCountry);
    const idCountry = openCountry ? 'simple-popover' : undefined;

    const openFunding = Boolean(anchorElFunding);
    const idFunding = openFunding ? 'simple-popover' : undefined;

    return (
        <Box sx={{ width: '566px', height: 'auto', bgcolor: 'dialogs.background', borderRadius: '8px', overflowX: 'hidden' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '16px' }}>
                <Input
                    hidden
                    accept=".xlsx"
                    id="importLeadsListXLSX"
                    type="file"
                    onChange={handleImport}
                />
                <Input
                    hidden
                    accept=".csv"
                    id="importLeadsListCSV"
                    type="file"
                    onChange={handleImport}
                />
                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.primary' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 600 }}>{btnTitle}</Typography>
                <Box sx={{ display: 'flex' }}>
                    {btnTitle !== 'Create list' && menu && <Box>
                        <MoreVertIcon
                            onClick={handleClickVertMenu}
                            sx={{ color: 'icons', fontSize: '1.5rem', cursor: 'pointer', pr: '1rem' }}
                            aria-controls={openVertMenu ? 'more-vert-icon-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openVertMenu ? 'true' : undefined}
                        />
                        <Menu
                            anchorEl={vertMenu}
                            id="more-vert-icon-menu"
                            open={openVertMenu}
                            onClose={handleCloseVertMenu}
                            onClick={handleCloseVertMenu}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    border: theme.mode !== 'light' ? `solid` : 'none',
                                    borderWidth: '1px',
                                    borderColor: '#45516F',
                                    bgcolor: 'menu.background',
                                    color: 'menu.text',
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        borderTop: theme.mode !== 'light' ? `solid` : 'none',
                                        borderLeft: theme.mode !== 'light' ? `solid` : 'none',
                                        borderWidth: '1px',
                                        borderColor: '#45516F',
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'menu.background',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem sx={{ label: { cursor: 'pointer' } }} //onClick={() => handleImport('XLSX')}
                            >
                                <label htmlFor="importLeadsListXLSX" >
                                    Import from Excel
                                </label>
                            </MenuItem>
                            <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)' }} //onClick={() => handleImport('XLSX')}
                            >
                                <label htmlFor="importLeadsListCSV" >
                                    Import from CSV
                                </label>
                            </MenuItem>
                            <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)' }} onClick={() => handleExport('XLSX')}>
                                Export to Excel
                            </MenuItem>
                            <MenuItem sx={{ borderTop: "solid", borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.25)', mb: '-4px' }} onClick={() => handleExport('CSV')}>
                                Export to CSV
                            </MenuItem>
                        </Menu>
                    </Box>}
                    <Box sx={{ cursor: 'pointer' }} onClick={handleClose} ><CloseIcon sx={{ color: 'icons' }} /></Box>
                </Box>
            </Box>
            <Divider sx={{ bgcolor: 'dialogs.divider', opacity: .2 }} />
            {location.pathname === '/mylist' ?
                <Box sx={{ p: '0 16px' }}>
                    <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>List Name</Typography>
                    <TextField fullWidth variant="outlined" color="secondary"
                        value={values.listName}
                        onChange={handleChange('listName')}
                        placeholder='Enter List Name'
                        sx={{
                            '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                            '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                        }} />
                    <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Lead Lists</Typography>

                    <Box >
                        <Select
                            multiple
                            fullWidth
                            color='secondary'
                            value={valueLeadStatus}
                            onChange={handleValueLeadStatus}
                            input={<OutlinedInput label="Leads" sx={{
                                color: 'input.text',
                                'input': {
                                    color: 'input.text'
                                },
                                'svg': { color: '#b4b7bd' },
                                '&.MuiOutlinedInput-root': {
                                    border: 'solid',
                                    borderColor: 'dialogs.border',
                                    borderWidth: '1px',
                                },
                                'fieldset': {
                                    border: 'none'
                                }
                            }} />}
                            MenuProps={MenuProps}
                        // MenuProps={MenuProps}
                        >
                            {dataLeads && dataLeads.map((item, index) => (
                                <MenuItem
                                    key={item._id}
                                    value={item._id}
                                    sx={{ color: 'input.text' }}
                                >
                                    {`${item.companyName} ${item.name}`}
                                </MenuItem>))}
                        </Select>
                    </Box>
                </Box>
                : <Grid container spacing={3} sx={{ p: '0 16px' }}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Company Name</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.companyName}
                            onChange={handleChange('companyName')}
                            placeholder='Enter Company Name'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} />
                        {errors?.companyName && <Typography sx={{ fontSize: '12px', letterSpacing: '0.2px', lineHeight: '12px', color: 'rgba(255, 0, 0, 1)', fontFamily: 'Montserrat', fontWeight: 600, mt: '8px' }}>{errors?.companyName}</Typography>}
                        <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Linkedin</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.linkedin}
                            onChange={handleChange('linkedin')}
                            placeholder='Enter Linkedin'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} />
                        <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Lead Status</Typography>
                        <Box
                            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', border: '1px solid', borderColor: 'dialogs.border', borderRadius: '4px', p: '8px 12px', justifyContent: 'space-between' }}
                            aria-describedby={id}
                            onClick={handleClick}>
                            <Typography sx={{ fontSize: '14px', color: 'input.text', fontFamily: 'Montserrat' }}>
                                {valueLeadStatus}
                            </Typography>
                            <KeyboardArrowDownIcon sx={{ fontSize: '30px', color: 'text.arrow' }} />
                            <Popover
                                sx={{ zIndex: '2222222', '.MuiPopover-paper': { border: '1px solid #414655', borderRadius: '4px', mt: '3px', width: '251px' } }}
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Box>
                                    <RadioGroup value={valueLeadStatus} onChange={(e) => {
                                        handleValueLeadStatus(e.target.value)
                                        setAnchorEl(null)
                                    }}
                                        aria-label="position" name="position" defaultValue="top" sx={{ display: 'flex', alignItems: 'start' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px 0px', bgcolor: valueLeadStatus == 'No action' ? 'menu.is' : 'menu.background' }}>
                                            <FormControlLabel
                                                sx={{ '.MuiFormControlLabel-label': { color: 'input.text', width: '100%', fontFamily: 'Montserrat', fontSize: '14px' } }}
                                                value="top"
                                                control={<Radio value="No action" sx={{ display: 'none' }} />}
                                                label="No action"
                                                labelPlacement="start"
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px 0px', bgcolor: valueLeadStatus == 'Message Received' ? 'menu.is' : 'menu.background' }}>
                                            <FormControlLabel
                                                sx={{ '.MuiFormControlLabel-label': { color: 'input.text', width: '100%', fontFamily: 'Montserrat', fontSize: '14px' } }}
                                                value="start"
                                                control={<Radio value="Message Received" sx={{ display: 'none' }} />}
                                                label="Message Received"
                                                labelPlacement="start"
                                            />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px 0px', bgcolor: valueLeadStatus == 'Message sent' ? 'menu.is' : 'menu.background' }}>
                                            <FormControlLabel
                                                sx={{ '.MuiFormControlLabel-label': { color: 'input.text', width: '100%', fontFamily: 'Montserrat', fontSize: '14px' } }}
                                                value="bottom"
                                                control={<Radio value="Message sent" sx={{ display: 'none' }} />}
                                                label="Message sent"
                                                labelPlacement="start"
                                            />
                                        </Box>
                                    </RadioGroup>
                                </Box>
                            </Popover>
                        </Box>
                        {errors?.leadStatus && <Typography sx={{ fontSize: '12px', letterSpacing: '0.2px', lineHeight: '12px', color: 'rgba(255, 0, 0, 1)', fontFamily: 'Montserrat', fontWeight: 600, mt: '8px' }}>{errors?.leadStatus}</Typography>}
                        <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Industry</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.industry}
                            onChange={handleChange('industry')}
                            placeholder='Enter Industry'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} />
                        {handleValueCountry &&
                            <Box>
                                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Country</Typography>
                                <Box
                                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', border: '1px solid', borderColor: 'dialogs.border', borderRadius: '4px', p: '8px 12px', justifyContent: 'space-between' }}
                                    aria-describedby={id}
                                    onClick={handleClickCountry}>
                                    <Typography sx={{ fontSize: '14px', color: 'input.text', fontFamily: 'Montserrat' }}>
                                        {values.country}
                                    </Typography>
                                    <KeyboardArrowDownIcon sx={{ fontSize: '30px', color: 'text.arrow' }} />
                                    <Popover
                                        sx={{ zIndex: '2222222', '.MuiPopover-paper': { border: '1px solid #414655', borderRadius: '4px', mt: '3px', width: '251px' } }}
                                        id={idCountry}
                                        open={openCountry}
                                        anchorEl={anchorElCountry}
                                        onClose={handleClosePopover}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Box>
                                            <RadioGroup value={values.country} onChange={(e) => {
                                                handleValueCountry(e.target.value)
                                                setAnchorElCountry(null)
                                            }}
                                                aria-label="position" name="position" defaultValue="top" sx={{ display: 'flex', alignItems: 'start' }}>
                                                {countriesList?.map((country) => (
                                                    <Box key={country?.name} sx={{ display: 'flex', alignItems: 'center', padding: '10px 0px', bgcolor: 'menu.background' }}>
                                                        <FormControlLabel
                                                            sx={{ '.MuiFormControlLabel-label': { color: 'input.text', width: '100%', fontFamily: 'Montserrat', fontSize: '14px' } }}
                                                            value="top"
                                                            control={<Radio value={country?.name} sx={{ display: 'none' }} />}
                                                            label={country?.name}
                                                            labelPlacement="start"
                                                        />
                                                    </Box>
                                                ))}
                                            </RadioGroup>
                                        </Box>
                                    </Popover>
                                </Box>
                            </Box>
                        }
                        {/* <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Slug</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.slug}
                            onChange={handleChange('slug')}
                            placeholder='Enter Slug'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} /> */}
                    </Grid>
                    <Grid item xs={6}>
                        {/* <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Limited Partners</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.limitedPartners}
                            onChange={handleChange('limitedPartners')}
                            placeholder='Enter Limited Partners'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} /> */}
                        <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>First Name</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.name}
                            onChange={handleChange('name')}
                            placeholder='Enter First Name'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} />
                        <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Last Name</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.surname}
                            onChange={handleChange('surname')}
                            placeholder='Enter Last Name'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} />
                        <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Email</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.email}
                            onChange={handleChange('email')}
                            placeholder='Enter Email'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} />
                        {errors?.email && <Typography sx={{ fontSize: '12px', letterSpacing: '0.2px', lineHeight: '12px', color: 'rgba(255, 0, 0, 1)', fontFamily: 'Montserrat', fontWeight: 600, mt: '8px' }}>{errors?.email}</Typography>}
                        <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Notes</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.notes}
                            onChange={handleChange('notes')}
                            placeholder='Enter Notes'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} />
                        {handleValueFunding &&
                            <Box>
                                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Funding Stage (required)</Typography>
                                <Box
                                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', border: '1px solid', borderColor: 'dialogs.border', borderRadius: '4px', p: '8px 12px', justifyContent: 'space-between' }}
                                    aria-describedby={id}
                                    onClick={handleClickFunding}>
                                    <Typography sx={{ fontSize: '14px', color: 'input.text', fontFamily: 'Montserrat' }}>
                                        {values.fundingStage}
                                    </Typography>
                                    <KeyboardArrowDownIcon sx={{ fontSize: '30px', color: 'text.arrow' }} />
                                    <Popover
                                        sx={{ zIndex: '2222222', '.MuiPopover-paper': { border: '1px solid #414655', borderRadius: '4px', mt: '3px', width: '251px' } }}
                                        id={idFunding}
                                        open={openFunding}
                                        anchorEl={anchorElFunding}
                                        onClose={handleClosePopover}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Box>
                                            <RadioGroup value={values.fundingStage} onChange={(e) => {
                                                handleValueFunding(e.target.value)
                                                setAnchorElFunding(null)
                                            }}
                                                aria-label="position" name="position" defaultValue="top" sx={{ display: 'flex', alignItems: 'start' }}>
                                                {fundingStagesList?.map((stage) => (
                                                    <Box key={stage} sx={{ display: 'flex', alignItems: 'center', padding: '10px 0px', bgcolor: 'menu.background' }}>
                                                        <FormControlLabel
                                                            sx={{ '.MuiFormControlLabel-label': { color: 'input.text', width: '100%', fontFamily: 'Montserrat', fontSize: '14px' } }}
                                                            value="top"
                                                            control={<Radio value={stage} sx={{ display: 'none' }} />}
                                                            label={stage === '' ? 'None' : stage}
                                                            labelPlacement="start"
                                                        />
                                                    </Box>
                                                ))}
                                            </RadioGroup>
                                        </Box>
                                    </Popover>
                                </Box>
                                {formErrors?.fundingStage && <Typography sx={{ fontSize: '12px', letterSpacing: '0.2px', lineHeight: '12px', color: 'rgba(255, 0, 0, 1)', fontFamily: 'Montserrat', fontWeight: 600, mt: '8px' }}>{formErrors?.fundingStage}</Typography>}
                            </Box>
                        }
                        {/* <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.title' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 400, m: '24px 0 8px' }}>Sub Industry</Typography>
                        <TextField fullWidth variant="outlined" color="secondary"
                            value={values.subIndustry}
                            onChange={handleChange('subIndustry')}
                            placeholder='Enter Sub Industry'
                            sx={{
                                '.MuiOutlinedInput-root': { border: '1px solid', padding: '0px', color: '#b4b7bd', borderColor: 'dialogs.border' },
                                '.MuiOutlinedInput-input': { p: '12px', color: 'input.text', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 400, border: '1px solid', borderColor: 'dialogs.border' }
                            }} /> */}
                    </Grid>
                </Grid>}
            {btnTitle.indexOf('Leads list') !== -1 ? '' : <Box sx={{ width: '147px', m: '25px auto', textAlign: 'center' }}>
                <Button sx={{
                    fontSize: '14px',
                    color: '#fff',
                    padding: '8px 16px',
                    textTransform: 'none',
                    borderRadius: '4px',
                    bgcolor: 'button.primary.background',
                    '&:hover': {
                        bgcolor: 'button.primary.background'
                    },
                }}
                    onClick={handleAdd}>{btnTitle}</Button>
            </Box>}
        </Box >
    )
}

export default DialogAddFields;