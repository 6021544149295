import axios from 'axios';
import { updateToken } from '../utils/updateToken';
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.interceptors.request.use((config) => {
    config.headers.userEmail = localStorage.getItem('userEmail')
    config.headers.token_id = localStorage.getItem('tokenID');
    return config;
})


instance.interceptors.response.use((config) => {
    return config;
}, async (error) => updateToken(error, instance))



instance.interceptors.request.use((config) => {
    config.headers.userEmail = localStorage.getItem('userEmail')
    return config;
})


instance.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    console.log(error.config._isRetry)
    if (error.response.status == 403 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/auth/updateToken?token_id=${localStorage.getItem('tokenID')}&userEmail=${localStorage.getItem('userEmail')}`,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            localStorage.setItem('tokenID', response.data.token)
            return instance.request(originalRequest);
        } catch (error) {
            console.log('error')
        }
    }
})

export const apiEvents = {
    getEvent: async ({ isDashboard, email, headers }) => {
        return (
            await instance.get('/event/getEvents', { params: { isDashboard, email }, headers })
        ).data;
    },
    getCountsEvent: async ({ headers }) => {
        return (
            await instance.get('/event/getCountsEvent', { headers })
        ).data;
    },
    createEvent: async ({ data, headers }) => {
        return (
            await instance.post('/event/createEvent', data, { headers })
        ).data;
    },
}