import React from 'react'
import { Avatar, Grid, Box, Typography, Divider, Button, Popover, Switch } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Logo from '../../assets/images/irm.svg';
import MobileLogo from '../../assets/images/mobileLogo.svg';
import MobileLogoWhite from '../../assets/images/mobileLogoWhite.svg';
import lightThemeLogo from '../../assets/images/lightThemeIrm.svg';
import userLogo from '../../assets/images/user.svg';
import lightThemeUser from '../../assets/images/lightThemeUser.svg';
import users from '../../assets/images/users.svg';
import lightThemeUsers from '../../assets/images/lightThemeUsers.svg';
import briefcase from '../../assets/images/briefcase.svg';
import lightThemeBriefcase from '../../assets/images/lightThemeBriefcase.svg';
import clipboard from '../../assets/images/clipboard.svg';
import lightThemeClipboard from '../../assets/images/lightThemeClipboard.svg';
import mail from '../../assets/images/mail.svg';
import mail2 from '../../assets/images/mail2.svg';
import home from '../../assets/images/home.svg';
import lightThemeHome from '../../assets/images/lightThemeHome.svg';
import settings from '../../assets/images/settings.svg';
import lightThemeSettings from '../../assets/images/lightThemeSettings.svg';
import checkCircle from '../../assets/images/checkCircle.svg';
import moreVertical from '../../assets/images/moreVertical.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
// import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import CircleIcon from '@mui/icons-material/Circle';
import SettingsIcon from '../../assets/images/settings.svg';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { apiLeads } from '../../api/apiLeads';
import { apiUser } from '../../api/apiUser';
import { createTheme } from '@mui/material/styles';
import { useBack } from "use-back";
import { NotificationContext } from "../../contexts/NotificationContext";
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';

const darkTheme = createTheme({
    assets: {
        home: home,
        user: userLogo,
        briefcase: briefcase,
        clipboard: clipboard,
        settings: settings,
        users: users,
    },
});

const lightTheme = createTheme({
    assets: {
        home: lightThemeHome,
        user: lightThemeUser,
        briefcase: lightThemeBriefcase,
        clipboard: lightThemeClipboard,
        settings: lightThemeSettings,
        users: lightThemeUsers,
    },
});



function Layout({ pageInfo, children, themeChanged, setThemeChanged, ...all }) {
    const { notification } = React.useContext(NotificationContext);

    const [theme, setTheme] = React.useState(lightTheme);
    const navigate = useNavigate();
    const { handleBack } = useBack();
    const links = [
        {
            title: 'Homepage',
            img: <img src={themeChanged ? lightThemeHome : home} alt="home" />,
            active: pageInfo.activeMenuTitle === 'Homepage',
            href: '/'
        },
        // {
        //     title: 'All Contacts',
        //     img: <img src={themeChanged ? lightThemeUser : userLogo} alt="user" />,
        //     active: pageInfo.activeMenuTitle === 'All Contacts',
        //     href: '/allpeople'
        // },
        {
            title: 'Contacts',
            img: <img src={themeChanged ? lightThemeUser : userLogo} alt="user" />,
            active: pageInfo.activeMenuTitle === 'My Leads',
            href: '/myleads'
        },
        {
            title: 'My Lists',
            img: <img src={themeChanged ? lightThemeClipboard : clipboard} alt="clipboard" />,
            active: pageInfo.activeMenuTitle === 'My Lists',
            href: '/mylist'
        },
        {
            title: 'Message Templates',
            img: <img src={themeChanged ? lightThemeSettings : settings} alt="sample-message" />,
            active: pageInfo.activeMenuTitle === 'Message Templates',
            href: '/sample-message'
        },
        {
            title: 'User Management',
            img: <img src={themeChanged ? lightThemeUser : userLogo} alt="usersManagement" />,
            active: pageInfo.activeMenuTitle === 'User Management',
            href: '/adminsmanagment'
        },
        {
            title: 'Settings',
            img: <img src={themeChanged ? lightThemeSettings : settings} alt="settings" />,
            active: pageInfo.activeMenuTitle === 'Settings',
            href: '/settings'
        }
    ]

    const [linksItems, setLinksItems] = React.useState(links);
    const location = useLocation();
    // const { user, logout } = useAuth0();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userName, setUserName] = React.useState('');
    const [rolesData, setRolesData] = React.useState([])
    const [menuList, setMenuList] = React.useState(links)
    const [mode, setMode] = React.useState(localStorage.getItem('mode'))

    const [checked, setChecked] = React.useState(mode === 'dark' ? false : true);
    const params = useParams();
    const { type } = params;

    React.useEffect(() => {
        setMenuList([...links])
    }, [themeChanged])

    const [tokenID, setTokenID] = React.useState(localStorage.getItem('tokenID'))

    React.useEffect(() => {
        let cleanupFunction = false;
        if (tokenID !== null) {
            const headers = {
                user_id: localStorage.getItem('userId'),
                token_id: tokenID
            }
            apiUser.getThemeUser({ headers })
                .then(res => {
                    if (res.themeSelect === "Dark") {
                        setChecked(false)
                        setTheme(darkTheme)
                        if (!cleanupFunction) setThemeChanged(false)
                    }
                    else {
                        setChecked(true)
                        setTheme(lightTheme)
                        if (!cleanupFunction) setThemeChanged(true)
                    }
                })
                .catch(err => { console.log(err) })
        }
        return () => cleanupFunction = true;
    }, [tokenID]);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        const headers = {
            user_id: localStorage.getItem('userId'),
            token_id: localStorage.getItem('tokenID')
        }
        let theme;
        if (event.target.checked) {
            theme = 'White'
            setMode('light')
        } else {
            theme = 'Dark'
            setMode('dark')
        }
        setThemeChanged(!themeChanged);

        apiUser.changeThemeUser({ theme, headers })
            .then(res => {
                console.log(res);
            })
            .catch(err => { console.log(err) })

    };

    React.useEffect(() => {
        setLinksItems(links)
    }, [mode, localStorage.getItem('mode')])

    React.useEffect(() => {
        setMode(localStorage.getItem('mode'))
    }, [localStorage.getItem('mode')])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (checked) {
            setTheme(lightTheme)
        }
        else {
            setTheme(darkTheme)
        }
    }, [checked]);

    const logoutGoogle = () => {
        const uri = `${process.env.REACT_APP_GOOGLE_AUTH_URI}?redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=${process.env.REACT_APP_SCOPES}&access_type=offline`;

        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/auth/singOut`,
            headers: {
                'Content-Type': 'application/json',
                user_id: localStorage.getItem('userId'),
                token_id: localStorage.getItem('tokenID')
            }
        }
        axios(config)
            .then(res => {
                console.log(res)
                localStorage.removeItem('tokenID');
                localStorage.removeItem('name');
                document.location.href = uri
            })
            .catch(err => console.log(err))
        // const uri = `${process.env.REACT_APP_GOOGLE_AUTH_URI}?redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=${process.env.REACT_APP_SCOPES}&access_type=offline`;
        // localStorage.removeItem('tokenID');
        // localStorage.removeItem('name');
        // console.log(localStorage.getItem('tokenID'))
        // document.location.href = uri//"https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=http://localhost:3000";
    }

    React.useEffect(() => {
        if (localStorage.getItem('tokenID')) {
            setUserName(localStorage.getItem('name'));
            const headers = {
                token_id: localStorage.getItem('tokenID')
            }
            apiUser.getUser({ headers })
                .then(function (response) {
                    // setUserName(response.name)
                    setRolesData(response.roles[0]);
                    localStorage.setItem('role', response.roles[0])
                    getAcsess(response.roles[0])
                })
                .catch(function (error) {
                    console.log(error)
                });
        };
    }, [linksItems])

    const getAcsess = (role) => {
        if (role !== 'ADMIN' && (location.pathname === '/adminsmanagment' || location.pathname === '/settings')) {
            navigate('/');
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleActive = (title) => {
        let arrItems = linksItems

        arrItems.forEach(element => {
            if (element.title === title) {
                element.active = true
            } else {
                element.active = false
            }
        });
        setLinksItems([...arrItems])
        setOpenMobileSideBar(false);
    }

    const [openMobileSideBar, setOpenMobileSideBar] = React.useState(false);

    const handleBackgroundClick = (event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            setOpenMobileSideBar(false);
        }
    };

    return (
        <Box>
            <Grid container sx={{ height: '100vh' }}>
                <Grid
                    item
                    xs={12}
                    md={0}
                    onClick={(e) => handleBackgroundClick(e)}
                    sx={{
                        zIndex: 11,
                        display: { xs: openMobileSideBar ? "flex" : "none", md: "none" },
                        justifyContent: 'flex-end',
                        height: "100vh",
                        width: "100%",
                        top: "0",
                        left: "0",
                        position: "absolute",
                        bgcolor: "rgba(0,0,0,.45)",
                    }}
                >
                    <Box sx={{ width: "80%", height: "100%", bgcolor: 'layout.background.block', }}>
                        {linksItems && linksItems.map((item, index) => {
                            if (localStorage.getItem('role') !== 'ADMIN' && (item.title === 'User Management' || item.title === 'Settings')) {
                            } else {
                                return (
                                    <Link className='underline' to={item.href} key={index + 1}>
                                        <Box key={index + 1} sx={{
                                            display: 'flex',
                                            bgcolor: item.active ? 'layout.background.navbarItems.active' : 'layout.background.navbarItems.default',
                                            p: '10px',
                                            alignItems: 'center',
                                            opacity: item.active ? '1' : '0.6',
                                            cursor: 'pointer'
                                        }}
                                            onClick={() => handleActive(item.title)}>
                                            <Box sx={{ width: '24px' }}>
                                                {item.img}
                                            </Box>
                                            <Typography ml={1} sx={{ fontSize: '14px', letterSpacing: '0.01em', lineHeight: '150%', color: 'layout.background.navbarItems.typography', fontFamily: 'Montserrat' }}>{item.title}</Typography>
                                        </Box>
                                    </Link>)
                            }
                        })}
                    </Box>
                </Grid>
                <Grid item sx={{ bgcolor: 'layout.background.block', backdropFilter: 'blur(10px)', width: '11.5%', display: { xs: 'none', md: 'block' } }}>
                    <Box sx={{ p: '20px' }} style={{ height: 'auto' }} >
                        <img src={!themeChanged ? Logo : lightThemeLogo} alt="logo" style={{ maxInlineSize: '100%', blockSize: 'auto' }} />
                    </Box>
                    <Divider sx={{ bgcolor: 'layout.background.divider', width: '90%', margin: '0 auto' }} />
                    <Box mt={4}>
                        {linksItems && linksItems.map((item, index) => {
                            if (localStorage.getItem('role') !== 'ADMIN' && (item.title === 'User Management' || item.title === 'Settings')) {
                            } else {
                                return (
                                    <Link className='underline' to={item.href} key={index + 1}>
                                        <Box key={index + 1} sx={{
                                            display: 'flex',
                                            bgcolor: item.active ? 'layout.background.navbarItems.active' : 'layout.background.navbarItems.default',
                                            p: '10px',
                                            alignItems: 'center',
                                            opacity: item.active ? '1' : '0.6',
                                            cursor: 'pointer'
                                        }}
                                            onClick={() => handleActive(item.title)}>
                                            <Box sx={{ width: '24px' }}>
                                                {item.img}
                                            </Box>
                                            <Typography ml={1} sx={{ fontSize: '14px', letterSpacing: '0.01em', lineHeight: '150%', color: 'layout.background.navbarItems.typography', fontFamily: 'Montserrat' }}>{item.title}</Typography>
                                        </Box>
                                    </Link>)
                            }
                        })}
                    </Box>
                </Grid>
                <Grid item sx={{ width: { xs: '100%', md: '88.5%' }, bgcolor: 'layout.background.main', p: '32px' }}>
                    {/* {location.pathname === '/' ? '' : */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', alignItems: 'center', bgcolor: 'layout.background.block', mb: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <ArrowBackIosIcon sx={{ color: 'layout.typography.main', fontSize: '18px', p: '0 10px' }} onClick={handleBack} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    p: 1,
                                    m: 1,
                                }}
                            >
                                <Typography sx={{ fontSize: '32px', lineHeight: '40px', color: 'layout.typography.main', fontFamily: 'Montserrat', display: 'flex', }}>
                                    {pageInfo.header}
                                </Typography>
                            </Box>
                        </Box>
                        {/* {location.pathname === '/adminsmanagment' ?
                            <Box sx={{ display: 'flex' }}>
                                <Button sx={{
                                    fontSize: '14px',
                                    color: '#7B61FF',
                                    border: '1px solid #7B61FF',
                                    padding: '8px 16px',
                                    textTransform: 'none',
                                    borderRadius: '4px',
                                    mr: '20px'
                                }}>Refresh</Button>
                                <Button sx={{
                                    fontSize: '14px',
                                    color: '#fff',
                                    padding: '8px 16px',
                                    textTransform: 'none',
                                    borderRadius: '4px',
                                    background: '#7B61FF',
                                    '&:hover': {
                                        background: '#7B61FF'
                                    }
                                }}>Add new admin</Button>
                            </Box> : location.pathname === '/settings' ? "" : */}
                        <Box sx={{ display: 'flex' }}>
                            {/* <Box sx={{ mr: '20px', cursor: 'pointer' }}>
                                            <img src={mail} alt="mail" />
                                        </Box>
                                        <Box sx={{ mr: '20px', cursor: 'pointer' }}>
                                            <img src={mail2} alt="mail2" />
                                        </Box>
                                        <Box sx={{ mr: '20px', cursor: 'pointer' }}>
                                            <img src={checkCircle} alt="checkCircle" />
                                        </Box>
                                        <Box sx={{ cursor: 'pointer' }}>
                                            <img src={moreVertical} alt="moreVertical" />
                                        </Box> */}
                            <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1, }} >
                                <Box sx={{ display: 'flex', alignItems: 'center', pr: '1rem' }}>
                                    <Switch
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        sx={{
                                            '& .MuiSwitch-switchBase': {
                                                margin: 1,
                                                padding: 0,
                                                transform: 'translateX(-6px)',
                                                '&.Mui-checked': {
                                                    transform: 'translateX(22px)',
                                                    '& .MuiSwitch-thumb:before': {
                                                        // backgroundImage: 'url(../../assets/images/sun.svg)'
                                                        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                                                            '#fff',
                                                        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z" stroke="white" stroke-width="1"/></svg>')`,
                                                    },
                                                    '& + .MuiSwitch-track': {
                                                        width: '48px',
                                                        opacity: 0.4,
                                                        backgroundColor: mode === 'dark' ? '#7B61FF' : '#0B5394',
                                                        borderRadius: '10px',
                                                    },
                                                },
                                            },
                                            '& .MuiSwitch-thumb': {
                                                backgroundColor: mode === 'dark' ? '#7B61FF' : '#0B5394',
                                                width: 24,
                                                height: 24,
                                                '&:before': {
                                                    content: "''",
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    left: 0,
                                                    top: 0,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center',
                                                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 14 14"><path fill="${encodeURIComponent(
                                                        '#7B61FF',
                                                    )}" d="M13.0001 7.52667C12.8952 8.66147 12.4693 9.74294 11.7722 10.6445C11.0751 11.5461 10.1357 12.2305 9.06386 12.6177C7.992 13.0049 6.83205 13.0787 5.71972 12.8307C4.6074 12.5827 3.58872 12.023 2.78287 11.2172C1.97703 10.4113 1.41735 9.39265 1.16933 8.28033C0.921307 7.16801 0.9952 6.00806 1.38236 4.9362C1.76953 3.86434 2.45394 2.92491 3.35553 2.22784C4.25712 1.53076 5.33859 1.10487 6.47339 1C5.809 1.89884 5.48929 3.0063 5.57241 4.12094C5.65553 5.23559 6.13596 6.28337 6.92632 7.07373C7.71668 7.86409 8.76447 8.34452 9.87911 8.42765C10.9938 8.51077 12.1012 8.19106 13.0001 7.52667Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')`,
                                                },
                                            },
                                            '& .MuiSwitch-track': {
                                                width: '48px',
                                                opacity: 0.4,
                                                backgroundColor: mode === 'dark' ? '#7B61FF' : '#0B5394',
                                                borderRadius: '10px',
                                            },
                                        }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', pr: '1rem', position: 'relative' }}>
                                    <NotificationsNoneOutlinedIcon sx={{ color: 'icons', height: '1.7rem', width: '1.7rem' }} />
                                    {notification && <CircleIcon sx={{
                                        position: 'absolute',
                                        fill: '#DB6AB5',
                                        right: '20px',
                                        top: '10px',
                                        borderRadius: '50%',
                                        fontSize: '8px'
                                    }} />}
                                </Box>
                                <Box aria-describedby={id}
                                    onClick={handleClick}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        position: 'relative',
                                        cursor: "pointer"
                                    }}>
                                    <Typography sx={{ mr: '0.5rem', color: 'layout.typography.name' }}>{userName}</Typography>
                                    <Avatar alt="Avatar" src="" />
                                    <CircleIcon sx={{
                                        position: 'absolute',
                                        fill: '#28C76F',
                                        right: '0',
                                        bottom: '-4px',
                                        border: '1px #000 solid',
                                        borderRadius: '50%',
                                        fontSize: '13px'
                                    }} />
                                </Box>
                                <Box sx={{
                                    pl: '1.5rem'
                                }}>
                                    {/* <Box sx={{
                                                    background: 'rgba(40, 199, 170, 0.4)',
                                                    width: '40px',
                                                    height: "40px",
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer'
                                                }}>
                                                    <img src={SettingsIcon} width='24' alt='svg' />
                                                </Box> */}
                                </Box>
                                <Popover
                                    sx={{
                                        '.MuiPopover-paper': {
                                            bgcolor: 'layout.background.block',
                                            boxShadow: '0 4px 24px 0 rgb(0 0 0 / 24%)',
                                            borderRadius: '10px',
                                            zIndex: '1000'
                                        }
                                    }}
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        bgcolor: location.pathname === '/profile' ? 'layout.background.navbarItems.active' : 'layout.background.navbarItems.default',
                                        opacity: '1',
                                        '& > a': {
                                            textDecoration: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            p: '0 1rem',
                                            zIndex: '3',
                                            cursor: "pointer"
                                        }
                                    }}>
                                        <Link to='/profile'
                                            onClick={() => handleActive('My Profile')}
                                        >
                                            <PermIdentityOutlinedIcon sx={{ color: 'layout.typography.name' }} />
                                            <Typography sx={{ p: '0.5rem', color: 'layout.typography.name', pl: '1rem', pr: '2rem' }}>My Profile</Typography>
                                        </Link>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        p: '0 1rem',
                                        zIndex: '3',
                                        cursor: "pointer"
                                    }} onClick={() => { //logout(); 
                                        logoutGoogle()
                                    }}>
                                        <PowerSettingsNewIcon sx={{ color: 'layout.typography.name' }} />
                                        <Button sx={{ textDecoration: 'none' }}  >
                                            <Typography sx={{ p: '0.5rem', color: 'layout.typography.name', pr: '2rem' }}>LogOut</Typography>
                                        </Button>
                                    </Box>
                                </Popover >
                            </Box >
                        </Box>
                        {/* } */}
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', width: '100%', alignItems: 'center', bgcolor: 'layout.background.block', mb: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'space-between' }}>
                            <Box sx={{ p: '20px' }} style={{ height: 'auto' }} >
                                <img src={!themeChanged ? MobileLogoWhite : MobileLogo} alt="logo" style={{ maxInlineSize: '100%', blockSize: 'auto' }} />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                p: 1,
                                m: 1,
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', pr: '1rem', position: 'relative' }}>
                                <NotificationsNoneOutlinedIcon sx={{ color: 'icons', height: '1.7rem', width: '1.7rem' }} />
                                {notification && <CircleIcon sx={{
                                    position: 'absolute',
                                    fill: '#DB6AB5',
                                    right: '20px',
                                    top: '10px',
                                    borderRadius: '50%',
                                    fontSize: '8px'
                                }} />}
                            </Box>
                            <Box>
                                <MenuIcon
                                    onClick={() => setOpenMobileSideBar(true)}
                                    fontSize='large'
                                    sx={{
                                        color: 'icons',
                                        p: "0.5rem",
                                        // border: "1px solid #E5E5E5",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {/* } */}
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        {children}
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>

                        <Typography sx={{ fontSize: '32px', lineHeight: '40px', color: 'layout.typography.main', fontFamily: 'Montserrat', display: 'flex', paddingBottom: '2rem' }}>
                            {pageInfo.header}
                        </Typography>
                        {children}
                    </Box>
                </Grid>
            </Grid>
        </Box >
    );
}

export default Layout;
