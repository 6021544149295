import React from 'react';
import { Box, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const DialogDeleteField = ({ 
    btnTitle = 'Yes', 
    title = '', 
    onCloseModal,
    onSubmit,
    text = '',
}) => {
    return (
        <Box sx={{ bgcolor: 'dialogs.background', borderRadius: '8px', overflowX: 'hidden' }}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {title}

                <IconButton
                    aria-label="close"
                    onClick={onCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={onCloseModal}>
                    No
                </Button>
                
                <Button
                    sx={{
                        fontSize: '14px',
                        color: '#fff',
                        padding: '8px 16px',
                        textTransform: 'none',
                        borderRadius: '4px',
                        bgcolor: 'button.primary.background',
                        '&:hover': {
                            bgcolor: 'button.primary.background'
                        },
                    }} 
                    onClick={onSubmit}
                >
                    {btnTitle}
                </Button>
            </DialogActions>
        </Box>
    );
}

export default DialogDeleteField;