import { createTheme } from '@mui/material/styles';
const themeLight = createTheme({
  palette: {
    background: {
      paper: '#FFFFFF',
    },

    input: {
      borderColor: '#D1D5DB',
      disabledColor: 'rgba(3, 3, 3, 0.6)',
      color: 'rgba(3, 3, 3, 1)',
      text: '#111827',
    },

    button: {
      primary: {
        background: "#0B5394 !important",
      },
      secondary: {
        buttonText: "#111827",
        border: "#9CA3AF",
      },
      pagination: {
        buttonText: "#6B7280",
        background: "#FFFFFF",
      },
      settings: {
        background: "#0B5394",
        borderColor: "#0B5394",
        hoverBackground: "#0b539480"
      }
    },

    icons: "#667085",

    menu: {
      points: "#344054",
      background: "#FFFFFF",
      text: "#6B7280",
      is: '#F9FAFB',
    },

    load: {
      background: '#FFFFFF',
      circle: '#0B5394',
    },

    text: {
      primary: "#101828",
      secondary: "#344054",
      link: "#111827",
      title: "#6B7280",
      arrow: "#9CA3AF",
    },

    layout: {
      background: {
        main: "#E5E5E5",
        block: "#FFFFFF",
        divider: "",
        navbarItems: {
          active: "#dae0e6",
          default: "#FFFFFF",
          typography: "#6B7280",
        },
      },
      typography: {
        main: "#111827",
        name: "#6B7280",
      },
    },

    home: {
      graph: {
        primary: "#374151",
        secondary: "#6B7280",
      },
      graphLineOne: {
        1: "#6200EE",
        2: "#D81B60",
        3: "#9C27B0",
      },
      graphLineTwo: {
        1: "#6200EE",
        2: "#D81B60",
        3: "#9C27B0",
        4: "#C0CA33",
      },
      grid: {
        primary: "rgba(234, 236, 240, 0.6)",
        secondary: "#FFFFFF",
        border: "#D1D5DB",
      },
      block: "#FFFFFF",
      divider: "#D1D5DB",
    },

    allPeople: {
      arrow: "#6B7280",
      grid: {
        primary: "rgba(234, 236, 240, 0.6)",
        secondary: "#FFFFFF",
        border: "#EAECF0",
      },
      search: {
        icon: '#6B7280'
      },
      checkbox: {
        label: "#111827",
        color: "#0B5394",
      },
      block: "#FFFFFF",
    },

    pagination: {
      arrow: "#6B7280",
      grid: {
        border: "#EAECF0",
      },
    },

    table: {
      grid: {
        primary: "rgba(234, 236, 240, 0.6)",
        secondary: "#FFFFFF",
        border: "#EAECF0",
      },
      block: "#FFFFFF",
    },

    myLeads: {
      mode: "light",
      arrow: "#6B7280",
      grid: {
        primary: "rgba(234, 236, 240, 0.6)",
        secondary: "#FFFFFF",
        border: "#EAECF0",
      },
      block: "#FFFFFF",
    },

    myLists: {
      mode: "light",
      arrow: "#6B7280",
      grid: {
        primary: "rgba(234, 236, 240, 0.6)",
        secondary: "#FFFFFF",
        border: "#EAECF0",
      },
      block: "#FFFFFF",
    },

    sampleMessage: {
      mode: "light",
      sample: {
        background: "#FFFFFF",
        border: "#EAECF0",
      },
      grid: {
        border: "#EAECF0",
      },
      arrow: "#6B7280",
    },

    admins: {
      mode: "light",
      arrow: "#6B7280",
      grid: {
        primary: "rgba(234, 236, 240, 0.6)",
        secondary: "#FFFFFF",
        border: "#EAECF0",
      },
      block: "#FFFFFF",
    },

    tableList: {
      mode: "light",
      grid: {
        primary: "rgba(234, 236, 240, 0.6)",
        secondary: "#FFFFFF",
        border: "#EAECF0",
      },
      block: "#FFFFFF",
    },

    conversation: {
      mode: 'light',
      conversation: {
        background: '#FFFFFF',
        page: '#F9FAFB',
        border: '#EAECF0',
        noMessage: '#F9FAFB',
        message: '#F3F4F6',
      },
    },

    dialogs: {
      background: '#FFFFFF',
      divider: '#EAECF0',
      border: '#D1D5DB',
    },

    settings: {
      editMenu: {
        background: "#FFFFFF",
        title: "#6B7280",
        border: "#EAECF0",
      },
    },

    companyInfo: {
      text:{
        secondary: '#111827',
        description: '#6B7280',
        bold: '#0B5394'
      }
    }
  },
});
export default themeLight