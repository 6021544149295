import { Avatar, Box, Button, Dialog, Typography, Grid, Checkbox } from "@mui/material";
import axios from "axios";
import React from "react";
import Layout from "../../layout";
import CircleIcon from '@mui/icons-material/Circle';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogAddSampleText from "../../dialogAddSampleText";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Moment from 'react-moment';
import PaginationCrm from '../../pagination';
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    mode: 'dark',
});

const lightTheme = createTheme({
    mode: 'light',
});

const SampleMessage = ({setThemeChanged, themeChanged}) => {
    const pageInfo = {
        activeMenuTitle: 'Message Templates',
        header: 'Message Templates',
    };

    const [theme, setTheme] = React.useState(darkTheme);

    React.useEffect(() => {
        if (themeChanged === false) {
            setTheme(darkTheme)
        }
        else {
            setTheme(lightTheme)
        }
    }, [themeChanged])

    const [page, setPage] = React.useState(1);
    const [pagesCount, setPagesCount] = React.useState(1);
    const [dataMes, setDataMes] = React.useState([]);
    const [pageData, setPageData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [propsDialog, setPropsDialog] = React.useState('');
    const [idMessage, setIdMessage] = React.useState('');
    const [values, setValues] = React.useState({
        templateName: '',
        themeMessage: '',
        textMessage: ''
    });
    const prevPage = () => {
        if (page !== 1) {
            setPage(page - 1);
        }
    }

    const nextPage = () => {
        if (page !== pagesCount) {
            setPage(page + 1);
        }
    }

    const handleChangePagination = (e, value) => {
        setPage(value)
    }
    // /createSampleMessage

    const handleCreateMessage = () => {
        const data = {
            templateName: values.templateName,
            themeMessage: values.themeMessage,
            textMessage: values.textMessage
        }
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/message/createSampleMessage`,
            data: data
        };
        axios(config)
            .then(function (response) {
                getSampleMessage();
                handleClose()
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleChooseMessage = (id) => {
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/message/setChosenSample?_id=${id}`,
        };
        axios(config)
            .then(function (response) {
                getSampleMessage();
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleUpdateMessage = (_id) => {
        const data = {
            _id: _id,
            templateName: values.templateName,
            themeMessage: values.themeMessage,
            textMessage: values.textMessage
        }
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/message/updateSampleMessage`,
            data: data
        };
        axios(config)
            .then(function (response) {
                handleClose();
                getSampleMessage();
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleDeleteMessage = (id, chosen) => {
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/message/deleteSampleMessage?_id=${id}&chosen=${chosen}`,
        };
        axios(config)
            .then(function (response) {
                getSampleMessage();
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    React.useEffect(() => {
        getSampleMessage();
    }, [])

    React.useEffect(() => {
        if (dataMes) {
            setPagesCount(Math.ceil(dataMes.length / 3))
            let dataArray = [];
            if (dataMes[3 * page - 3]) {
                dataArray[0] = dataMes[3 * page - 3];
            }
            if (dataMes[3 * page - 2]) {
                dataArray[1] = dataMes[3 * page - 2];
            }
            if (dataMes[3 * page - 1]) {
                dataArray[2] = dataMes[3 * page - 1];
            }
            setPageData(dataArray)
        }
    }, [page, dataMes])

    const getSampleMessage = () => {
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/message/getSampleMessages`,
        };
        axios(config)
            .then(function (response) {
                setDataMes(response.data);

            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleClickOpen = (target, id, data) => {
        if (target === 'add') {
            setPropsDialog('Add Message Template')
        } else {
            setIdMessage(id)
            setValues({ templateName: data.templateName, textMessage: data.textMessage, themeMessage: data.themeMessage })
            console.log('update')
            setPropsDialog('Update Message Template')
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setValues({
            templateName: '',
            themeMessage: '',
            textMessage: ''
        })
    };


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    }

    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', pb: '2rem' }}>
                    <Button
                        sx={{
                            fontSize: '14px',
                            color: '#fff',
                            padding: '8px 16px',
                            textTransform: 'none',
                            borderRadius: '4px',
                            bgcolor: 'button.primary.background',
                            height: '40px',
                            '&:hover': {
                                bgcolor: 'button.primary.background',
                            }
                        }}
                        onClick={() => handleClickOpen('add')}
                    >
                        <AddIcon sx={{ pr: '2px' }} />
                        Add Sample message
                    </Button>
                </Box>
                <Box sx={{ bgcolor: 'sampleMessage.sample.background', width: 'auto', height: 'fit-content', minHeight: '50%', borderRadius: '8px', p: "0 2rem" }} >
                    <Grid container>
                        {pageData && pageData.map((item, index) => (
                            <Grid item container xs={12} sx={{ pt: "2rem" }} key={item._id}>
                                <Grid item xs={3}>
                                    <Typography sx={{ color: 'text.title', fontSize: "16px", lineHeight: "24px" }}>
                                        Message Subject:
                                    </Typography>
                                    <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.primary', fontSize: "14px", lineHeight: "20px" }}>
                                        {item.themeMessage}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography sx={{ color: 'text.title', fontSize: "16px", lineHeight: "24px" }}>
                                        Template name:
                                    </Typography>
                                    <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.primary', fontSize: "14px", lineHeight: "20px" }}>
                                        {item.templateName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography sx={{ color: 'text.title', fontSize: "16px", lineHeight: "24px" }}>
                                        Created at:
                                    </Typography>
                                    <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.primary', fontSize: "14px", lineHeight: "20px" }}>
                                        <Moment format="YYYY.MM.DD hh:mm">
                                            {item.createdAt}
                                        </Moment>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    {/* <Checkbox checked={item.chosenMessage} onClick={() => handleChooseMessage(item._id)} sx={{ mt:"-0.5rem", mb:"0.5rem", color: '#D0D2D6',
                                    '&.Mui-checked': {
                                        color: "#7B61FF",
                                    }, }} />
                                    <Typography  sx={{ mt:"0.25rem", color: "#D0D2D6", fontSize: "14px", lineHeight: "20px" }}>
                                        Choose Message
                                    </Typography> */}
                                    <EditIcon sx={{ color:  'icons', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer', pl: "1rem" }} onClick={() => handleClickOpen('update', item._id, item)} disabled={dataMes.length === 1} />
                                    <DeleteOutlineIcon sx={{ color: 'icons', '&:hover': { fill: '#7B61FF' }, cursor: 'pointer', pl: "1rem" }} onClick={() => handleDeleteMessage(item._id, item.chosenMessage)} disabled={dataMes.length === 1} />
                                </Grid>
                                <Grid item container xs={12} sx={{ pt: "1.5rem", pb: "2rem", border: index + 1 === pageData.length ? 'none' : "solid", borderColor: 'sampleMessage.sample.border', borderWidth: "1px", borderTop: "none", borderLeft: "none", borderRight: "none" }}>
                                    <Typography sx={{ color: 'text.title', fontSize: "16px", lineHeight: "24px", width: "100%" }}>
                                        Message text:
                                    </Typography>
                                    <Typography sx={{ color: theme.mode === 'light' ? 'text.link' : 'text.primary', fontSize: "14px", lineHeight: "20px" }}>
                                        {item.textMessage}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <PaginationCrm
                        page={page}
                        count={pagesCount}
                        prevPage={prevPage}
                        handleChange={handleChangePagination}
                        nextPage={nextPage}
                    />
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ '.MuiDialog-root': { background: 'rgba(13, 18, 31, 0.8)' }, '.MuiPaper-root': { background: 'none' }, background: 'rgba(13, 18, 31, 0.6)' }}
                >
                    <DialogAddSampleText
                        btnTitle={propsDialog}
                        handleClose={handleClose}
                        idMessage={idMessage}
                        values={values}
                        handleChange={handleChange}
                        handleCreateMessage={handleCreateMessage}
                        handleUpdateMessage={handleUpdateMessage}
                        theme={theme} />
                </Dialog>
            </Layout>
        </Box>
    )
}

export default SampleMessage;