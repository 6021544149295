import React from 'react';
import { Box, Typography, OutlinedInput, Button } from '@mui/material';
import Layout from '../../layout';
import moment from 'moment';
import { createTheme } from '@mui/material/styles';
import { apiUser } from '../../../api/apiUser';
import { useAuth0 } from '@auth0/auth0-react';


const darkTheme = createTheme({
    mode: 'dark',
});

const lightTheme = createTheme({
    mode: 'light',
});

const tableData = [
    { name: 'Email', value: '' },
    { name: 'First Name', value: '', slugName: 'firstName' },
    { name: 'Last Name', value: '', slugName: 'lastName' },
    { name: 'Industry', value: '', slugName: 'industry' },
    { name: 'Sub-industry', value: '', slugName: 'subIndustry' },
    { name: 'Date Joined', value: '' }
]


const MyProfile = ({ themeChanged, setThemeChanged }) => {
    const pageInfo = {
        activeMenuTitle: 'My Profile',
        header: 'My Profile',
    };

    const [theme, setTheme] = React.useState(darkTheme);
    const [dataUser, setDataUser] = React.useState(tableData);
    const [editMode, setEditMode] = React.useState(false);
    const [inputValue, setInputValue] = React.useState({
        industry: '',
        subIndustry: ''
    });

    const { user } = useAuth0();


    React.useEffect(() => {
        if (themeChanged === false) {
            setTheme(darkTheme)
        }
        else {
            setTheme(lightTheme)
        }
    }, [themeChanged])

    React.useEffect(() => {
        // if (user) {
            const headers = {
                'user_id': localStorage.getItem('userId'),
                'token_id': localStorage.getItem('tokenID'),
            }
            apiUser.getUser({ headers })
                .then((res) => {
                    dataUser[0].value = res.email;
                    dataUser[1].value = res.given_name
                    dataUser[2].value = res.family_name
                    dataUser[3].value = res.industry
                    dataUser[4].value = res.subIndustry
                    dataUser[5].value = res.updated_at
                    inputValue.industry = res.industry;
                    inputValue.subIndustry = res.subIndustry;
                    setInputValue({ ...inputValue })
                    setDataUser([...dataUser])
                })
                .catch((error) => {
                    console.log(error)
                });
        // };
    }, [user, editMode]);

    const handleSave = () => {
        const headers = {
            'user_id': localStorage.getItem('userId'),
            'token_id': localStorage.getItem('tokenID'),
        }
        apiUser.changeUser({ headers, industry: inputValue.industry, subIndustry: inputValue.subIndustry })
            .then(res => {
                inputValue.industry = res.doc.industry;
                inputValue.subIndustry = res.doc.subIndustry;
                setInputValue({ ...inputValue });
                setEditMode(!editMode);
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleEdit = () => {
        setEditMode(!editMode);
        if (editMode) {
            setInputValue({
                industry: dataUser[3].value,
                subIndustry: dataUser[4].value
            })
        }
    }

    const handleChangeInput = (e) => {
        inputValue[e.target.name] = e.target.value;
        setInputValue({ ...inputValue })
    }

    return (
        <Box>
            <Layout pageInfo={pageInfo} themeChanged={themeChanged} setThemeChanged={setThemeChanged}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ bgcolor: 'settings.editMenu.background', p: '1rem 2rem', borderRadius: '8px', width: '60%' }}>
                        {dataUser && dataUser.map((item, key) => (
                            <Box key={item.name} sx={{
                                display: 'flex', borderTop: 'solid 1px', p: '0 1rem ',
                                borderBottom: key === dataUser.length - 1 ? 'solid 1px' : '',
                                '&:last-child': { borderBottom: 'solid 1px' }
                            }}>
                                <Typography sx={{ color: theme.mode === 'light' ? 'text.primary' : 'text.secondary', p: '1rem', minWidth: '40%', fontFamily: 'Montserrat' }}>{item.name}</Typography>
                                {item.name === 'Email' || item.name === 'Date Joined' ?
                                    <Typography sx={{
                                        p: '1rem',
                                        color: theme.mode === 'light' ? 'text.primary' : 'text.secondary', fontFamily: 'Montserrat'
                                    }}>
                                        {item.name === 'Date Joined' ? moment(item.value).format('MMM. DD, YYYY, h:mma') : item.value}
                                    </Typography> :
                                    <Box sx={{ minWidth: '52%', pt: '0.3rem' }}>
                                        <OutlinedInput
                                            fullWidth
                                            disabled={editMode && (item.name === 'Industry' || item.name === 'Sub-industry') ? false : true}
                                            value={editMode && (item.name === 'Industry' || item.name === 'Sub-industry') ? inputValue[item.slugName] : item.value}
                                            onChange={handleChangeInput}
                                            name={item.slugName}
                                            sx={{
                                                'input': {
                                                    color: 'input.color',
                                                    p: '0',
                                                    fontFamily: 'Montserrat'
                                                },
                                                "& .Mui-disabled": {
                                                    color: "rgba(208, 210, 214, 1)",
                                                    '-webkit-text-fill-color': theme.mode === 'dark' ? 'rgba(208, 210, 214, 0.6) !important' : 'rgba(3, 3, 3, 0.6) !important'   // (default alpha is 0.38)
                                                },
                                                '&.MuiOutlinedInput-root': {
                                                    p: '8px 0 8px 1rem',
                                                    height: '45px',
                                                    border: 'solid',
                                                    borderColor: 'input.borderColor',
                                                    borderWidth: '1px',
                                                },
                                                'fieldset': {
                                                    border: 'none'
                                                }
                                            }}
                                        />
                                    </Box>}
                            </Box>
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: '1rem' }}>
                            {editMode ?
                                <Box>
                                    <Button
                                        onClick={handleEdit}
                                        sx={{
                                            color: 'button.settings.borderColor',
                                            border: 'solid 1px',
                                            borderColor: 'button.settings.borderColor',
                                        }}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleSave}
                                        sx={{
                                            ml: '1rem',
                                            color: 'button.settings.borderColor',
                                            border: 'solid 1px',
                                            borderColor: 'button.settings.borderColor',
                                        }}>
                                        Save
                                    </Button>
                                </Box>
                                : <Button
                                    onClick={handleEdit}
                                    sx={{
                                        color: 'button.settings.borderColor',
                                        border: 'solid 1px',
                                        borderColor: 'button.settings.borderColor',
                                    }}>
                                    Edit
                                </Button>}
                        </Box>
                    </Box>
                </Box>
            </Layout>
        </Box>
    )
}

export default MyProfile;