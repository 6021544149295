import React from 'react';
import { Box, Button, Divider, Typography, Grid, Select, MenuItem, OutlinedInput } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const DialogInsertTemplate = ({ btnTitle, messages, handleClose, setNewMessageData, setThemeMessage, appTheme }) => {
    
    const [theme, setTheme] = React.useState('');
    const [dataTheme, setDataTheme] = React.useState([]);
    const [dataMessages, setDataMessages] = React.useState([]);
    const [template, setTemplate] = React.useState('');

    const handleChangeTheme = (event) => {
        setTheme(event.target.value);
        setThemeMessage(event.target.value)
        setTemplate('')
    };

    const handleChangeTemplate = (event) => {
        setTemplate(event.target.value);
    };

    const handleInsertTemplate = () => {
        setNewMessageData(template);
        handleClose();
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                color: 'input.text',
                bgcolor: 'menu.background',
            },
        },
    };


    React.useEffect(() => {
        // console.log(messages)
        let messagesTopics = [];
        messages.forEach(message => {
            let check = true;
            messagesTopics.forEach(topic => {
                if(message.themeMessage === topic){
                    check = false;
                }
            });
            if(check){
                messagesTopics.push(message.themeMessage)
            }
        });
        setDataTheme(messagesTopics)
    }, [messages])


    React.useEffect(() => {
        let messagesArray = [];
        messages.forEach(message => {
            if(message.themeMessage === theme){
                messagesArray.push(message)
            }
        });
        setDataMessages(messagesArray)
    }, [theme])

    return (
        <Box sx={{ width: '566px', bgcolor: 'dialogs.background', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '16px' }}>
                <Typography sx={{ fontSize: '16px', letterSpacing: '0.2px', lineHeight: '24px', color: theme.mode === "light" ? 'text.primary' : 'text.secondary', fontFamily: 'Montserrat', fontWeight: 600 }}>{btnTitle}</Typography>
                <Box sx={{ cursor: 'pointer' }} onClick={handleClose} ><CloseIcon sx={{ color: 'icons' }} /></Box>
            </Box>
            <Divider sx={{ bgcolor: 'dialogs.divider', opacity: .2 }} />
            <Grid container
                sx={{
                    width: '566px',
                    '.MuiTextField-root': {
                        p: '0 13px'
                    },
                    '.MuiFormControl-root': {
                        p: '0 13px'
                    },
                    '.MuiOutlinedInput': {
                        color: '#D0D2D6'
                    },
                    'textarea': {
                        m: '0 11px',
                        padding: '0px',
                        p: '12px',
                        color: '#D0D2D6',
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        border: '1px solid #414655',
                        borderRadius: '2px',
                        '&:focus': {
                            outline: 'none !important',
                            border: '1px solid #7367F0',
                        }
                    }
                }}>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{
                            fontSize: '16px',
                            letterSpacing: '0.2px',
                            lineHeight: '24px',
                            color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            m: '1.5rem 0 0 1rem'
                        }}>Topics</Typography>
                        <Box sx={{ pl:"1rem", pr:"1rem", pt:"0.5rem", width:"100% - 32px" }} >
                            <Select
                                fullWidth
                                color='secondary'
                                value={theme}
                                onChange={handleChangeTheme}
                                input={<OutlinedInput label="Leads" sx={{
                                    color: 'input.text',
                                    'input': {
                                        color: 'input.text'
                                    },
                                    'svg': { color: '#b4b7bd' },
                                    '&.MuiOutlinedInput-root': {
                                        border: 'solid',
                                        borderColor: 'dialogs.border',
                                        borderWidth: '1px',
                                    },
                                    'fieldset': {
                                        border: 'none'
                                    }
                                }} />}
                                MenuProps={MenuProps}
                            >
                                {dataTheme && dataTheme.map((theme) => (
                                    <MenuItem
                                        key={theme}
                                        value={theme}
                                        sx={{ color: 'input.text' }}
                                    >
                                        {theme}
                                    </MenuItem>))}
                            </Select>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{
                            fontSize: '16px',
                            letterSpacing: '0.2px',
                            lineHeight: '24px',
                            color: theme.mode === "light" ? 'text.title' : 'text.secondary',
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            m: '1.5rem 0 0 1rem'
                        }}>Message Template</Typography>
                        <Box sx={{ pl:"1rem", pr:"1rem", pt:"0.5rem", width:"100% - 32px" }} >
                            <Select
                                fullWidth
                                color='secondary'
                                value={template}
                                onChange={handleChangeTemplate}
                                input={<OutlinedInput label="Leads" sx={{
                                    color: 'input.text',
                                    'input': {
                                        color: 'input.text'
                                    },
                                    'svg': { color: '#b4b7bd' },
                                    '&.MuiOutlinedInput-root': {
                                        border: 'solid',
                                        borderColor: 'dialogs.border',
                                        borderWidth: '1px',
                                    },
                                    'fieldset': {
                                        border: 'none'
                                    }
                                }} />}
                                MenuProps={MenuProps}
                            >
                                {dataMessages && dataMessages.map((message) => (
                                    <MenuItem
                                        key={message.templateName}
                                        value={message.textMessage}
                                        sx={{ color: 'input.text' }}
                                    >
                                        {message.templateName}
                                    </MenuItem>))}
                            </Select>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ width: '100%', m: '24px auto', textAlign:"center" }}>
                <Button sx={{
                    fontSize: '14px',
                    fontFamily: 'Montserrat',
                    color: '#fff',
                    padding: '8px 16px',
                    textTransform: 'none',
                    borderRadius: '4px',
                    bgcolor: 'button.primary.background',
                    '&:hover': {
                        bgcolor: 'button.primary.background'
                    },
                }}
                    onClick={handleInsertTemplate}
                >
                    {btnTitle}
                </Button>
            </Box>
        </Box>
    )
}

export default DialogInsertTemplate;