import axios from 'axios';
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_OLD}`,
});

export const apiCompany = {
    companyDetails: async ({ slug }) => {
        return (
            await instance.get('/dashboards/api/company-details/', { params: { slug } })
        ).data;
    },
}